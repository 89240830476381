/**
 * SingleEndedScale.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * Use the caretPosition to offset my caret to the correct location in my
 * display scale.
 *
 * My container passes me a ref to the current dataLoader, and I use that to
 * collect the needed limits.
 *
 * 2021-12-29 (tms):
 *  Refactored to use centiles.
 *
 *  The minimum and maximum scale boxes have been replaced with instances of
 *  CentileChooser.
 */
import React, { useEffect, useState  } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  } from '@material-ui/core';

import Caret from './Caret';
import SaturatedCaret from './SaturatedCaret';

export default function SingleEndedScale({ dataLoaderRef, highlightedFeature, minimumScaleString, maximumScaleString, refreshFeatures, ...props }) {
  const [scaleOffset, setScaleOffset] = useState(null);
  const [isSaturatedMin, setIsSaturatedMin] = useState(false);
  const [isSaturatedMax, setIsSaturatedMax] = useState(false);

  function colorKeyBackgroundString() {return dataLoaderRef.current.colorKeyBackgroundString()}

  function saturatedMaxColorKeyString() {
    return dataLoaderRef.current.saturatedMaxColorKeyString()
    }

  function saturatedMinColorKeyString() {
    return dataLoaderRef.current.saturatedMinColorKeyString()
    }

  /**
   * This must be inside SingleEndedScale because it needs the current colorKey
   */
  const useStyles = makeStyles((aTheme) => ({
    scaleLegend: {
    },
    colorKey: {
      background: `${colorKeyBackgroundString()}`,
      marginTop: '0px',
      // textAlign: 'left',
      fontSize: '1.0em',
      lineHeight: '1.0em',
      width: '200px',
      display: 'inline-block',
    },
    saturatedMinColorKey: {
      background: `${saturatedMinColorKeyString()}`,
      lineHeight: '1.0em',
      display: 'inline-block',
      width: '15px',
    },
    saturatedMaxColorKey: {
      background: `${saturatedMaxColorKeyString()}`,
      lineHeight: '1.0em',
      display: 'inline-block',
      width: '15px',
    },
  }));

  const classes = useStyles();

  useEffect(
    () => {
      if (highlightedFeature !== null) {
        if (dataLoaderRef.current.isFeatureMaxSaturated_(highlightedFeature)) {
          setIsSaturatedMin(false);
          setIsSaturatedMax(true);
          setScaleOffset(null);
        } else if (dataLoaderRef.current.isFeatureMinSaturated_(highlightedFeature)) {
          setIsSaturatedMin(true);
          setIsSaturatedMax(false);
          setScaleOffset(null);
        } else {
          const newScaleOffset = dataLoaderRef.current.scaleOffsetForFeature_(highlightedFeature);
          setIsSaturatedMin(false);
          setIsSaturatedMax(false);
          setScaleOffset(newScaleOffset)
        }
      } else {
        setIsSaturatedMin(false);
        setIsSaturatedMax(false);
        setScaleOffset(null);
      }
    },
    [dataLoaderRef, highlightedFeature]
  );

  return (
    <tr id="singleEndedScaleRowID">
      <td id="singleEndedScaleRowCell0ID">
        <Box id="noSaturatedMinColorKeyID" className={classes.saturatedMinColorKey}>
          <SaturatedCaret isSaturated={isSaturatedMin} />
        </Box>
      </td>
      <td id="singleEndedScaleRowCell1ID" >
        <Box id="colorKeyMinValueID" className={classes.colorKeyMinValue}>
          {minimumScaleString}
        </Box>
      </td>
      <td id="singleEndedScaleRowCell2ID">
        <Box id="colorKeyID" className={classes.colorKey}>
          <Caret scaleOffset={scaleOffset} />
        </Box>
      </td>
      <td id="singleEndedScaleRowCell3ID" >
        <Box id="colorKeyMaxValueID" className={classes.colorKeyMaxValue}>
          {maximumScaleString}
        </Box>
      </td>
      <td id="singleEndedScaleRowCell4ID" >
        <Box id="saturatedMaxColorKeyID" className={classes.saturatedMaxColorKey}>
          <SaturatedCaret isSaturated={isSaturatedMax} />
        </Box>
      </td>
    </tr>
  )
}
