/**
 * DataWindow.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 * I am the window that appears on the map in response to a mouse click.
 **/

import {
  InfoWindow,
  } from "@react-google-maps/api";

const MAXIMUM_WIDTH = 600;

const DataWindow = ( {onLoad, position, onContentChanged, onCloseClick, onDOMReady, content, ...props } ) => {
  // console.log('DataWindow.render()');
  return (
    <InfoWindow
      onLoad={onLoad}
      position={position}
      onContentChanged={onContentChanged}
      onCloseClick={onCloseClick}
      onDomReady={onDOMReady}
      options = {{MAXIMUM_WIDTH: MAXIMUM_WIDTH, content: content}}
    >
      <div />
    </InfoWindow>
  );
};

export default DataWindow;
