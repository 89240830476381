/**
 * TechnologyPane.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 */
import React from 'react';

import Typography from '@material-ui/core/Typography';
import PaneLink from './PaneLink';

export default function TechnologyPane({ onLinkClick, ...props}) {

  return (
    <>
    <Typography variant="body2" style={{'marginTop': '15px'}}>
      The COVID Data Browser is a work-in-progress created by the &nbsp;
      <PaneLink onLinkClick={onLinkClick} label="About">Zeetix LLC team</PaneLink> intended for non-commercial use by government
      officials, researchers, media, and interested people. We welcome
      opportunities to collaborate with interested parties to extend
      and enhance this offering.
    </Typography>
    <Typography variant="subtitle1" style={{'marginTop': '15px'}}>
      <span style={{'marginTop': '5px', 'marginBottom': '5px'}}>
      Technology stack
      </span>
    </Typography>
    <Typography variant="body2" style={{'marginTop': '15px'}}>
      The COVID Data Browser is supported by a CentOS 7 system hosted
      on Amazon AWS. It uses Neo4J (a graph database) to store all
      data accessible to the site. Access to the Neo4J database is provided
      by lightweight nodeJS microservices. The front end uses React and Material-UI.
      Data is ingested daily using tools built in Python.
    </Typography>
    <Typography variant="subtitle1" style={{'marginTop': '15px'}}>Anticipated enhancements</Typography>
    <Typography variant="body2" style={{'marginTop': '15px'}}>
      Several areas of enhancements are anticipated, subject to our
      extreme resource constraints.
    </Typography>
    <Typography variant="subtitle2" style={{'marginTop': '15px'}}>Greatly improved spatial resolution</Typography>
      <Typography variant="body2" style={{'marginTop': '15px'}}>
        The COVID Data Browser will be greatly improved by gathering
        and managing more granular data. For example, data is becoming
        available at the village, town or city level. The underlying
        database schema is capable of handling data at virtually any
        scale, including congressional district, ward, precint, census
        block, and even real estate parcel (or smaller).
      </Typography>
    <Typography variant="subtitle2" style={{'marginTop': '15px'}}>User experience</Typography>
    <Typography variant="body2" style={{'marginTop': '15px'}}>
      Anticipated features in our roadmap include animations of various
      layers over time, additional interactive display and data
      visualization capabilities, and integration of a variety of
      additional data types and sources.
    </Typography>
    <Typography variant="subtitle2" style={{'marginTop': '15px'}}>Data analysis tools</Typography>
    <Typography variant="body2" style={{'marginTop': '15px'}}>
      This technology stack greatly simplifies the construction of
      analysis tools that can be applied across large datasets to
      improve the ability of users to discover, document and share new
      aspects of already harvested data.
    </Typography>
    <Typography variant="subtitle2" style={{'marginTop': '15px'}}>Data Sources</Typography>
    <Typography variant="body2" style={{'marginTop': '15px'}}>
      The current data sources (see <PaneLink onLinkClick={onLinkClick} label="Sources">Sources</PaneLink>)
      aggregate data at the "county" level. Additional data sources
      may include "point" sources such as individual nursing homes,
      hospitals, and similar geographic features.
    </Typography>
    </>
  );
}
