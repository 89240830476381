/**
 * EnvironmentLoader.js
 * @copyright: 2022 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 * I can't believe how tedious it is to simply load the environment from
 * process.env and answer the resulting object.
 *
 * If there is a simpler way, I haven't found it.
 */
import axios from 'axios';

const GET_ENVIRONMENT_SELECTOR = 'getEnvironment';
const URL = `${process.env.REACT_APP_COVID_URL}:${process.env.REACT_APP_SERVICES_PORT}/${GET_ENVIRONMENT_SELECTOR}`;
/**
 * An instance of EnvironmentLoader collects and returns process.env
 */
class EnvironmentLoader {

  checkStatus(aResponse) {
    if (aResponse.status >= 200 && aResponse.status < 300) {
      return aResponse.data;
    } else {
      const error = new Error(`HTTP Error ${aResponse.statusText}`);
      error.status = aResponse.statusText;
      error.response = aResponse;
      console.log(error);
      throw error;
    }
  }

  /**
   * This actually makes the server call, using axios, and answers the result.
   *
   */
  privateLoadEnvironmentUsingURL_(aURL) {
    //const loadedData = axios.get(aURL)
    return axios
      .get(aURL)
      .then((aResponse) => {return this.checkStatus(aResponse)})
      .catch((anError) => {
        const complaint = `Error: EnvironmentLoader.privateLoadEnvironmentUsingURL_(${aURL}): ${anError.message}`
        // console.log('EnvironmentLoader.privateLoadEnvironmentUsingURL_ error: ', anError);
        console.log(complaint);
        throw anError;
        });
  }

  /**
   * Answers a promise that resolves to the value of process.env
   */
  loadServerEnvironment() {
    // console.log('EnvironmentLoader.loadServerEnvironment()');
    const loadURL = URL;
    const loadedEnvironment = this.privateLoadEnvironmentUsingURL_(loadURL);
    // console.log('EnvironmentLoader.loadServerEnvironment(): Done');
    return loadedEnvironment;
  }
}

const environmentLoader = new EnvironmentLoader();
export default environmentLoader;
