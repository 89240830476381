/**
  * DoubleEndedFeatureStyler.js
  * @copyright: 2022 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
  * All rights reserved.
  * 
  * The contents of this file may not be copied, duplicated, or used without the
  * written consent of Zeetix, LLC.
  * 
  * @param {google.maps.Data.Feature} feature
 **/
import AbstractFeatureStyler from './AbstractFeatureStyler';

class DoubleEndedFeatureStyler extends AbstractFeatureStyler {

  doLegendViewName() {
    return 'DoubleEndedLegendView'
  }

  /**
   * Each descendant overrides me to provide a suitable colorKey.
   *
   * Ordered from low to high. Compute an index and use it to extract the
   * desired color.
   */
  doColorKeyPreset() {
    const colorKey = [
      [238, 100, 18],
      [221, 95, 24],
      [181, 72, 33],
      [138, 95, 39],
      [76, 99, 39],
      [51, 100, 50],
      [18, 88, 51],
      [7, 88, 46],
      [311, 100, 47]
    ];
    return colorKey;
  }

  /**
   * Descendants override this to apply their specific style to aFeature.
   *
   * It is an error for a descendant to not override this method.
   */
  doStyleFeature_(aFeature) {
    let color = [0, 0, 0]
    let isShowingRow = false;
    const isMiddlesex = aFeature.getProperty('GEO_ID') === "0500000US25017";
    const isKCMO = aFeature.getProperty('GEO_ID') === "0500000US29998";
    if (isMiddlesex) {
      // console.log(`DoubleEndedFeatureStyler.doStyleFeature: Styling Middlesex County`)
    }
    const scaleOffset = this.scaleOffsetForFeature_isDoubleEnded_(aFeature, true);

    if (isMiddlesex) {
      // console.log(`SingleEndedFeatureStyler.doStyleFeature: Middlesex County scaleOffset is ${scaleOffset}`);
    }

    if (aFeature.getProperty('refresh')){
      aFeature.setProperty('refresh', false);
    }

    if (scaleOffset !== null) {
      color = this.interpolatedColorForScaleOffset_(scaleOffset);
      isShowingRow = true;
    }

    let outlineWeight = 0.5;
    let zIndex = isKCMO? 2: 1;

    if (aFeature.getProperty('state') === 'hover') {
      outlineWeight = 2;
      zIndex += 1;
    }

   return {
      strokeWeight: outlineWeight,
      strokeColor: '#fff',
      zIndex: zIndex,
      fillColor: 'hsl(' + color[0] + ',' + color[1] + '%,' + color[2] + '%)',
      fillOpacity: 0.75,
      visible: isShowingRow
    };
  }

  /**
   * Calculates and returns a color that matches the linear shading of the
   * scale.
   *
   * This is done by collecting a color key expressed in RGB instead of hsl.
   * The start and stop values are determined from aScaleOffset, and an RGB
   * value is interpolated from the start and stop using aScaleOffset. That
   * rgb value is then converted back to HSL and returned.
   *
   * I have nine stop colors and eight color bands.
   */
  doInterpolatedColorForScaleOffset_(aScaleOffset) {
    const colorKey = this.rgbColorKey();
    let startColor;
    let stopColor;
    let color;

    if ((aScaleOffset >= 0) && (aScaleOffset < (0.125))) {
      startColor = colorKey[0];
      stopColor = colorKey[1];
      color = this.rgbColorForScaleOffset_startColor_stopColor((aScaleOffset-0)*8, startColor, stopColor);
    } else if (aScaleOffset < (0.250)) {
      startColor = colorKey[1];
      stopColor = colorKey[2];
      color = this.rgbColorForScaleOffset_startColor_stopColor((aScaleOffset-0.125)*8, startColor, stopColor);
    } else if (aScaleOffset < (0.375)) {
      startColor = colorKey[2];
      stopColor = colorKey[3];
      color = this.rgbColorForScaleOffset_startColor_stopColor((aScaleOffset-0.250)*8, startColor, stopColor);
    } else if (aScaleOffset < (0.500)) {
      startColor = colorKey[3];
      stopColor = colorKey[4];
      color = this.rgbColorForScaleOffset_startColor_stopColor((aScaleOffset-0.375)*8, startColor, stopColor);
    } else if (aScaleOffset < (0.625)) {
      startColor = colorKey[4];
      stopColor = colorKey[5];
      color = this.rgbColorForScaleOffset_startColor_stopColor((aScaleOffset-0.500)*8, startColor, stopColor);
    } else if (aScaleOffset < (0.750)) {
      startColor = colorKey[5];
      stopColor = colorKey[6];
      color = this.rgbColorForScaleOffset_startColor_stopColor((aScaleOffset-0.625)*8, startColor, stopColor);
    } else if (aScaleOffset < (0.875)) {
      startColor = colorKey[6];
      stopColor = colorKey[7];
      color = this.rgbColorForScaleOffset_startColor_stopColor((aScaleOffset-0.750)*8, startColor, stopColor);
    } else {
      startColor = colorKey[7];
      stopColor = colorKey[8];
      color = this.rgbColorForScaleOffset_startColor_stopColor((aScaleOffset-0.875)*8, startColor, stopColor);
    }
    const [red, green, blue] = color;
    const hslColor = this.rgbToHsl(red, green, blue);
    return hslColor;
  }

  /**
   * My minimumScalestring is always the negative of my `maximumScaleString`.
   */
  minimumScaleString() {
    const maximumScaleString = this._renderer.maximumScaleString();
    const minimumScaleString = `-${maximumScaleString}`;
    return minimumScaleString
  }

  /**
   * My scaleOffset reflects negative scale maximum.
   */
  scaleOffsetForFeature_(aFeature) {
    const covidVariable = aFeature.getProperty('covid_variable');
    if ((covidVariable === undefined) || (covidVariable === null)) {return null}
    return this._renderer.scaleOffsetForValue_isDoubleEnded_(covidVariable, true);
  }

}

export default DoubleEndedFeatureStyler;
