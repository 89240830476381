/**
 * DatabaseClient.js
 * @copyright: 2022 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 * This component encapsulates React access to the Neo4J service, which in turn
 * provides access to the Neo4J database
 *
 */
import axios from 'axios';

const neo4jBase = `${process.env.REACT_APP_COVID_URL}:${process.env.REACT_APP_SERVICES_PORT}/neo4j/v2/covidData`;

/**
 * An instance of DatabaseClient collects and returns data from the Neo4J
 * database. If needed, it uses credentials obtained from the server
 * environment service.
 */
class DatabaseClient {
  // Internal methods

  privateQueryStringPartsForDate_source_(aDateString, aDataSource) {
    let queryStringParts = [
      'pertainsDate=' + aDateString,
    ];
    if ('HotSpots' === aDataSource) {
      queryStringParts.push('dataSource=' + aDataSource);
    }
    return queryStringParts;
  }

  privateQueryStringPartsForDate_source_grain_analyzerName_(aDateString, aDataSource, aDataGrain, anAnalyzerName) {
    let queryStringParts = [
      'pertainsDate=' + aDateString,
    ];
    if (aDataSource) {
      queryStringParts.push('dataSource=' + aDataSource);
    }
    if (aDataGrain) {
      queryStringParts.push('dataGrain=' + aDataGrain);
    }
    if ('EdgeIntensity' === anAnalyzerName) {
      queryStringParts.push('isEdgeIntensity=true');
    }
    return queryStringParts;
  }

  privateQueryStringPartsForDate_source_grain_isByPopulation_isByArea_adjustment_(aDateString, aDataSource, aDataGrain, aPopulationBoolean, anAreaBoolean, anAdjustment) {
    let queryStringParts = [
      'pertainsDate=' + aDateString,
    ];

    // Collect the rest of the queryStringParts
    if (aDataSource) {
      queryStringParts.push('dataSource=' + aDataSource);
    }
    if (aDataGrain) {
      queryStringParts.push('dataGrain=' + aDataGrain);
    }
    if (aPopulationBoolean) {
      queryStringParts.push('isByPopulation=true');
    }
    if (anAreaBoolean) {
      queryStringParts.push('isByArea=true');
    }
    if ("None" !== anAdjustment) {
      queryStringParts.push('dataAdjustment=' + anAdjustment);
    }
    return queryStringParts;
  }


  privateURLForDate_source_(aDateString, aDataSource) {
    const queryStringParts = this.privateQueryStringPartsForDate_source_(aDateString, aDataSource);
    const queryString = queryStringParts.join('&');
    const dataRequestURI = neo4jBase + '?' + queryString;
    return dataRequestURI;
  }

  privateURLForDate_source_grain_analyzerName_(aDateString, aDataSource, aDataGrain, anAnalyzerName) {
    const queryStringParts = this.privateQueryStringPartsForDate_source_grain_analyzerName_(aDateString, aDataSource, aDataGrain, anAnalyzerName);
    const queryString = queryStringParts.join('&');
    const dataRequestURI = neo4jBase + '?' + queryString;
    return dataRequestURI;
  }

  privateURLForDate_source_grain_isByPopulation_isByArea_adjustment_(aDateString, aDataSource, aDataGrain, aPopulationBoolean, anAreaBoolean, anAdjustment) {
    const queryStringParts = this.privateQueryStringPartsForDate_source_grain_isByPopulation_isByArea_adjustment_(aDateString, aDataSource, aDataGrain, aPopulationBoolean, anAreaBoolean, anAdjustment);
    const queryString = queryStringParts.join('&');
    const dataRequestURI = neo4jBase + '?' + queryString;
    return dataRequestURI;
  }

  privateURLForMetadata(){
    const dataRequestURI = `${neo4jBase}/metadata`;
    return dataRequestURI
  }

  checkStatus(aResponse) {
    if (aResponse.status >= 200 && aResponse.status < 300) {
      if (('code' in aResponse.data) || ('name' in aResponse.data)) {
        // Neo4J error
        const neo4jError = new Error(`Neo4J Error code: "${aResponse.data.code}" name: "${aResponse.data.name}"`);
        neo4jError.status = aResponse.data.code;
        neo4jError.response = aResponse;
        console.log(neo4jError);
        throw neo4jError;
      }
      return aResponse.data;
    } else {
      const error = new Error(`HTTP Error ${aResponse.statusText}`);
      error.status = aResponse.statusText;
      error.response = aResponse;
      console.log(error);
      throw error;
    }
  }

  parseJSON(aResponse) {
    return aResponse.json();
  }

  /**
   * This actually makes the server call, using axios, and answers the result.
   *
   */
  privateLoadDataUsingURL_(aURL) {
    //const loadedData = axios.get(aURL)
    return axios
      .get(aURL)
      .then((aResponse) => {return this.checkStatus(aResponse)})
      .catch((anError) => {
        console.log('DatabaseClient.privateLoadDataUsingURL_ error: ', anError);
        throw anError;
        });
  }

  // Methods for consumers

  /**
   * Answers a promise that resolves to loaded boolean data selected by my
   * arguments.
   *
   *    aDateString:  'YYYY' + ['-'] + 'MMM' + ['-'] + 'DD'
   *    aDataSource:  'HotSpots'
   */
  loadBooleanDataForDate_source_(aDateString, aDataSource) {
    // console.log('DatabaseClient.loadBooleanDataForDate_...()', {aDateString, aDataSource});
    const loadURL = this.privateURLForDate_source_(aDateString, aDataSource)
    const loadedData = this.privateLoadDataUsingURL_(loadURL);
    return loadedData;
  }

  /**
   * Answers a promise that resolves to loaded analysisResults selected by my
   * arguments.
   *
   *    aDateString:        'YYYY' + ['-'] + 'MMM' + ['-'] + 'DD'
   *    aDataSource:        'CaseCount' | 'DeathCount'
   *    aDataGrain:         'cumulative' | 'daily'
   *    anAnalyzerName:     'edgeIntensity`
   *
   *    Returns: [
   *        [ <featureID>, [<value>, <value>, ...<value>]],
   *        [ <featureID>, [<value>, <value>, ...<value>]],
   *        ...
   *        [ <featureID>, [<value>, <value>, ...<value>]],
   *      ]

   */
  loadAnalysisResultsForDate_source_grain_analyzerName_(aDateString, aDataSource, aDataGrain, anAnalyzerName) {
    // console.log('DatabaseClient.loadAnalysisResultsForDate_...(): ', {aDateString, aDataSource, aDataGrain, anAnalyzerName});
    const loadURL = this.privateURLForDate_source_grain_analyzerName_(aDateString, aDataSource, aDataGrain, anAnalyzerName)
    const loadedData = this.privateLoadDataUsingURL_(loadURL);
    return loadedData;
  }

  /**
   * Answers a promise that resolves to loaded data selected by my arguments
   *
   *    aDateString:        'YYYY' + ['-'] + 'MMM' + ['-'] + 'DD'
   *    aDataSource:        'CaseCount' | 'DeathCount'
   *    aDataGrain:         'cumulative' | 'daily'
   *    aPopulationBoolean: false | true
   *    anAreaBoolean:      false | true
   *    anAdjustment:       'none' | 'smoothing' | 'anomalies'
   *
   *    Returns: [
   *        [ <featureID>, [<value>, <value>, ...<value>]],
   *        [ <featureID>, [<value>, <value>, ...<value>]],
   *        ...
   *        [ <featureID>, [<value>, <value>, ...<value>]],
   *      ]
   */
  loadDataForDate_source_grain_isByPopulation_isByArea_adjustment_(aDateString, aDataSource, aDataGrain, aPopulationBoolean, anAreaBoolean, anAdjustment) {
    // console.log('DatabaseClient.loadDataForDate_...(): ', {aDateString, aDataSource, aDataGrain, aPopulationBoolean, anAreaBoolean, anAdjustment});
    const loadURL = this.privateURLForDate_source_grain_isByPopulation_isByArea_adjustment_(aDateString, aDataSource, aDataGrain, aPopulationBoolean, anAreaBoolean, anAdjustment)
    const loadedData = this.privateLoadDataUsingURL_(loadURL);
    return loadedData;
  }

  /**
   * Answers a promise that resolves to loaded metadata
   */
  loadMetadata() {
    // console.log('DatabaseClient.loadDataForDate_...(): ', {aDateString, aDataSource, aDataGrain, aPopulationBoolean, anAreaBoolean, anAdjustment});
    const loadURL = this.privateURLForMetadata()
    const loadedData = this.privateLoadDataUsingURL_(loadURL);
    return loadedData;
  }
}

const databaseClient = new DatabaseClient();
export default databaseClient;
