/**
  * BooleanFeatureStyler.js
  * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
  * All rights reserved.
  * 
  * The contents of this file may not be copied, duplicated, or used without the
  * written consent of Zeetix, LLC.
  * 
  * In the original static browser, Boolean features were rendered using the
  * single-ended styler. Boolean data is massaged at load-time to contain
  * values for 'true' and 'false' that render as maximumValue and minimumValue
  * respectively.
 **/
import AbstractFeatureStyler from './AbstractFeatureStyler';

class BooleanFeatureStyler extends AbstractFeatureStyler {

  /**
   * Each descendant overrides me to provide a suitable colorKey.
   *
   * It is an error for a descendant to not override this method.
   */
  doColorKeyPreset() {
    const colorKey = [
      [76, 99, 39],
      [311, 100, 47],
    ];
    return colorKey;
  }

  /**
   * Descendants override this to apply their specific style to aFeature.
   *
   * Answers just two colors, based 
   */
  doStyleFeature_(aFeature) {
    // Ordered from low to high. Compute an index and use it to extract the
    // desired color.
    // 
    // const falseColor = [76, 99, 39]; 
    // const trueColor = [311, 100, 47]
    const colorKey = this.colorKey();
    const [falseColor, trueColor] = colorKey;

    let isShowingRow = false;
    const isKCMO = aFeature.getProperty('GEO_ID') === "0500000US29998"
    const scaleOffset = this.scaleOffsetForFeature_(aFeature);
    let color = falseColor;
    if ((scaleOffset !== undefined) && (scaleOffset !== null)) {
      if (scaleOffset) {
        color = trueColor;
      }
      isShowingRow = true;
    }
    let outlineWeight = 0.5;
    let zIndex = isKCMO? 2: 1;

    if (aFeature.getProperty('state') === 'hover') {
      outlineWeight = 2;
      zIndex += 1;
    }

    return {
      strokeWeight: outlineWeight,
      strokeColor: '#fff',
      zIndex: zIndex,
      fillColor: 'hsl(' + color[0] + ',' + color[1] + '%,' + color[2] + '%)',
      fillOpacity: 0.75,
      visible: isShowingRow
    };

  };

  doLegendViewName() {
    return 'BooleanLegendView'
  };

  // No centiles in a boolean legend
  lowerLimitCentile() {return null};
  lowerLimitCentile_(anInteger) {};
  upperLimitCentile() {return null};
  upperLimitCentile_(anInteger) {};

}

export default BooleanFeatureStyler;
