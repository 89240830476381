/**
 * MapSceneController.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * I provide the dashboard that controls a map scene.
 *
 * I am usually the single child of the `Collapse` element of a
 * `ScreenController` that is in turn a sibling of a `Navigator` in an
 *  `Omnibox`.
 * I appear below (in the y-direction) the `Navigator` and screen controller
 * preview buttons. Each of those is 345x48 (as of 31-Mar-2021).
 *
 * I therefore position a 345x48 spacer box/paper/div at top of my root
 * container in order to position my controllers so that they are visible while
 * I am expanded.
 *
 * I maintain the current state of the MapScene. I pass that state to my
 * controls. When my apply button is clicked, I update the state used by
 * the mapSceneView.
 */

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Divider,
  Paper,
  } from '@material-ui/core';

import DataSourceControl from './DataSourceControl';
import DataGrainControl from './DataGrainControl';
import DataNormalizationAndAdjustmentControl from './DataNormalizationAndAdjustmentControl';
import ActionButtons from './ActionButtons';

const FONT_SIZE='13px';

const useStyles = makeStyles((aTheme) => ({
  root: {
    position: 'Absolute',
    width: 345,
  },
  formControl: {
    padding: "0px",
    margin: "0px",
    fontSize: FONT_SIZE,
  },
  radioGroup: {
    padding: "0px",
    margin: "0px",
    fontSize: FONT_SIZE,
  },
  formControlLabel: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 14,
    margin: 0,
    fontSize: FONT_SIZE,
  },
  radio: {
    padding: "0px",
    margin: "0px",
    width: 19,
    height: 19,
    fontSize: FONT_SIZE,
  },
  checkbox: {
    padding: "0px",
    margin: "0px",
    fontSize: FONT_SIZE,
  },
  actionButton: {
    margin: aTheme.spacing(1),
  }
}));

export default function MapSceneController({ handleExpandClick, mapControls, setMapControls, ...props }) {
  const classes = useStyles();
  const [dataSource, setDataSourceBasic] = useState(mapControls.dataSource);
  const [dataGrain, setDataGrain] = useState(mapControls.dataGrain);
  const [analyzerName, setAnalyzerName] = useState(mapControls.analyzerName);
  const [isByPopulation, setIsByPopulation] = useState(mapControls.isByPopulation);
  const [isByArea, setIsByArea] = useState(mapControls.isByArea);
  const [dataAdjustment, setDataAdjustment] = useState(mapControls.dataAdjustment);

  /**
   * Resets my state to match the current value of mapControls
[
  ['dataSource', (aValue) => setDataSource(aValue)],
  ['dataGrain', (aValue) => setDataGrain(aValue)],
  ['dataAdjustment', (aValue) => setDataAdjustment(aValue)],
  ['isByPopulation', (aValue) => setIsByPopulation(aValue)],
  ['isByArea', (aValue) => setIsByArea(aValue)],
  ['analyzerName', (aValue) => setAnalyzerName(aValue)],
]
   */
  const cancelChanges = (anEvent) => {
    const specification = [
      ['dataSource', (aValue) => setDataSource_(aValue)],
      ['dataGrain', (aValue) => setDataGrain(aValue)],
      ['dataAdjustment', (aValue) => setDataAdjustment(aValue)],
      ['isByPopulation', (aValue) => setIsByPopulation(aValue)],
      ['isByArea', (aValue) => setIsByArea(aValue)],
      ['analyzerName', (aValue) => setAnalyzerName(aValue)],
    ];
    specification.forEach((anElement) => {anElement[1](mapControls[anElement[0]])});
    // Close myself after cancelling
    handleExpandClick()
  }

  /**
   * Adjust other defaults if needed.
   */
  function setDataSource_(aDataSource) {
    if ('Vax' === aDataSource) {
      setDataGrain('cumulative');
    } else if (('CaseCount' === aDataSource) || ("DeathCount" === aDataSource)) {
      setDataGrain('daily');
    }
    setDataSourceBasic(aDataSource)
  }

  /**
   * Constructs a new mapControls from my state and uses the setter provided by
   * my container to change mapControls to the new value.
   */
  const applyChanges = (anEvent) => {
    // console.log(`MapSceneController.applyChanges(${anEvent}): `);
    const newMapControls = {...mapControls, dataSource, dataGrain, dataAdjustment, isByPopulation, isByArea, analyzerName };
    //// Validate the pertainsDate?
    //newMapControls.pertainsDate = null;
    setMapControls(newMapControls);
    // Close myself after accepting
    handleExpandClick()
  }

  return (
    <Box
      id="mapSceneControllerBoxID"
      width={345}
      position="absolute"
    >
      <Paper
        elevation={5}
        className={classes.root}
      >
        <Box height="100px" padding="0px" margin="0px" />
        <DataSourceControl
          dataSource={dataSource}
          setDataSource={setDataSource_}
        />
        <Box visibility={"HotSpots"===dataSource ? "hidden" : "visible"} >
          <Divider variant="middle" />
          <DataGrainControl
            dataSource={dataSource}
            dataGrain={dataGrain}
            setDataGrain={setDataGrain}
            analyzerName={analyzerName}
            setAnalyzerName={setAnalyzerName}
          />
          <Box visibility= {(("HotSpots"!==dataSource) && ('EdgeIntensity' !== analyzerName)) ? "visible" : "hidden"} >
            <Divider variant="middle" />
            <DataNormalizationAndAdjustmentControl
              isByPopulation={isByPopulation}
              setIsByPopulation={setIsByPopulation}
              isByArea={isByArea}
              setIsByArea={setIsByArea}
              dataAdjustment={dataAdjustment}
              setDataAdjustment={setDataAdjustment}
            />
          </Box>
        </Box>
        <Divider variant="middle" />
        <ActionButtons
          onCancel={cancelChanges}
          onApply={applyChanges}
        />
      </Paper>
    </Box>
  );
}
