/**
  * Limits.js
  * @copyright: 2022 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
  * All rights reserved.
  * 
  * The contents of this file may not be copied, duplicated, or used without the
  * written consent of Zeetix, LLC.
  * 
  * I contain metadata about a specific property.
  */

class Limits {
  #_directorContext = null;
  #_metaDatapointID = null;
  #_propertyName = null;
  #_minimumValue = null;
  #_maximumValue = null;
  #_centilesJSON = null;
  #_centiles = null;
  #_lowerLimitCentile = null;
  #_upperLimitCentile = null;

  /**
   * Creates and answers an instance based on aMetadata
   */
  static createFromMetadata_directorContext_(aMetadata, aDirectorContext) {
    const limits = new Limits(aMetadata);
    limits._directorContext = aDirectorContext;
    limits.loadInitialize()
    return limits
  }

  constructor({metaDatapointID, propertyName, minimumValue, maximumValue, centiles}) {
    this._metaDatapointID = metaDatapointID;
    this._propertyName = propertyName;
    this._minimumValue = minimumValue;
    this._maximumValue = maximumValue;
    this._centilesJSON = centiles;
  };

  metaDatapointID() {return this._metaDatapointID}
  propertyName() {return this._propertyName}
  minimumValue() {return this._minimumValue}
  maximumValue() {return this._maximumValue}

  lowerLimitCentileBasic() {return this._lowerLimitCentile}
  lowerLimitCentileBasic_(aCentile) {this._lowerLimitCentile = aCentile}
  upperLimitCentileBasic() {return this._upperLimitCentile}
  upperLimitCentileBasic_(aCentile) {this._upperLimitCentile = aCentile}

  // Begin methods that delegate to the directoryContext
  defaultLowerLimitCentile() {
    const defaultLowerLimitCentile = this._directorContext.defaultLowerLimitCentile()
    return defaultLowerLimitCentile
  }

  defaultUpperLimitCentile() {
    const defaultUpperLimitCentile = this._directorContext.defaultUpperLimitCentile()
    return defaultUpperLimitCentile
  }
  // End methods that delegate to the directoryContext

  lowerLimitCentilePreset() {return this.defaultLowerLimitCentile()}
  upperLimitCentilePreset() {return this.defaultUpperLimitCentile()}
  centilesPreset() {
    if (this._centilesJSON) {
      const centilesArray = JSON.parse(this._centilesJSON)
      const centiles = new Map(centilesArray)
      return centiles;
    }
    return new Map();
  }

  loadInitialize() {
    this._lowerLimitCentile = this.lowerLimitCentilePreset();
    this._upperLimitCentile = this.upperLimitCentilePreset();
    this._centiles = this.centilesPreset();
  }

  /**
   * Answers the value bound to aCentile in my centiles
   */
  centileValueAt_(aCentile) {
    if (!(this._centiles.has(aCentile))) {
        throw new Error("Missing or invalid centile");
    }
    const centileValue = this._centiles.get(aCentile);
    return centileValue;
  }

  /**
   * Answers the value corresponding to the lowerLimitCentile
   */
  lowerLimit() {
    return this.centileValueAt_(this._lowerLimitCentile)
  };

  /**
   * Answers the value corresponding to the upperLimitCentile
   */
  upperLimit() {
    return this.centileValueAt_(this._upperLimitCentile)
  };

  // Clients use these methods to adjust me

  lowerLimitCentile() {
    return this.lowerLimitCentileBasic();
  };

  /**
   * If aCentile is different from my lowerLimitCentile, changes me to reflect
   * its new value
   */
  lowerLimitCentile_(aCentile) {
    if (aCentile === this._lowerLimitCentile) {
      return;
    }

    this._lowerLimitCentile = aCentile
  };

  upperLimitCentile() {
    return this.upperLimitCentileBasic();
  };

  /**
   * If aCentile is different from my upperLimitCentile, changes me to reflect
   * its new value
   */
  upperLimitCentile_(aCentile) {
    if (aCentile === this._upperLimitCentile) {
      return;
    }

    this._upperLimitCentile = aCentile
  };

  /**
   * Answers an array with my lowerLimitCentile and my upperLimitCentile
   */
  range() {
    const range = [this.lowerLimitCentile(), this.upperLimitCentile()]
    return range
  }

  /**
   * Sets my lowerLimitCentile and upperLimitCentile
   *
   * Change both at the same time if possible.
   */
  range_(aRange) {
    const [newLowerLimitCentile, newUpperLimitCentile] = aRange;
    this.lowerLimitCentile_(newLowerLimitCentile);
    this.upperLimitCentile_(newUpperLimitCentile);
  }
}

export default Limits;
