/**
 * DataMarkerContainer.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 * I am the container of the window that appears on the map in response to a
 * mouse-enter.
 *
 **/

import React, { useState, } from 'react';
import DataMarker from './DataMarker';

const DataMarkerContainer = ({ rawDataMarkerRef, handleClickInMarker, markerPosition, setMarkerPosition, isDataMarkerVisible, ...props}) => {
  const [markerIcon, setMarkerIcon] = useState(null);

  const markerIconPreset = () => {
    const size = new window.google.maps.Size(300, 40);
    const markerIcon = {
      url: 'https:/images/rectangle-icon.png',
      scaledSize: size,
      };
    return markerIcon;
  };

  const onMarkerLoad = (aRawMarker) => {
    setMarkerIcon(markerIconPreset())
    rawDataMarkerRef.current = aRawMarker;
  };

  return (
    <DataMarker
      onMarkerLoad={onMarkerLoad}
      markerPosition={markerPosition}
      setMarkerPosition={setMarkerPosition}
      markerIcon={markerIcon}
      onClickInMarker={handleClickInMarker}
      isDataMarkerVisible={isDataMarkerVisible}
    />
  );
};

export default DataMarkerContainer;
