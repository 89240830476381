/**
 * DataLoader.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * I need a single-ended numeric legend
 */
import AbstractDataLoader from './AbstractDataLoader';

class DataLoader extends AbstractDataLoader {

  doConfigureLoadedFeature_covidVariable_(aFeature, aCovidVariable) {
    aFeature.setProperty('covid_variable', aCovidVariable);
    aFeature.setProperty('isHighlighted', false);
    aFeature.setProperty('isSelected', false);
  }

  doHandleLoadedData_(aLoadedData) {
    const googleMapData = this._directorContext.rawDataRef.current;
    aLoadedData.forEach((aDataItem) => {
      const [stateCountyFIPSCode, covidVariable] = aDataItem;
      const countyFIPSCode = stateCountyFIPSCode.slice(-3);
      const geoID = '0500000US' + stateCountyFIPSCode;
      let currentFeature = null;
      // Ignore data bound to countyFIPSCode 999.
      // These are for "unknown" bins in the NYT data.
      // update the existing row with the new data
      if (parseFloat(countyFIPSCode) < 999) {
        currentFeature = googleMapData.getFeatureById(geoID)
        if (currentFeature != null) {
          this.configureLoadedFeature_covidVariable_(currentFeature, covidVariable)
        } else {
          // The entry for this stateCountyFIPS is missing from the shapes file.
          let complaint = 'Missing stateCountyFIPSCode: ' + stateCountyFIPSCode;
          console.log(complaint);
        }
      }
    });
  }

  doLoadData() {
    return this.loadNumericData();
  };

}

export default DataLoader;
