/**
  * SingleEndedFeatureStyler.js
  * @copyright: 2022 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
  * All rights reserved.
  * 
  * The contents of this file may not be copied, duplicated, or used without the
  * written consent of Zeetix, LLC.
  * 
  * Styles features for monotonically-increasing data values.
  *
  * Use DoubleEndedStyler for data values that are both negative and
  * positive.
  *
  * Applies a gradient style based on the 'covid_variable' property.
  * Ignores any feature that lacks this property.
  *
  * @param {google.maps.Data.Feature} feature
  *
 **/
import AbstractFeatureStyler from './AbstractFeatureStyler';

class SingleEndedFeatureStyler extends AbstractFeatureStyler {

  doLegendViewName() {
    return 'SingleEndedLegendView'
  }

  doNothing() {}

  /**
   * Each descendant overrides me to provide a suitable colorKey.
   *
   * Ordered from low to high. Compute an index and use it to extract the
   * desired color.
   */
  doColorKeyPreset() {
    const colorKey = [
      [76, 99, 39],
      [51, 100, 50],
      [18, 88, 51],
      [7, 88, 46],
      [311, 100, 47]
    ];
    return colorKey;
  }

  /**
   * Descendants override this to apply their specific style to aFeature.
   *
   * It is an error for a descendant to not override this method.
   */
  doStyleFeature_(aFeature) {
    let color = [0, 0, 0]
    let isShowingRow = false;
    const isMiddlesex = aFeature.getProperty('GEO_ID') === "0500000US25017";
    const isKCMO = aFeature.getProperty('GEO_ID') === "0500000US29998";
    if (isMiddlesex) {
      // console.log(`SingleEndedFeatureStyler.doStyleFeature: Styling Middlesex County`)
    }
    const scaleOffset = this.scaleOffsetForFeature_(aFeature);

    if (isMiddlesex) {
      // console.log(`SingleEndedFeatureStyler.doStyleFeature: Middlesex County scaleOffset is ${scaleOffset}`);
    }

    if (aFeature.getProperty('refresh')){
      aFeature.setProperty('refresh', false);
    }

    if (scaleOffset !== null) {
      color = this.interpolatedColorForScaleOffset_(scaleOffset);
      isShowingRow = true;
    }
    let outlineWeight = 0.5;
    let zIndex = isKCMO? 2: 1;

    if (aFeature.getProperty('state') === 'hover') {
      outlineWeight = 2;
      zIndex += 1;
    }

    return {
      strokeWeight: outlineWeight,
      strokeColor: '#fff',
      zIndex: zIndex,
      fillColor: 'hsl(' + color[0] + ',' + color[1] + '%,' + color[2] + '%)',
      fillOpacity: 0.75,
      visible: isShowingRow
    };

  }

  /**
   * Calculates and returns a color that matches the linear shading of the
   * scale.
   *
   * This is done by collecting a color key expressed in RGB instead of hsl.
   * The start and stop values are determined from aScaleOffset, and an RGB
   * value is interpolated from the start and stop using aScaleOffset. That
   * rgb value is then converted back to HSL and returned.
   *
   * I have five stop colors and four color bands.
   */
  doInterpolatedColorForScaleOffset_(aScaleOffset) {
    const colorKey = this.rgbColorKey();
    let startColor;
    let stopColor;
    let color;

    if ((aScaleOffset >= 0) && (aScaleOffset < 0.25)) {
      startColor = colorKey[0];
      stopColor = colorKey[1];
      color = this.rgbColorForScaleOffset_startColor_stopColor(aScaleOffset*4, startColor, stopColor);
    } else if (aScaleOffset < 0.50) {
      startColor = colorKey[1];
      stopColor = colorKey[2];
      color = this.rgbColorForScaleOffset_startColor_stopColor((aScaleOffset-0.25)*4, startColor, stopColor);
    } else if (aScaleOffset < 0.75) {
      startColor = colorKey[2];
      stopColor = colorKey[3];
      color = this.rgbColorForScaleOffset_startColor_stopColor((aScaleOffset-0.50)*4, startColor, stopColor);
    } else {
      startColor = colorKey[3];
      stopColor = colorKey[4];
      color = this.rgbColorForScaleOffset_startColor_stopColor((aScaleOffset-0.75)*4, startColor, stopColor);
    }
    const [red, green, blue] = color;
    const hslColor = this.rgbToHsl(red, green, blue);
    if (!hslColor) {
      this.doNothing();
    }
    return hslColor;
  }

}

export default SingleEndedFeatureStyler;
