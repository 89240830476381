/**
 * ContentContainer.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 * I pass a change handler to at least my scene. I'm not sure if this makes
 * sense or not.
 *
 * I am the closest common ancestor of my MapSceneController (a child of
 * OmniboxContainer) and my MapScene (a child of scene).
 *
 * That means that I have to collect my map controls from my OmniboxContainer
 * and pass them all the way to my MapScene. Yuck.
 *
 * My SceneFooter will someday provide copyright info and a link to Zeetix.
 */

import React, { 
  useEffect,
  useRef,
  useState,
  } from 'react';
import { 
  Box,
  } from '@material-ui/core';

import Scene from './scene/Scene';
import OmniboxContainer from './omnibox/OmniboxContainer';

//import SceneFooter from './scene/SceneFooterContainer';
//import metadataForMapControls_, {metadataKeyFromMapControls_} from './scene/map_scene/CovidMetadata';

import Environment from './Environment';
import DataLoaderFactory from './data_loader/DataLoaderFactory';
import { LoadScript } from '@react-google-maps/api';

const googleMapAPILibraries = ['places', 'geometry'];

/**
 * The closest common ancestor of a Scene and SceneController. I therefore
 * collect controls and settings from the OmniboxContainer and pass them as
 * props to the Scene.
 */
export default function ContentContainer({ initialMapControls, initialMapSettings, ...props }) {
  const [mapControls, setMapControls] = useState(initialMapControls);
  const [mapSettings, setMapSettings] = useState(initialMapSettings);
  const [isEnvironmentLoaded, setIsEnvironmentLoaded] = useState(false);
  const [environment, setEnvironment] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [isMetadataLoaded, setIsMetadataLoaded] = useState(false);
  const environmentRef = useRef(null);
  const metadataLoaderRef = useRef(null);

  function handleEnvironmentLoadError(anError) {
    console.log('ContentContainer.handleEnvironmentLoadError: ', anError);
  };

  function handleMetadataLoadError(anError) {
    console.log('ContentContainer.handleMetadataLoadError: ', anError);
  };

  const setMapControlsWrapper = (aMapControls) => {
    // console.log(`ContentContainer.setMapControlsWrapper(${aMapControls}): `);
    // console.log(`ContentContainer.setMapControlsWrapper: old pertainsDate=${aMapControls.pertainsDate}`);
    // console.log(`ContentContainer.setMapControlsWrapper: dataSource=${aMapControls.dataSource}`);
    let mapControls = aMapControls;
    if (!aMapControls.pertainsDate) {
      // Use the provided pertainsDate if present
      // console.log(`ContentContainer.setMapControlsWrapper: Finding new pertainsDate`);
      let newPertainsDate = null;
      switch (aMapControls.dataSource) {
        case 'CaseCount':
          newPertainsDate = environmentRef.current.nytLatestPertainsDate();
          break;
        case 'DeathCount':
          newPertainsDate = environmentRef.current.nytLatestPertainsDate();
          break;
        case 'Vax':
          newPertainsDate = environmentRef.current.cdcLatestPertainsDate();
          break;
        case 'HotSpots':
          newPertainsDate = environmentRef.current.zeetixHotspotLatestPertainsDate();
          break;
        default:
          break;
      }
      // console.log(`ContentContainer.setMapControlsWrapper: new pertainsDate=${newPertainsDate}`);
      mapControls = {...aMapControls, pertainsDate: newPertainsDate}
      // console.log(`ContentContainer.setMapControlsWrapper: Updated mapControls`);
    }
    setMapControls(mapControls);
    // console.log('ContentContainer.setMapControlsWrapper: Done');
  }

  const setMapSettingsWrapper = (aMapSettings) => {
    // console.log(`ContentContainer.setMapSettingsWrapper(${aMapSettings})`);
    setMapSettings(aMapSettings);
    // console.log(`ContentContainer.setMapSettingsWrapper(): Done`);
  }

  useEffect(
    () => {
      /**
      * This is called by Environment after it has loaded the current environment
      */
      function handleLoadedEnvironment_(aLoadedEnvironment) {
        // console.log(`ContentContainer.handleLoadedEnvironment_(${aLoadedEnvironment})`);
        const environment = environmentRef.current.getEnvironment();
        setEnvironment(environment);
        setIsEnvironmentLoaded(true);
        // console.log(`ContentContainer.handleLoadedEnvironment_(): Done`);
      }

      // console.log('ContentContainer.useEffect[environment]()');
      environmentRef.current = new Environment(handleLoadedEnvironment_)
      environmentRef.current.loadEnvironmentOnError_((anError) => {handleEnvironmentLoadError(anError)});
      // console.log('ContentContainer.useEffect[environment]: Done');
    },
    [environmentRef]
  );

  useEffect(
    () => {
      function handleLoadedData_(aLoadedData) {
        setMetadata(aLoadedData);
      }

      function handleCompletion() {
        setIsMetadataLoaded(true);
      }

      // console.log('ContentContainer.useEffect[environment]()');
      metadataLoaderRef.current = DataLoaderFactory.createForMetadataDirectorContext_({
        'onCompletion': handleCompletion,
        'onDataLoaded': handleLoadedData_
        });
      metadataLoaderRef.current.loadDataOnError_((anError) => {handleMetadataLoadError(anError)});;
      // console.log('ContentContainer.useEffect[environment]: Done');
    },
    [metadataLoaderRef]
  );

  /**
   * I just log the change for now.
   */
  const handleChange = (anEvent) => {
    // console.log('ContentContainer.handleChange(): ', anEvent);
  };

  if (    !environmentRef.current
       || !isEnvironmentLoaded
       || !metadataLoaderRef.current
       || !isMetadataLoaded
    ) {
    return (
      <div>Loading ...</div>
    )
  }

  const googleMapKey = environmentRef.current.getEnvironmentVariableNamed_('GOOGLE_MAP_KEY');

  return (
    <LoadScript
      id="LoadScriptID"
      googleMapsApiKey={googleMapKey}
      libraries={googleMapAPILibraries}
    >
      <Box
        width="100vw"
        height="100vh"
        position="relative"
      >
        <Scene 
          id="SceneID"
          mapControls={mapControls}
          mapSettings={mapSettings}
          setMapSettings={setMapSettings}
          googleMapKey={googleMapKey}
          environment={environment}
          metadata={metadata}
        />
        <OmniboxContainer
          id="OmniboxContainerID"
          onChange={handleChange}
          mapControls={mapControls}
          setMapControls={setMapControlsWrapper}
          mapSettings={mapSettings}
          setMapSettings={setMapSettingsWrapper}
          googleMapKey={googleMapKey}
          environmentRef={environmentRef}
          metadata={metadata}
        />
      </Box>
    </LoadScript>
  );
}
