/**
 * DataWindowContainer.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 * I am the container of the window that appears on the map in response to a
 * mouse click.
 *
 * I do nothing until my container opens me by changing the state of
 * isDataWindowOpen.
 **/

import React, { useState, useEffect, useCallback } from 'react';
import DataWindow from './DataWindow';
import { vaxUnknownCountyShareByState } from './VaxUnknownCountyShareByState';

const DataWindowContainer = ({closeDataWindow, mapControls, mapSettings, position, rawDataWindowRef, highlightedFeature, selectedFeature, dataLoaderRef, nameForStateFIPSCode_, isDataLoaded, ...props}) => {
  // console.log('DataWindowContainer.render()');
  const [dataWindowContent, setDataWindowContent] = useState('');

  /**
   * Answers an HTML fragment to be rendered in the DataWindow.
   */
  const getRawDataWindowContent = useCallback(
    () => {
      const getDataNormalization = () => {
        let dataNormalization = 'None';
        if (mapControls.isByPopulation) {
          dataNormalization = 'population';
        };
        if (mapControls.isByArea) {
          if (mapControls.isByPopulation) {
            dataNormalization += ', area';
          }
          else {
            dataNormalization = 'area';
          }
        };
        return dataNormalization;
      }

      /**
       * Answers true iff I am rendering boolean values. As of March 2021,
       * the only boolean layer has a dataSource of 'HotSpots'.
       */
      const isBooleanView = () => {return ('HotSpots' === mapControls.dataSource)};

      const isByPopulation = () => {
        return ((mapControls.isByPopulation) && !(mapControls.isByArea))
      }

      /**
       * Answers an html fragment derived from aValue based on the current
       * mapControl settings (boolean or numeric)
       */
      const selectedCovidVariableAsHTML = () => {
        let featureValue = selectedFeature.getProperty('covid_variable');
        let html;
        if ((mapControls.dataSource === 'Vax') && (isByPopulation())) {
          featureValue = featureValue / 1000;
          html = dataLoaderRef.current.htmlForValue_(featureValue);
          html += '%'
        } else {
          html = dataLoaderRef.current.htmlForValue_(featureValue);
        }
        return html;
      }

      const renderAnalysisResultsRow = () => {
        if ('EdgeIntensity' !== mapControls.analyzerName) {
          return '';
        }
        const analysisResultsRow = `<tr class="data-window-text"><td>AnalysisResults:</td><td>${mapControls.analyzerName}</td></tr>`
        return analysisResultsRow
      };

      const dataSourceAsHTML = () => {
        const dataSourceAsHTML = ("Vax" === mapControls.dataSource) ? 'Vaccinations' : mapControls.dataSource;
        return dataSourceAsHTML
      }

      const dataCoverageAsHTML = () => {
        const unknownShare = vaxUnknownCountyShareByState[selectedFeature.getProperty('STATE')]
        const dataCoverageAsHTML = `${100 - parseFloat(unknownShare)}%`
        return dataCoverageAsHTML
      }

      const dataCoverageRow = () => {
        if ((mapControls.dataSource === 'Vax') && (isByPopulation())) {
          const dataCoverageRow = `<tr class="data-window-text"><td>Data coverage:</td><td>${dataCoverageAsHTML()}</td></tr>`;
          return dataCoverageRow;
        }
        return ''
      }

      const featureName = `${selectedFeature.getProperty('NAME')} ${selectedFeature.getProperty('LSAD')}`;
      const featureState = nameForStateFIPSCode_(selectedFeature.getProperty('STATE'));
      const label = `${featureName}, ${featureState}`;

      const selectedVariableValue = selectedCovidVariableAsHTML()
      const dataNormalization = getDataNormalization();
      const analysisResultsRow = renderAnalysisResultsRow();
      const dataWindowHeader = `<div class="data-window-title"><strong>Covid Data for ${label}</strong></div>`;
      const dataSourceRow = `<tr class="data-window-text"><td>Data source:</td><td>${dataSourceAsHTML()}</td></tr>`;
      const pertainsDateRow = `<tr><td>Pertains Date</td><td>${mapControls.pertainsDate}</td></tr>`;
      const selectedValueRow = `<tr class="data-window-value"><td><strong>Value</strong></td><td><strong>${selectedVariableValue}</strong></td></tr>`
      let dataWindowContent;
      if (isBooleanView()) {
        dataWindowContent = `<div id="dataWindowContentDiv">${dataWindowHeader}<table><tbody>${dataSourceRow}${pertainsDateRow}${selectedValueRow}</tbody></table></div>`;
      } else {
        const dataGrainRow = `<tr class="data-window-text"><td>Data grain:</td><td>${mapControls.dataGrain}</td></tr>`;
        const dataNormalizationRow = `<tr class="data-window-text"><td>Data normalization:</td><td>${dataNormalization}</td></tr>`;
        const dataAdjustmentRow = `<tr class="data-window-text"><td>Data Adjustment:</td><td>${mapControls.dataAdjustment}</td></tr>`;
        dataWindowContent = `<div id="dataWindowContentDiv">${dataWindowHeader}<table><tbody>${dataSourceRow}${dataCoverageRow()}${dataGrainRow}${dataNormalizationRow}${dataAdjustmentRow}${analysisResultsRow}${pertainsDateRow}${selectedValueRow}</tbody></table></div>`;
      }
      return dataWindowContent;
    },
    [
      mapControls.analyzerName,
      mapControls.dataAdjustment,
      mapControls.dataGrain,
      mapControls.dataSource,
      mapControls.isByArea,
      mapControls.isByPopulation,
      mapControls.pertainsDate,
      nameForStateFIPSCode_,
      selectedFeature,
      dataLoaderRef,
    ]
  );

  const refreshDataWindowContent = useCallback(
    () => {
      // console.log('DataWindowContainer.refreshdataWindowContent()');
      if (selectedFeature === null) {
        // Nothing to do
        // console.log('DataWindowContainer.refreshdataWindowContent(): No selected feature');
        return;
      }
      if (!isDataLoaded) {
        // console.log('DataWindowContainer.refreshdataWindowContent(): No data');
        return;
      }
      // console.log('DataWindowContainer.refreshdataWindowContent(): Update based on new props');
      const newDataWindowContent = getRawDataWindowContent();
      setDataWindowContent(newDataWindowContent);
      // console.log('DataWindowContainer.refreshdataWindowContent(): Done');
    },
    [isDataLoaded, selectedFeature, getRawDataWindowContent]
  );

  useEffect(
    () => {
      refreshDataWindowContent();
    },
    [dataWindowContent, refreshDataWindowContent]
  );

  const onLoad = (aRawInfoWindow) => {
    // console.log('DataWindowContainer.onLoad: ', aRawInfoWindow);
    rawDataWindowRef.current = aRawInfoWindow;
    if ('' === dataWindowContent) {
    // // It should be closed if empty
      aRawInfoWindow.close()
    }
    //aRawInfoWindow.set('isOpen', false);
    // console.log('DataWindowContainer.onLoad: Done');
  }

  const onCloseClick = (anEvent) => {
    // console.log('DataWindowContainer.onLoad: ', anEvent);
    closeDataWindow(anEvent);
  }

  const onContentChanged = () => {
    // console.log('DataWindowContainer.onContentChanged():');
    if (null === rawDataWindowRef.current) {
      // console.log('DataWindowContainer.onContentChanged(): rawDataWindowRef is null');
      return;
    }
    // dataWindowContentChanged()
    // console.log('DataWindowContainer.onContentChanged(): Done');
  }

  return (
    <DataWindow
      onLoad={onLoad}
      position={position}
      onContentChanged={onContentChanged}
      onCloseClick={onCloseClick}
      content={dataWindowContent}
    />
  );
};

export default DataWindowContainer;
