/**
 * SceneController.js
 * @copyright: 2022 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 * My container must ensure that my current environmentRef is loaded before
 * calling me.
 *
 * 2022-01-11 (tms):
 *
 * Use mapControls to determine the following dates based on the selected
 * data layer:
 *
 *  - earliestPertainsDate
 *  - latestPertainsDate
 *  - lastUpdateDate
 *
 * Use these to initialize the date picker, validate the date changes, and
 * set the lastUpdateTime.
 */
import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardActions,
  Collapse,
  IconButton,
  Tooltip,
  } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DatePicker from './DatePicker'
import MapSceneController from './map_scene_controller/MapSceneController';

// Silence findDOMNode complaint
import { ThemeProvider } from '@material-ui/core/styles';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';

const unstableTheme = createMuiTheme();

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 360,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: 48,
    width: 360,
  },
  cardActions: {
    marginTop: 0,
    padding: 0,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default function SceneController({ containerZIndex, onChange, mapControls, setMapControls, mapSettings, setMapSettings, environmentRef, ...props }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [pertainsDate, setPertainsDateBasic] = useState(null);
  const [latestPertainsDate, setLatestPertainsDate] = useState(null);
  const [earliestPertainsDate, setEarliestPertainsDate] = useState(null);
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const zOffset = 0;

  function setPertainsDateWrapper(aDateString) {
    // console.log(`SceneController.setPertainsDateWrapper(${aDateString})`);
    setPertainsDateBasic(aDateString);
    // console.log(`SceneController.setPertainsDateWrapper: Done)`);
  }

  /*
   * Set up lastUpdateTime
   */
  useEffect(
    () => {
      let newLastUpdateTime = null;
      switch (mapControls.dataSource) {
        case 'CaseCount':
          newLastUpdateTime = environmentRef.current.nytLastUpdateTime();
          break;
        case 'DeathCount':
          newLastUpdateTime = environmentRef.current.nytLastUpdateTime();
          break;
        case 'Vax':
          newLastUpdateTime = environmentRef.current.cdcLastUpdateTime();
          break;
        case 'HotSpots':
          newLastUpdateTime = environmentRef.current.zeetixHotspotLastUpdateTime();
          break;
        default:
          throw new Error("Missing or invalid dataSource");
      }
      setLastUpdateTime(newLastUpdateTime)
    },
    [mapControls, environmentRef]
  );

  /*
   * Set up latestPertainsDate
   */
  useEffect(
    () => {
      let dateString = null;
      switch (mapControls.dataSource) {
        case 'CaseCount':
          dateString = environmentRef.current.nytLatestPertainsDate();
          break;
        case 'DeathCount':
          dateString = environmentRef.current.nytLatestPertainsDate();
          break;
        case 'Vax':
          dateString = environmentRef.current.cdcLatestPertainsDate();
          break;
        case 'HotSpots':
          dateString = environmentRef.current.zeetixHotspotLatestPertainsDate();
          break;
        default:
          throw new Error("Missing or invalid dataSource");
      }
      setLatestPertainsDate(dateString)
    },
    [mapControls, environmentRef]
  );

  /*
   * Set up earliestPertainsDate
   */
  useEffect(
    () => {
      /**
       * Adjusts the result to reflect data requirements of daily and smoothed
       * datapoints.
       */
      function dateForDateString_(aDateString) {
        /**
        * Adds a leading '0' if aMonthInteger is less than 10.
        */
        function twoDigitStringFromInteger_(anInteger) {
          return anInteger > 9 ? anInteger : '0' + anInteger
        }
        function offsetFromMapControls() {
          let offset = 0;
          if ('daily' === mapControls.dataGrain) {
            offset = 1
          }
          if (   ('smoothing' === mapControls.dataAdjustment)
              || ('anomalies' === mapControls.dataAdjustment)
             ) {
            // smoothing and anomalies require 6 more days
            offset += 6
          }
          return offset;
        }
        /**
         * The es6 Date behavior is completely broken.
         *
         * The string '2000-01-02' corresponds to '2-Jan-2000' in every
         * reasonable context.
         * 
         * Calling "new Date('2000-01-02')" results in the following
         * Date:
         * 'Sat Jan 01 2000 19:00:00 GMT-0500 (Eastern Standard Time)'
         * This is wrong -- it should be:
         * 'Sun Jan 02 2000 19:00:00 GMT-0500 (Eastern Standard Time)'
         *
         * The "getMonth" method decreases the "month" number yet again because
         * it returns a 0-origin, rather than 1-origin, integer.
         *
         * If there are good arguments for this behavior beyond sheer developer
         * laziness, I'd like to hear them.
         *
         *
         */
        const rawDate = new Date(aDateString)
        const offset = offsetFromMapControls();
        const year = rawDate.getFullYear();
        const month = rawDate.getMonth() + 1;       // Who knows why getMonth is 0-origin!
        const day = rawDate.getDate() + 1 + offset  // Fix the broken day parsing
        const adjustedDate = `${year}-${twoDigitStringFromInteger_(month)}-${twoDigitStringFromInteger_(day)}`;
        return adjustedDate;
      }

      let dateString = null;
      switch (mapControls.dataSource) {
        case 'CaseCount':
          dateString = environmentRef.current.nytEarliestPertainsDate();
          break;
        case 'DeathCount':
          dateString = environmentRef.current.nytEarliestPertainsDate();
          break;
        case 'Vax':
          dateString = environmentRef.current.cdcEarliestPertainsDate();
          break;
        case 'HotSpots':
          dateString = environmentRef.current.zeetixHotspotEarliestPertainsDate();
          break;
        default:
          throw new Error("Missing or invalid dataSource");
      }
      const adjustedDate = dateForDateString_(dateString);
      setEarliestPertainsDate(adjustedDate)
    },
    [mapControls, environmentRef]
  )

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  /**
   * Change my local pertains date and also change the
   * pertainsDate of mapControls
   */
  const setPertainsDate_ = useCallback(
    (aDate) => {
      // console.log(`SceneController.setPertainsDate_(${aDate})`);
      // anEvent.preventDefault();
      if (aDate !== pertainsDate) {
        // console.log(`SceneController.setPertainsDate_: Changing local pertainsDate`);
        setPertainsDateWrapper(aDate)
        const newMapControls = {...mapControls, pertainsDate: aDate}
        // console.log(`SceneController.setPertainsDate_: Changing mapControls`);
        setMapControls(newMapControls);
      }
      // console.log(`SceneController.setPertainsDate_: Done`)
    },
    [mapControls, setMapControls, pertainsDate]
  );

  const defaultPertainsDate = useCallback(
    () => {
      let newPertainsDate = null;
      switch (mapControls.dataSource) {
        case 'CaseCount':
          newPertainsDate = environmentRef.current.nytLatestPertainsDate();
          break;
        case 'DeathCount':
          newPertainsDate = environmentRef.current.nytLatestPertainsDate();
          break;
        case 'Vax':
          newPertainsDate = environmentRef.current.cdcLatestPertainsDate();
          break;
        case 'HotSpots':
          newPertainsDate = environmentRef.current.zeetixHotspotLatestPertainsDate();
          break;
        default:
          throw new Error("Missing or invalid dataSource");
        }
      return newPertainsDate;
    },
    [
      mapControls.dataSource,
      environmentRef,
    ]
  );

  /*
   * Refreshes my pertainsDate.
   *
   * If my pertainsDate is different from mapControls.pertainsDate, then use
   * the latter.
   *
   * If mapControls.pertainsDate is null, then use my latest pertains date.
   */
  useEffect(
    () => {
      let newPertainsDate = pertainsDate;
      if (!mapControls.pertainsDate) {
        newPertainsDate = defaultPertainsDate();
      } else if (pertainsDate !== mapControls.pertainsDate) {
        newPertainsDate = mapControls.pertainsDate
      }
      setPertainsDate_(newPertainsDate)
    },
    [
      mapControls.pertainsDate,
      pertainsDate,
      defaultPertainsDate,
      setPertainsDate_]
  )

  const handleDateFieldChange = (anEvent) => {
    console.log(`SceneController.handleDateFieldChange(${anEvent})`);
    const newPertainsDate = anEvent.target.value
    setPertainsDate_(newPertainsDate);
    console.log(`SceneController.handleDateFieldChange: Done`);
  }

  if (!pertainsDate){
    return (
      <div>Loading ...</div>
    )
  }

  const tooltipTitle = `${expanded? 'Close' : 'Open'} dashboard`;
  return (
    <ThemeProvider theme={unstableTheme}>
      <Card
        id="sceneControllerCardID"
        elevation={5}
        className={classes.root}
        style={{zIndex: (containerZIndex+zOffset)}}
      >
        <CardActions
          className={classes.cardActions}
        >
          <Tooltip title={tooltipTitle} placement="right-start" arrow >
            <span>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
              >
                <ExpandMoreIcon />
              </IconButton>
            </span>
          </Tooltip>
          <DatePicker 
            pertainsDate={pertainsDate}
            handlePertainsDateChange={handleDateFieldChange}
            earliestPertainsDate={earliestPertainsDate}
            latestPertainsDate={latestPertainsDate}
          />
          <Box fontSize="0.75rem">
            Updated:<br/>{lastUpdateTime}
          </Box>
        </CardActions>
      </Card>
      <Collapse
        id="sceneControllerCollapseID"
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        <MapSceneController
          handleExpandClick={handleExpandClick}
          mapControls={mapControls}
          setMapControls={setMapControls}
        />
      </Collapse>
    </ThemeProvider>
  );
}
