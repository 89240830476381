/**
 * LegendView.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * I ask the current dataLoaderRef what kind of view I'm rendering and then
 * dispatch accordingly.
 */
import React from 'react';
import BooleanLegendView from './BooleanLegendView'
import SingleEndedLegendView from './SingleEndedLegendView';
import DoubleEndedLegendView from './DoubleEndedLegendView';

import BooleanScale from '../../scale/BooleanScale';
import SingleEndedScale from '../../scale/SingleEndedScale';
// import SingleEndedCentileChooser from '../../scale/SingleEndedCentileChooser';
import SingleEndedSlider from '../../scale/SingleEndedSlider';
import DoubleEndedScale from '../../scale/DoubleEndedScale';
import DoubleEndedSlider from '../../scale/DoubleEndedSlider';

function SingleEndedLegendViewWrapper({ dataLoaderRef, highlightedFeature, minimumScaleString, setMinimumScaleString, maximumScaleString, setMaximumScaleString, refreshFeatures, ...props }) {
  return (
    <SingleEndedLegendView
      id="singleEndedLegendViewID"
      dataLoaderRef={dataLoaderRef}
    >
      <SingleEndedScale
        id="singleEndedScaleID"
        dataLoaderRef={dataLoaderRef}
        highlightedFeature={highlightedFeature}
        refreshFeatures={refreshFeatures}
        minimumScaleString={minimumScaleString}
        maximumScaleString={maximumScaleString}
      />
      <SingleEndedSlider
        id="singleEndedSliderID"
        dataLoaderRef={dataLoaderRef}
        refreshFeatures={refreshFeatures}
        minimumScaleString={minimumScaleString}
        setMinimumScaleString={setMinimumScaleString}
        maximumScaleString={maximumScaleString}
        setMaximumScaleString={setMaximumScaleString}
      />
    </SingleEndedLegendView>
  )
};

function DoubleEndedLegendViewWrapper({ dataLoaderRef, highlightedFeature, minimumScaleString, setMinimumScaleString, maximumScaleString, setMaximumScaleString, refreshFeatures, ...props }) {
  return (
    <DoubleEndedLegendView
      id="doubleEndedLegendViewID"
      dataLoaderRef={dataLoaderRef}
    >
      <DoubleEndedScale
        id="doubleEndedScaleID"
        highlightedFeature={highlightedFeature}
        dataLoaderRef={dataLoaderRef}
        refreshFeatures={refreshFeatures}
        minimumScaleString={minimumScaleString}
        maximumScaleString={maximumScaleString}
      />
      <DoubleEndedSlider
        id="doubleEndedSliderID"
        dataLoaderRef={dataLoaderRef}
        refreshFeatures={refreshFeatures}
        minimumScaleString={minimumScaleString}
        setMinimumScaleString={setMinimumScaleString}
        maximumScaleString={maximumScaleString}
        setMaximumScaleString={setMaximumScaleString}
      />
    </DoubleEndedLegendView>
  )
};

function BooleanLegendViewWrapper({ dataLoaderRef, highlightedFeature, ...props }) {
  return (
    <BooleanLegendView
      id="booleanLegendViewID"
      dataLoaderRef={dataLoaderRef}
    >
      <BooleanScale
        id="booleanScaleID"
        highlightedFeature={highlightedFeature}
        dataLoaderRef={dataLoaderRef}
      />
    </BooleanLegendView>
  )
};

export default function LegendView({ dataLoaderRef, highlightedFeature, minimumScaleString, setMinimumScaleString, maximumScaleString, setMaximumScaleString, refreshFeatures, ...props }) {
  // console.log(`LegendView: Rendering...`);
  if (dataLoaderRef.current === null) {
    return (
      <div id="legendViewLoadingDivID">Loading Legend...</div>
    )
  }
  // console.log(`LegendView: current propertyName = ${dataLoaderRef.current.propertyName()}`);
  // console.log(`LegendView: current metaDatapointID = ${dataLoaderRef.current.metaDatapointID()}`);

  switch (dataLoaderRef.current.legendViewName()) {
    case 'DoubleEndedLegendView': {
      return (
        <DoubleEndedLegendViewWrapper
          dataLoaderRef = {dataLoaderRef}
          highlightedFeature = {highlightedFeature}
          refreshFeatures={refreshFeatures}
          minimumScaleString={minimumScaleString}
          setMinimumScaleString={setMinimumScaleString}
          maximumScaleString={maximumScaleString}
          setMaximumScaleString={setMaximumScaleString}
        />
      );
    }
    case 'SingleEndedLegendView': {
      return (
        <SingleEndedLegendViewWrapper
          dataLoaderRef = {dataLoaderRef}
          highlightedFeature = {highlightedFeature}
          refreshFeatures={refreshFeatures}
          minimumScaleString={minimumScaleString}
          setMinimumScaleString={setMinimumScaleString}
          maximumScaleString={maximumScaleString}
          setMaximumScaleString={setMaximumScaleString}
        />
      );
    }
    case 'BooleanLegendView': {
      return (
        <BooleanLegendViewWrapper
          dataLoaderRef = {dataLoaderRef}
          highlightedFeature = {highlightedFeature}
        />
      );
    }
    default: {
      return (
        <div>Unknown dataLoader</div>
      )
    }
  }
}
