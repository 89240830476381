/**
 * AppContainer.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 * The top-level component of the DataBrowser. Specifies one of:
 *
 *    Box
 *    Container
 *    Grid
 *
 * Once a top-level component is chosen, I will have just one child, a
 * ContentContainer.
 *
 * I'm choosing square paper, hoping to provide minimal furniture.
 *
 * Perhaps this is the root for state and property management?
 */

import React, { useState, useEffect, useCallback } from 'react';
import ContentContainer from './ContentContainer';

export default function AppContainer() {
  // const [defaultPertainsDate, setDefaultPertainsDate] = useState(null);
  const [initialMapControls, setInitialMapControls] = useState(null);
  const [initialMapSettings, setInitialMapSettings] = useState(null);

  /**
   * Make this a function so that it can be conveniently called on any change
   * to defaultPertainsDate.
   */
  const initialMapControlsPreset = useCallback(
    () => {
      return {
        'pertainsDate':             null,
        'dataSource':               'CaseCount',      // 'CaseCount', "DeathCount", "HotSpots'"
        'dataGrain':                'daily',          // 'daily'|'cumulative'
        'dataAdjustment':           'smoothing',           // 'None'|'smoothing'|'anomalies
        'isByPopulation':           true,
        'isByArea':                 false,
        'analyzerName':             'None',           // 'EdgeIntensity'
      }
    },
    []
  );

  /**
  * Collect these from a config file or something.
  */
  const initialMapSettingsPreset = useCallback(
    () => {
      return {
        'center': {lat: 40.0, lng: -100.0},
        'markerPosition': {lat: 40.0, lng: -100.0},
        'dataWindowPosition':  {lat: 40.0, lng: -100.0},
        'zoom': 4,
        'selectedLocation': null,
      }
    },
    []
  );

  // const setDefaultPertainsDateWrapper = (aDate) => {
  //   setDefaultPertainsDate(aDate);
  // }

  // /*
  //  * Set up defaultPertainsDate
  //  */
  // useEffect(
  //   () => {
  //     if (!initialMapControls) {
  //       return;
  //     }
  //     switch (initialMapControls.dataSource) {
  //       case 'CaseCount':
  //         loadNYTLatestPertainsDate({setter: setDefaultPertainsDateWrapper});
  //         break;
  //       case 'DeathCount':
  //         loadNYTLatestPertainsDate({setter: setDefaultPertainsDateWrapper});
  //         break;
  //       case 'Vax':
  //         loadCDCLatestPertainsDate({setter: setDefaultPertainsDateWrapper});
  //         break;
  //       case 'HotSpots':
  //         loadSamlerLatestPertainsDate({setter: setDefaultPertainsDateWrapper});
  //         break;
  //       default:
  //         throw new Error("Missing or invalid dataSource");
  //     }
  //   },
  //   [initialMapControls]
  // )

  useEffect(
    () => {
      setInitialMapControls(initialMapControlsPreset());
      setInitialMapSettings(initialMapSettingsPreset());
    },
    [initialMapControlsPreset, initialMapSettingsPreset]
    );

  /**
   * Coded this way because the ternary conditional operator seems to misbehave.
   * See original (and commented out) attempt below.
   */
  if (!initialMapControls) {
    return (
      <div id="appContainerLoadingID">Loading dataBrowser ...</div>
    );
  } else {
    return (
      <ContentContainer
        initialMapControls={initialMapControls}
        initialMapSettings={initialMapSettings}
      />
    );
  }

  // // The terniary conditional below seems to misbehave for some unknown
  // // reason.
  // // I don't know why the following renders ContentContent while isLoading
  // // is false
  // return (
  //   (
  //     (   (defaultPertainsDate === null)
  //      || (defaultPertainsDate === undefined)
  //      || (initialMapControls === null)
  //      || (initialMapControls.pertainsDate === null)
  //      || (initialMapControls.pertainsDate === undefined)
  //     ) ? (
  //       <div id="appContainerLoadingID">Loading dataBrowser ...</div>
  //     ) : (
  //       <ContentContainer
  //         initialMapControls={initialMapControls}
  //         initialMapSettings={initialMapSettings}
  //         iniialMapLimits={initialMapLimits}
  //       />
  //     )
  //   )
  // );

}
