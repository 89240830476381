/**
 * MapScene.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 * MapScene is a container for MapSceneView, the presentational
 * component of the map in Scene.
 *
 **/

import React from 'react';
import MapSceneView from './MapSceneView';

const MapScene = ({ mapControls, mapSettings, setMapSettings, googleMapKey, environmentRef, environment, metadata, ...props }) => {
  return (
    (('' === googleMapKey) || ({} === mapControls)) ? (
    <div id="mapSceneLoadingDivID">Loading ...</div>
    ) : (
      <MapSceneView 
        mapControls={mapControls}
        mapSettings={mapSettings}
        setMapSettings={setMapSettings}
        environmentRef={environmentRef}
        environment={environment}
        metadata={metadata}
      />
    )
  );
};

export default MapScene;
