/**
  * RendererFactory.js
  * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
  * All rights reserved.
  * 
  * The contents of this file may not be copied, duplicated, or used without the
  * written consent of Zeetix, LLC.
  * 
  * I provide a factory method that answers an AbstractRenderer descendant
  * based on the provided parameters.
  *
  * This can probably done as static behavior in the AbstractRenderer
  * tree, but I don't know how.
   */
import LinearRenderer from './LinearRenderer';
import BooleanRenderer from './BooleanRenderer';

class RendererFactory {

  static createRendererForMapControls_(aMapControls) {
    if ('HotSpots' === aMapControls.dataSource) {
      return new BooleanRenderer();
    }
    return new LinearRenderer();
  }
}

export default RendererFactory;
