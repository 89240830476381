/**
 * DataSourceControl.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * I provide the dashboard that controls a map scene.
 *
 * I am usually the single child of the `Collapse` element of a
 * `ScreenController` that is in turn a sibling of a `Navigator` in an
 *  `Omnibox`.
 * I appear below (in the y-direction) the `Navigator` and screen controller
 * preview buttons. Each of those is 345x48 (as of 31-Mar-2021).
 *
 * I therefore position a 345x48 spacer box/paper/div at top of my root
 * container in order to position my controllers so that they are visible while
 * I am expanded.
 *
 * From static site:
 *  .nicebox (container)
 *    font-family: "Roboto", "Arial", "sans-serif";
 *    font-size: 13px
 *
 *  input element:
 *    
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  } from '@material-ui/core';

const FONT_SIZE=13;

const useStyles = makeStyles((aTheme) => ({
  root: {
    width: 19,
    height: 19,
    marginLeft: 16,
    paddingTop: 0,
    paddingBottom: 0,
  },
  formControlLabel: {
    paddingLeft: 8,
    fontSize: FONT_SIZE,
  },
}));

export default function DataSourceControl({ dataSource, setDataSource, ...props}) {
  const classes = useStyles();

  const handleDataSourceChange = (anEvent) => {
    anEvent.preventDefault();
    setDataSource(anEvent.target.value);
  }

  const TinyRadio = <Radio size="small" classes={{root: classes.root}} />;

  return (
    <FormControl component="fieldset">
      <RadioGroup
        value={dataSource}
        onChange={handleDataSourceChange}
      >
        <FormControlLabel
          classes={{label: classes.formControlLabel}}
          value="CaseCount"
          control={TinyRadio} 
          label="Cases"
        />
        <FormControlLabel
          classes={{label: classes.formControlLabel}}
          value="DeathCount"
          control={TinyRadio} 
          label="Deaths"
        />
        <FormControlLabel
          classes={{label: classes.formControlLabel}}
          value="Vax"
          control={TinyRadio} 
          label="Vaccinations"
        />
        <FormControlLabel
          classes={{label: classes.formControlLabel}}
          value="HotSpots"
          control={TinyRadio} 
          label="HotSpot warning"
        />
      </RadioGroup>
    </FormControl>
  );
};
