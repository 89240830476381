/**
 * The CssBaseline resets margins, paddings, and so on.
 *
 * Navbar needs state ... at least selectedTabIndex
 *
 */
import React from 'react';
import { CssBaseline } from '@material-ui/core';
// import AppContainer from './AppContainer';
// import NavigatorSpike from './NavigatorSpike';
// import LegendSpike from './LegendSpike';
// import AppContainer from './SearchSpike';
import AppContainer from './AppContainer';

const App = () => {
  return (
    <>
      <CssBaseline />
      <AppContainer
      />
    </>
  );
};

export default App;
