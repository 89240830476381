/**
 * Legend.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * I wrap a LegendView in suitable elements so that it is positioned in the
 * desired location.
 *
 */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Paper,
  } from '@material-ui/core';
import LegendView from './legend_view/LegendView';

const LEGEND_WIDTH = '400px';
const LEGEND_HEIGHT = '75px'

const useStyles = makeStyles((theme) => ({
  legendView: {
    maxWidth: LEGEND_WIDTH,
    alignItems: 'center',
    width: LEGEND_WIDTH,
    height: LEGEND_HEIGHT,
  }
}));

export default function Legend({ dataLoaderRef, highlightedFeature, isDataLoaded, refreshFeatures, ...props }) {
  // console.log(`Legend: Rendering with isDataLoaded = ${isDataLoaded}`);
  const classes = useStyles();
  const [minimumScaleString, setMinimumScaleString] = useState('');
  const [maximumScaleString, setMaximumScaleString] = useState('');

  function setMinimumScaleStringWrapper(aString) {
    // console.log(`Legend.setMinimumScaleStringWrapper(${aString})`);
    setMinimumScaleString(aString);
    // console.log(`Legend.setMinimumScaleStringWrapper: Done`);
  };

  function setMaximumScaleStringWrapper(aString) {
    // console.log(`Legend.setMaximumScaleStringWrapper(${aString})`);
    setMaximumScaleString(aString);
    // console.log(`Legend.setMaximumScaleStringWrapper: Done`);
  };

  useEffect(
    () => {
      // console.log(`Legend.useEffect[isDataLoaded, dataLoaderRef] triggered`);
      if ((dataLoaderRef.current !== null) && isDataLoaded) {
        // Do this only when data has just been loaded
        // console.log(`Legend.useEffect[isDataLoaded, dataLoaderRef, range]: Updating minimum and maximum scale string`);
        const newMinimumScaleString = dataLoaderRef.current.minimumScaleString();
        const newMaximumScaleString = dataLoaderRef.current.maximumScaleString();
        setMinimumScaleStringWrapper(newMinimumScaleString);
        setMaximumScaleStringWrapper(newMaximumScaleString);
      }
      // console.log(`Legend.useEffect[isDataLoaded, dataLoaderRef] Done`);
    },
    [isDataLoaded, dataLoaderRef]
  );

  if ((dataLoaderRef.current === null) || !isDataLoaded) {
    // console.log(`Legend: returning "loading" component`);
    return (
      <div id="legendViewLoadingDivID">Loading Legend...</div>
    )
  };

  return (
    <Box
      id="outerLegendBoxID"
      position='absolute'
      height={LEGEND_HEIGHT}
      left='5px'
      bottom='120px'
    >
      <Paper
        id="outerLegendPaperID"
        elevation={5}
      >
        <LegendView
          className={classes.legendView}
          dataLoaderRef={dataLoaderRef}
          highlightedFeature={highlightedFeature}
          refreshFeatures={refreshFeatures}
          minimumScaleString={minimumScaleString}
          setMinimumScaleString={setMinimumScaleStringWrapper}
          maximumScaleString={maximumScaleString}
          setMaximumScaleString={setMaximumScaleStringWrapper}
        />
      </Paper>
    </Box>
  )
}
