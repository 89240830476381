/**
 * Caret.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * Encapsulates Caret for convenience.
 * Work out how to hide and show from consumer.
 * Use the caretPosition to offset my caret to the correct location in my
 * display scale.,
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((aTheme) => ({
  caret: {
    color: 'white',
    display: 'block',
  },
}));

/**
 * Callers should pass floating-point scale offset between 0.0 and 1.0.
 *
 * I convert scaleOffset into a floating-point percentage and scale it by 96%
 * at the same time. This ensures that the caret stays within the right edge of
 * the scale that contains me.
 */
export default function Caret({ scaleOffset, ...props }) {
  const classes = useStyles();
  return (
    <span
      id="dataCaret"
      className={ classes.caret }
      style = {{ paddingLeft: `${96*scaleOffset}%`}}
    >
      {(scaleOffset === null) ? <>&nbsp;</> : <>&#x25c6;</>}
    </span>
  )
}
