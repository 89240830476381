/**
 * SaturatedCaret.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * I indicate when a value is saturated.
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((aTheme) => ({
  caret: {
    color: 'white',
    display: 'block',
  },
}));

/**
 * Callers should pass floating-point scale offset between 0.0 and 1.0.
 *
 * I convert  into a floating-point percentage and scale it by 96%
 * at the same time. This ensures that the caret stays within the right edge of
 * the scale that contains me.
 */
export default function SaturatedCaret({ isSaturated, ...props }) {
  const classes = useStyles();
  return (
    <span
      id="dataCaret"
      className={ classes.caret }
    >
      {(isSaturated) ? <>&#x25c6;</> : <>&nbsp;</>}
    </span>
  )
}
