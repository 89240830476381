/**
 * PaneContainer.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 * The `unstableTheme` element is a work-around for a hopefully-temporary issue
 * in the current version of React regarding ...
 *
 * Much of this is borrowed from the material-ui documentation examples. This
 * uses the "Customized dialogs" example in particular.
 *
  */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  IconButton,
  Typography,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';

import { ThemeProvider } from '@material-ui/core/styles';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';

const unstableTheme = createMuiTheme();

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const PaneTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <ThemeProvider theme={unstableTheme}>
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
          <IconButton
            id="paneCloseButtonID"
            onClick={onClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
      </MuiDialogTitle>
    </ThemeProvider>
  );
});

const PaneContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function PaneContainer({ children, selectedPaneIndex, paneIndex, paneTitle, onClose, ...props }) {
  return (
    <div
      hidden={selectedPaneIndex !== paneIndex}
      id={`paneContainer${selectedPaneIndex}ID`}
      {...props}
    >
      {selectedPaneIndex === paneIndex && (
        <ThemeProvider theme={unstableTheme}>
          <Box
          >
            <Dialog
              id={`paneDialog${paneIndex}ID`}
              open={true}
              scroll="paper"
            >
              <PaneTitle
                id={`dialogTitle${paneIndex}ID`}
                onClose={onClose}
              >
                {paneTitle}
              </PaneTitle>
              <PaneContent dividers >
                {children}
              </PaneContent>
            </Dialog>
          </Box>
        </ThemeProvider>
      )}
    </div>
  );
}
