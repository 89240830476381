/**
 * Markup.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 * Components for wrapping Typography
 */
import React from 'react';
import Typography from '@material-ui/core/Typography';

export function Body2({ children, ...props }) {
  return (
    <Typography
      variant="body2"
      style={{marginTop:  '15px'}}
    >
      {children}
    </Typography>
  )
};

export function H5({ children, ...props }) {
  return (
    <Typography
      variant="h5"
      style={{marginTop:  '15px', marginBottom: '5px'}}
    >
      {children}
    </Typography>
  )
};

export function Subtitle2({ children, ...props }) {
  return (
    <Typography variant="subtitle2" style={{marginTop: '15px'}}>
      {children}
    </Typography>
  )
};

