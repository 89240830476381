/**
 * Scene.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 * I contain the Google Map and its controls. I may someday have other children
 * besides MapScene
 */

import React from 'react';
import SceneView from './SceneView';

/**
 * The container component for SceneView.
 *
 * I connect the scene (in this case, a map) to the rest of the app.
 */
const Scene = ({ mapControls, mapSettings, setMapSettings, googleMapKey, environment, metadata, ...props }) => {

  if (!mapControls.pertainsDate){
    // Wait for my SceneController to set pertainsDate
    return (
      <div>Loading ...</div>
    )
  }

  return (
    <SceneView
      id="SceneViewID"
      mapControls={mapControls}
      mapSettings={mapSettings}
      googleMapKey={googleMapKey}
      setMapSettings={setMapSettings}
      environment={environment}
      metadata={metadata}
    />
  );
};

export default Scene;
