/**
 * DoubleEndedScale.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * My container expects me to return a tr element with five children.
 *
 * Use the caretPosition to offset my caret to the correct location in my
 * display scale.
 */
import React, { useEffect, useState,  } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  } from '@material-ui/core';
import Caret from './Caret';
import SaturatedCaret from './SaturatedCaret';

export default function DoubleEndedScale({ dataLoaderRef, highlightedFeature, minimumScaleString, maximumScaleString, ...props }) {
  const [scaleOffset, setScaleOffset] = useState(null);
  const [isSaturatedMin, setIsSaturatedMin] = useState(false);
  const [isSaturatedMax, setIsSaturatedMax] = useState(false);

  function colorKeyBackgroundString() {return dataLoaderRef.current.colorKeyBackgroundString()}
  function saturatedMinColorKeyString() {return dataLoaderRef.current.saturatedMinColorKeyString()}
  function saturatedMaxColorKeyString() {return dataLoaderRef.current.saturatedMaxColorKeyString()}

  const useStyles = makeStyles((aTheme) => ({
    scaleLegend: {
    },
    colorKeyMinValue: {
      fontSize: '12px',
      display: 'inline-block',
      width: '45px',
    },
    colorKeyMaxValue: {
      fontSize: '12px',
      display: 'inline-block',
      width: '45px',
    },
    colorKey: {
      background: `${colorKeyBackgroundString()}`,
      marginTop: '0px',
      textAlign: 'left',
      fontSize: '1.0em',
      lineHeight: '1.0em',
      width: '200px',
      display: 'inline-block',
    },
    saturatedMinColorKey: {
      background: `${saturatedMinColorKeyString()}`,
      lineHeight: '1.0em',
      display: 'inline-block',
      width: '15px',
    },
    saturatedMaxColorKey: {
      background: `${saturatedMaxColorKeyString()}`,
      lineHeight: '1.0em',
      display: 'inline-block',
      width: '15px',
    },
  }));

  const classes = useStyles();
  useEffect(
    () => {
      if (highlightedFeature !== null) {
        if (dataLoaderRef.current.isFeatureSaturated_(highlightedFeature)) {
          if (highlightedFeature.getProperty("covid_variable") > 0) {
            setIsSaturatedMin(false);
            setIsSaturatedMax(true);
            setScaleOffset(null);
          } else {
            setIsSaturatedMin(true);
            setIsSaturatedMax(false);
            setScaleOffset(null);
          }
        } else {
          const newScaleOffset = dataLoaderRef.current.scaleOffsetForFeature_(highlightedFeature);
          setIsSaturatedMin(false);
          setIsSaturatedMax(false);
          setScaleOffset(newScaleOffset)
        }
      } else {
        setIsSaturatedMin(false);
        setIsSaturatedMax(false);
        setScaleOffset(null);
      }
    },
    [highlightedFeature, dataLoaderRef]
  );

  return (
    <tr id="doubleEndedScaleRowID">
      <td id="doubleEndedScaleRowCell0ID">
        <Box id="saturatedMinColorKeyID" className={classes.saturatedMinColorKey}>
          <SaturatedCaret isSaturated={isSaturatedMin} />
        </Box>
      </td>
      <td id="doubleEndedScaleRowCell1ID">
        <Box id="colorKeyMinValueID" className={classes.colorKeyMinValue}>
          {minimumScaleString}
        </Box>
      </td>
      <td id="doubleEndedScaleRowCell2ID">
        <Box id="colorKeyID" className={classes.colorKey}>
          <Caret scaleOffset={scaleOffset} />
        </Box>
      </td>
      <td id="doubleEndedScaleRowCell3ID">
        <Box id="colorKeyMaxValueID" className={classes.colorKeyMaxValue}>
          {maximumScaleString}
        </Box>
      </td>
      <td id="doubleEndedScaleRowCell4ID">
        <Box id="saturatedMaxColorKeyID" className={classes.saturatedMaxColorKey}>
          <SaturatedCaret isSaturated={isSaturatedMax} />
        </Box>
      </td>
    </tr>
  )
}
