/**
 * DataNormalizationAndAdjustmentControl.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
**/
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Chip,
  Tooltip,
  } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles((aTheme) => ({
  root: {
    position: 'Absolute',
    width: 345,
  },
  actionButton: {
    margin: aTheme.spacing(1),
  }
}));



export default function ActionButtons({ onCancel, onApply, ...props }) {
  const classes = useStyles();

  function handleCancel(anEvent) {
    anEvent.preventDefault();
    onCancel(anEvent)
  }

  function handleApply(anEvent) {
    anEvent.preventDefault();
    onApply(anEvent)
  }

  return (
    <Box
    >
      <Tooltip title="Cancel changes and close dashboard" placement="right-start" arrow >
        <span>
          <Chip
              clickable
              size="small"
              className={classes.actionButton}
              onClick={handleCancel}
              label="Cancel"
          />
        </span>
      </Tooltip>
      <Tooltip title="Accept changes and close dashboard" placement="right-start" arrow >
        <span>
          <Chip
              clickable
              size="small"
              className={classes.actionButton}
              deleteIcon={<DoneIcon />}
              label="Apply"
              onClick={handleApply}
          />
        </span>
      </Tooltip>
   </Box>
  )
}
