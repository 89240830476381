/**
 * ContactPane.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 */
import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { loadRecaptchaSiteKey } from '../../../ServerEnvironment';
import axios from 'axios';
/**
 * From the MUI example form ...
 */
import {
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SENDMAIL_SELECTOR = 'sendContactRequest';
const SENDMAIL_URL = `${process.env.REACT_APP_COVID_URL}:${process.env.REACT_APP_SERVICES_PORT}/${SENDMAIL_SELECTOR}`;

export default function ContactPane({props}) {
  const classes = useStyles();
  const [recaptchaSiteKey, setRecaptchaSiteKey] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [comment, setComment] = useState('');
  const [gReCaptchaValue, setGReCaptchaValue] = useState('');
  const recaptchaRef = useRef(null);

  useEffect(() => {loadRecaptchaSiteKey({setter: setRecaptchaSiteKey})}, []);

  const resetState = () => {
    setFirstName('');
    setLastName('');
    setEmailAddress('');
    setComment('');
    setGReCaptchaValue('');
  };

  const performSendMail = () => {
    // console.log('Sending email ...')
    // const sendMailURL = 'http://tms-dev.zeetix.com:7000/sendContactRequest'
    const queryParts = [
      `firstName=${firstName}`,
      `lastName=${lastName}`,
      `emailAddress=${emailAddress}`,
      `comment=${comment}`,
      `recaptchaToken=${gReCaptchaValue}`,
    ];
    const sendMailData = queryParts.join('&');
    // console.log(`sendMailURL: ${sendMailURL}, sendMailData: ${sendMailData}`);
    axios.post(SENDMAIL_URL, sendMailData)
    .then((aResponse) => {
      // console.log(aResponse);
    })
    .catch((anError) => {
      alert(`SendMail failed with error: ${anError}`);
    });
  };

  const onFormSubmitClick = (anEvent) => {
    anEvent.preventDefault();
    // console.log(`firstName: ${firstName}, lastName: ${lastName}, emailAddress: ${emailAddress}, comment: ${comment}}`)
    performSendMail();
    resetState();
    recaptchaRef.current.reset()
    // console.log('Successfully sent')
  };

  const onRecaptchaChange = (anArg) => {
    // console.log('ContactPanel.onRecaptchaChange: ', anArg);
    setGReCaptchaValue(anArg);
  }

  const onRecaptchaExpired = (anArg) => {
    console.log('ContactPanel.onRecaptchaExpired: ', anArg);
  }

  const onRecaptchaErrored = (anArg) => {
    console.log('ContactPanel.onRecaptchaErrored: ', anArg);
  }

  return (
    <div>
      { ('' === recaptchaSiteKey) ? (
        <div>Loading recaptchaSiteKey from server environment...</div>
      ) : (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography variant="body2" style={{marginTop: '15px'}}>
            We are eager to hear from you. Please use this form to tell us about you.
          </Typography>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={firstName}
                  onChange={anEvent => setFirstName(anEvent.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  value={lastName}
                  onChange={anEvent => setLastName(anEvent.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="emailAddress"
                  autoComplete="email"
                  value={emailAddress}
                  onChange={anEvent => setEmailAddress(anEvent.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label="Comment (Required)"
                  multiline
                  rows={10}
                  name="comment"
                  id="comment"
                  value={comment}
                  onChange={anEvent => setComment(anEvent.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={recaptchaSiteKey}
                  onChange={onRecaptchaChange}
                  onExpired={onRecaptchaExpired}
                  onErrored={onRecaptchaErrored}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={onFormSubmitClick}
            >
              Submit
            </Button>
          </form>
        </div>
      </Container>
      )}
    </div>
  );
}
