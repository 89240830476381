/**
 * OmniboxContainer.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 */

import React from 'react';
import {
  Box,
 } from '@material-ui/core';


import OmniboxView from './OmniboxView';

export default function OmniboxContainer({ onChange, mapControls, setMapControls, mapSettings, setMapSettings, googleMapKey, environmentRef, ...props }) {

  return (
    <Box
      top={8}
      left={8}
      position="absolute"
    >
      <OmniboxView
        onChange={onChange}
        mapControls={mapControls}
        setMapControls={setMapControls}
        mapSettings={mapSettings}
        setMapSettings={setMapSettings}
        googleMapKey={googleMapKey}
        environmentRef={environmentRef}
      />
    </Box>
  );
}
