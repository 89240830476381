/**
 * DatePicker.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 */
export default function DatePicker({ pertainsDate, handlePertainsDateChange, earliestPertainsDate, latestPertainsDate }) {

  const handleChange = (anEvent) => {
    console.log(`DatePicker.handleChange()`);
    handlePertainsDateChange(anEvent);
    console.log(`DatePicker.handleChange: Done`);
  }

  const handleFocus = (anEvent) => {
    console.log(`DatePicker.handleFocus()`);
    console.log(`DatePicker.handleFocus: Done`);
  }

  const handleBlur = (anEvent) => {
    console.log(`DatePicker.handleBlur()`);
    console.log(`DatePicker.handleBlur: Done`);
  }

  const handleInput = (anEvent) => {
    console.log(`DatePicker.handleInput()`);
    console.log(`DatePicker.handleInput: Done`);
  }

  return (
    <div
      id="pertainsDateInput"
      style={{"paddingTop": "5px", "paddingBottom": "5px"}}
    >
      <input 
        type="date"
        id="pertainsDate"
        name="pertainsDate"
        value={pertainsDate}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onInput={handleInput}
        tabIndex={0}
        min={earliestPertainsDate}
        max={latestPertainsDate}
      />
    </div>
  )
}

