/**
 * AboutPane.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 */
import React from 'react';
import {
  Link,
  Typography,
 } from '@material-ui/core';

export default function AboutPane() {
  return (
    <>
    <Typography variant="body2" component="div" style={{marginTop: '15px'}}>
      <p><strong><img
        src="/images/photos/tom_thumb.jpg"
        alt="Tom's head shot"
        width="250px"
        style={{float: 'right', clear: 'both', margin: '15px 0 0 10px'}}
      />
      </strong></p>
      <p style={{marginTop: '15px'}}><strong><Link href="https://www.linkedin.com/in/tomstambaugh/">Thomas Stambaugh </Link>, consultant&nbsp;</strong></p>
      <p>
        Tom helped invent personal computing as we know it today, making important
        contributions in user interface design, object oriented technology, hypermedia,
        groupware, and software development tools. Tom brings more than thirty years of
        related hardware and software industry experience to your problem, including Experian, IBM, Millennium Pharmaceuticals, and more than 25 years of
        experience in startup companies (including his own).
      </p>
    </Typography>
    <p id="samlerSpacer" style={{clear: 'both'}} />
    <Typography variant="body2" component="div" style={{marginTop: '15px'}}>
      <p><strong><img
        src="/images/photos/steve_thumb.jpg"
        alt="Steve's head shot"
        width="250"
        style={{float: 'right', clear: 'both', margin: '15px 0 0 10px'}}
        />
      </strong></p>
      <p style={{marginTop: '15px'}}><strong><Link href="https://www.linkedin.com/in/steve-samler/">Steve Samler</Link>, consultant&nbsp;</strong></p>
      <p>
        Steve found his calling in a postage stamp sized classified ad in the
        Boston Globe back in 1991.  A start-up company was looking for someone with
        knowledge of the telecom industry that could review newswire output culled
        by an AI engine.  The output would be faxed as custom newspapers overnight
        to customers at the carriers and equipment manufacturers looking to get a
        leg up on the competition.  The company was Individual Inc.
      </p>
      <p>
        After a few mergers, Steve ended up working for the R&D group of Thomson in
        Eagan MN, lending his expertise in company name discovery and resolution to
        the legal domain.
      </p>
      <p>
        Most recently, and also via a few mergers, Steve worked for Experian
        helping to extract people identities and resolve them to consumer data,
        criminal data and social media data.
      </p>
      <p>
        We don't know yet where this Covid data collection will lead, but hope to
        make it more useful by aligning it with other data organized by state and
        county.
      </p>
    </Typography>
    <p id="gregerSpacer" style={{clear: 'both'}} />
    <Typography variant="body2" component="div" style={{marginTop: '15px'}}>
      <p><strong>
        <img
          src="/images/photos/valerie_thumb.jpg"
          alt="Valerie's head shot"
          width="250"
        style={{float: 'right', clear: 'both', margin: '15px 0 0 10px'}}
        />
      </strong></p>
      <p style={{marginTop: '15px'}}><strong><Link href="https://www.linkedin.com/in/valeriegreger/">Valerie Greger</Link></strong></p>
      <p>
        Valerie is an accomplished scientist and leader with extensive
        experience in human genetics, genomics, molecular biology and
        bioinformatics. 
      </p>
      <p>
        Valerie is passionate about creating value from scientific
        discoveries and transforming ideas into reality. She shows
        proven ability to successfully design, plan and execute on
        client deliverables. She brings a strategic view balanced by
        strong analytical skills. 
      </p>
      <p>
        Valerie is experienced in working in global environments with
        multidisciplinary teams. She thrives in start-up environments.
      </p>
      <p>
        Specialties: clinical diagnostics, carrier screening, medical
        genetics, variant evaluation, data curation, genome
        interpretation, data analysis, NextGen sequencing, personalized
        medicine, genomic medicine, rare diseases, Ashkenazi Jewish
        disorders, hearing loss. 
      </p>
    </Typography>
    </>
  );
}
