/**
 * DoubleEndedSlider.js
 * @copyright: 2022 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * I must get the initial range from the limits of my dataLoaderRef. I use a
 * Slider instance to change those limits, and apply the change directly to the
 * dataLoader.
 *
 */
import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  Slider,
  } from '@material-ui/core';

const useStyles = makeStyles((aTheme) => ({
  saturatedBox: {
    background: 'white',
    lineHeight: '1.0em',
    display: 'inline-block',
    width: '15px',
  },
}));

export default function DoubleEndedSlider({ dataLoaderRef, refreshFeatures, minimumScaleString, setMinimumScaleString, maximumScaleString, setMaximumScaleString, }) {
  const [lowerLimitCentile, setLowerLimitCentile] = useState('')
  const [upperLimitCentile, setUpperLimitCentile] = useState('')

  function setLowerLimitCentileWrapper(aCentile) {
    // console.log(`DoubleEndedSlider.setLowerLimitCentileWrapper(${aCentile})`);
    setLowerLimitCentile(aCentile)
  }

  function setUpperLimitCentileWrapper(aCentile) {
    // console.log(`DoubleEndedSlider.setUpperLimitCentileWrapper(${aCentile})`);
    setUpperLimitCentile(aCentile)
  }

  const classes = useStyles();
  const updateScaleStrings = () => {
    const newMinimumScaleString = dataLoaderRef.current.minimumScaleString()
    const newMaximumScaleString = dataLoaderRef.current.maximumScaleString()
    setMinimumScaleString(newMinimumScaleString);
    setMaximumScaleString(newMaximumScaleString);
  }

  const handleSliderChange = (anEvent, aNewValue) => {
    // console.log(`DoubleEndedSlider.handleSliderChange(${anEvent}, ${aNewValue})`);
    const [newLowerLimitCentile, newUpperLimitCentile] = aNewValue;
    setLowerLimitCentileWrapper(newLowerLimitCentile);
    setUpperLimitCentileWrapper(newUpperLimitCentile);
    // console.log(`DoubleEndedSlider.handleSliderChange: Update my dataLoader`);
    dataLoaderRef.current.range_([newLowerLimitCentile, newUpperLimitCentile]);
    // console.log(`DoubleEndedSlider.handleSliderChange: Update scale strings`);
    updateScaleStrings();
    // console.log(`DoubleEndedSlider.handleSliderChange: Refresh features`);
    refreshFeatures();
    // console.log(`DoubleEndedSlider.handleSliderChange: Done`);
  };


  const lowerLimitCentilePreset = useCallback(
    () => {
      // console.log(`SingleEndedSlider.lowerLimitCentilePreset()`);
      const centile = dataLoaderRef.current.lowerLimitCentile();
      // console.log(`SingleEndedSlider.lowerLimitCentilePreset(): returning ${centile}`);
      return centile
    },
    [dataLoaderRef]
  );

  const upperLimitCentilePreset = useCallback(
    () => {
      // console.log(`SingleEndedSlider.upperLimitCentilePreset())`);
      const centile = dataLoaderRef.current.upperLimitCentile();
      // console.log(`SingleEndedSlider.upperLimitCentilePreset(): returning ${centile}`);
      return centile
    },
    [dataLoaderRef]
  );

  useEffect(
    () => {
      setLowerLimitCentile(lowerLimitCentilePreset());
    },
    [dataLoaderRef, lowerLimitCentilePreset]
  );

  useEffect(
    () => {
      setUpperLimitCentile(upperLimitCentilePreset());
    },
    [dataLoaderRef, upperLimitCentilePreset]
  );

  if (lowerLimitCentile === '' || upperLimitCentile === '') {
    return (
      <tr>
        <td colSpan={5}>
          Loading ...
        </td >
      </tr>
    )
  };

  return (
    <tr>
      <td id="doubleEndedSliderCell0ID">
        <Box className={classes.saturatedBox}>
          <span><>&nbsp;</></span>
        </Box>
      </td>
      <td id="sliderCellID" colSpan={3}>
        <Slider
          value={[lowerLimitCentile, upperLimitCentile]}
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
        />
       </td>
      <td id="doubleEndedSliderCell4ID" >
        <Box id="saturatedMaxColorKeyID" className={classes.saturatedBox}>
          <span><>&nbsp;</></span>
        </Box>
      </td>

    </tr>
  )
}
