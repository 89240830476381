/**
 * DataLoaderFactory.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 */
import BooleanDataLoader from './BooleanDataLoader';
import AnalysisResultsDataLoader from './AnalysisResultsDataLoader';
import DataLoader from './DataLoader';
import MetadataLoader from './MetadataLoader';
import StylerFactory from '../styler/StylerFactory';

class DataLoaderFactory {

  static createForMapControls_metadata_directorContext_(aMapControls, aMetadata, aDirectorContext) {
    const loaderName = ('HotSpots' === aMapControls.dataSource) ? 'booleanDataLoader' : (('EdgeIntensity' === aMapControls.analyzerName) ? 'analysisResultsDataLoader': 'dataLoader');
    const styler = StylerFactory.createForMapControls_metadata_directorContext_(aMapControls, aMetadata, aDirectorContext);
    switch (loaderName) {
      case 'booleanDataLoader': {
        return new BooleanDataLoader({ mapControls: aMapControls, featureStyler: styler, directorContext: aDirectorContext});
      }
      case 'analysisResultsDataLoader': {
        return new AnalysisResultsDataLoader({ mapControls: aMapControls, featureStyler: styler, directorContext: aDirectorContext });
      }
      case 'dataLoader': {
        return new DataLoader({ mapControls: aMapControls, featureStyler: styler, directorContext: aDirectorContext });
      }
      default: {
        const complaint = `Invalid or missing map controls: ${aMapControls}`;
        throw new Error(complaint);
      }
    }
  };

  static createForMetadataDirectorContext_(aDirectorContext) {
    let metadataLoader = new MetadataLoader({ mapControls: null, featureStyler: null, directorContext: aDirectorContext });
    return metadataLoader;
  }

}

export default DataLoaderFactory;
