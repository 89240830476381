/**
 * OmniboxView.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * The common parent of the Navigator and SceneController
 *
 * I need to be absolute to make my top and left position be 8, yet I need to
 * be relative for my children to position themselves within me. Hmmm.
 *
 * Note that there is no document element corresponding to my SceneController.
 *
 * 03-May-2021 (tms):
 *  Restorted the Navigator after the alpha release
 *
 * 07-Apr-2021 (tms):
 *
 *  Removed the Navigator for the alpha release
 */

import React from 'react';
import {
  Box,
 } from '@material-ui/core';

import SceneController from './scene_controller/SceneController';
import Navigator from './navigator/Navigator';

export default function OmniboxView({ onChange, mapControls, setMapControls, mapSettings, setMapSettings, googleMapKey, environmentRef, ...props }) {

  const containerZIndex = 5;
  return (
    <Box
      id="omniboxViewBoxID"
    >
      <Navigator
        id="omniboxViewNavigatorID"
        containerZIndex={containerZIndex}
        googleMapKey={googleMapKey}
        mapSettings={mapSettings}
        setMapSettings={setMapSettings}
      />
      <SceneController
        containerZIndex={containerZIndex}
        mapControls={mapControls}
        setMapControls={setMapControls}
        mapSettings={mapSettings}
        setMapSettings={setMapSettings}
        environmentRef={environmentRef}
      />
    </Box>
  );
}
