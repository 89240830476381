/**
 * DoubleEndedLegendView.js
 * @copyright: 2022 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * I render double-ended numeric scale
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  } from '@material-ui/core';

// // import LegendLabel from './LegendLabel';
// import SingleEndedScale from './scale/SingleEndedScale';
// import DoubleEndedScale from './scale/DoubleEndedScale';

const useStyles = makeStyles((aTheme) => ({
  root: {
    display: 'inline',
    marginLeft: "10px",
  },
  scaleLegendLabel: {
    width: '200px',
    display: 'inline-block',
    textAlign: 'center'
  },
  scaleLegendLabelLeft: {
    width: '25px',
    display: 'inline-block',
  },
  scaleLegendLabelRight: {
    width: '25px',
    display: 'inline-block',
  },
  scaleLegendLabelSaturated: {
    width: '25px',
    display: 'inline-block',
  },
  scaleLegendDiv: {
    lineHeight: '1.0em',
  },
}));

export default function DoubleEndedLegendView({ dataLoaderRef, children, ...props }) {
  const classes = useStyles();

  const getSourceString = () => {
    const mapControls = dataLoaderRef.current._mapControls;
    let dataSourceString = '';
    switch (mapControls.dataSource) {
      case 'CaseCount':
        dataSourceString = 'case count';
        break;
      case 'DeathCount':
        dataSourceString = 'death count';
        break;
      case 'Vax':
        dataSourceString = 'vaccinations';
        break;
      default:
        break;
    }
    const sourceString = `${mapControls.dataGrain} ${dataSourceString}`;
    return sourceString
  }

  const getNormalizationString = () => {
    const mapControls = dataLoaderRef.current._mapControls;
    let normalizationString = '';
    if (mapControls.isByPopulation && mapControls.isByArea) {
      normalizationString = 'per 100M person-mile'
    }
    if (mapControls.isByPopulation && !mapControls.isByArea) {
      normalizationString = 'per 100K person'
    }
    if (!mapControls.isByPopulation && mapControls.isByArea) {
      normalizationString = 'per 1K square mile'
    }
    return normalizationString
  }

  const getPrefixString = () => {
    const mapControls = dataLoaderRef.current._mapControls;
    if ('None' !== mapControls.analyzerName) {
      // No prefix for any Analyzer results
      return ''
    }
    const prefixString = ('smoothing' === mapControls.dataAdjustment) ? 'smoothed' : '';
    return prefixString;
  }

  const getSuffixString = () => {
    const mapControls = dataLoaderRef.current._mapControls;

    const normalizationString = getNormalizationString()
    const anomaliesString = ('anomalies' === mapControls.dataAdjustment) ? `(${mapControls.dataAdjustment})` : '';
    // Maybe more than one AnalzerName someday
    let analyzerString = '';
    switch (mapControls.analyzerName) {
      case 'EdgeIntensity':
        analyzerString = '(edge intensity)';
        break;
      default:
        break;
    };
    /*
    If anaylizerString is not empty, then no anomaliesString or normalizationString.
    */
    let suffixString = ''
    if (analyzerString) {
      suffixString = `${analyzerString}`
      return suffixString;
    }
    if (normalizationString || anomaliesString) {
      suffixString = `${normalizationString} ${anomaliesString}`;
    }
    return suffixString;
  }

  const getLegendString = () => {
    const sourceString = getSourceString();
    const prefixString = getPrefixString();
    const suffixString = getSuffixString();
    let legendString ;
    if (prefixString) {
      legendString = `${prefixString} ${sourceString}`;
    } else {
      legendString = `${sourceString}`;
    }
    if (suffixString) {
      legendString += ` ${suffixString}`;
    }
    return legendString
  }

  const legendString = getLegendString();

  return (
    <table id="doubleEndedLegendTableID">
      <tbody id="doubleEndedLegendTableBodyID">
        <tr id="doubleEndedLegendLabelRowID">
          <td id= "doubleEndedLegendLabelCell0ID">
            <Box
              id="scaleLegendLabelSaturatedMinID"
              className={classes.scaleLegendLabelSaturated}
              fontWeight="fontWeightMedium"
              fontSize={16}
            >
              &lt;
            </Box>
          </td>
          <td id= "doubleEndedLegendLabelCell1ID">
            <Box
              id="scaleLegendLabelMinID"
              className={classes.scaleLegendLabelLeft}
              fontWeight="fontWeightMedium"
              fontSize={12}
            >
              Min
            </Box>
          </td>
          <td id= "doubleEndedLegendLabelCell2ID">
            <Box
              id="scaleLegendLabelID"
              className={classes.scaleLegendLabel}
              fontWeight="fontWeightMedium"
              fontSize={12}
            >
              {legendString}
            </Box>
          </td>
          <td id= "doubleEndedLegendLabelCell3ID">
            <Box
              id="scaleLegendLabelMaxID"
              className={classes.scaleLegendLabelRight}
              fontWeight="fontWeightMedium"
              fontSize={12}
            >
              Max
            </Box>
          </td>
          <td id= "doubleEndedLegendLabelCell4ID">
            <Box
              id="scaleLegendLabelSaturatedMaxID"
              className={classes.scaleLegendLabelSaturated}
              fontWeight="fontWeightMedium"
              fontSize={16}
            >
              &gt;
            </Box>
          </td>
        </tr>
        {children}
      </tbody>
    </table>
  )
}
