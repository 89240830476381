/**
 * DataNormalizationAndAdjustmentControl.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * I provide the dashboard that controls a map scene.
 *
 * I am usually the single child of the `Collapse` element of a
 * `ScreenController` that is in turn a sibling of a `Navigator` in an
 *  `Omnibox`.
 * I appear below (in the y-direction) the `Navigator` and screen controller
 * preview buttons. Each of those is 345x48 (as of 31-Mar-2021).
 *
 * I therefore position a 345x48 spacer box/paper/div at top of my root
 * container in order to position my controllers so that they are visible while
 * I am expanded.
 *
 * From static site:
 *  .nicebox (container)
 *    font-family: "Roboto", "Arial", "sans-serif";
 *    font-size: 13px
 *
 *  input element:
 *    
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  } from '@material-ui/core';

const FONT_SIZE=13;

const useStyles = makeStyles((aTheme) => ({
  root: {
    width: 19,
    height: 19,
    marginLeft: 16,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: FONT_SIZE,
  },
  formControlLabel: {
    paddingLeft: 8,
    fontSize: FONT_SIZE,
  },
}));

export default function DataNormalizationAndAdjustmentControl({
  isByPopulation,
  setIsByPopulation,
  isByArea,
  setIsByArea,
  dataAdjustment,
  setDataAdjustment,
  ...props
}) {
  const classes = useStyles();

  const handleDataAdjustmentChange = (anEvent) => {
    anEvent.preventDefault();
    setDataAdjustment(anEvent.target.value);
  }

  const handleIsByPopulationChange = (anEvent) => {
    anEvent.preventDefault();
    setIsByPopulation(anEvent.target.checked);
  }

  const handleIsByAreaChange = (anEvent) => {
    anEvent.preventDefault();
    setIsByArea(anEvent.target.checked);
  }

  const TinyRadio = <Radio size="small" classes={{root: classes.root}} />;
  function TinyCheckbox({ isChecked, onChange }) {
    return (
      isChecked ? (
        <Checkbox checked onChange={onChange} size="small" classes={{root: classes.root}} />
      ) : (
      <Checkbox onChange={onChange} size="small" classes={{root: classes.root}} />
      )
    )
  }

  return (
    <Grid
      container
      spacing={0}
      justifyContent="flex-start"
      alignItems="center"
    >
      <FormControl component="fieldset">
        <FormGroup>
          <FormControlLabel
            classes={{label: classes.formControlLabel}}
            value={isByPopulation}
            control={
              <TinyCheckbox isChecked={isByPopulation} onChange={handleIsByPopulationChange} />
            } 
            label="per 100K Population"
          />
          <FormControlLabel
            classes={{label: classes.formControlLabel}}
            value={isByArea}
            control={
              <TinyCheckbox isChecked={isByArea} onChange={handleIsByAreaChange} />
            } 
            label="Per 1K square miles"
          />
        </FormGroup>
      </FormControl>
      <Divider variant="middle" orientation="vertical" flexItem />
      <FormControl component="fieldset">
        <RadioGroup
          value={dataAdjustment}
          onChange={handleDataAdjustmentChange}
        >
          <FormControlLabel
            classes={{label: classes.formControlLabel}}
            value="None"
            control={TinyRadio}
            label="No adjustment"
          />
          <FormControlLabel
            classes={{label: classes.formControlLabel}}
            value="smoothing"
            control={TinyRadio} 
            label="Smoothed"
          />
          <FormControlLabel
            classes={{label: classes.formControlLabel}}
            value="anomalies"
            control={TinyRadio} 
            label="Anomalies"
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};
