/**
  * BooleanRenderer.js
  * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
  * All rights reserved.
  * 
  * The contents of this file may not be copied, duplicated, or used without the
  * written consent of Zeetix, LLC.
  *
  * I'm called with a CovidValue of true or false, and I just answer that value.
  */

import AbstractValueRenderer from './AbstractValueRenderer';

class BooleanRenderer extends AbstractValueRenderer{

  doRenderedValueFor_(aCovidValue) {
    return aCovidValue
  };

  doScaleOffsetForValue_isDoubleEnded_(aCovidValue, isDoubleEnded) {
    if ((aCovidValue === null)||(aCovidValue === undefined)) {
      return null;
    }
    return aCovidValue
  }

  doHTMLForValue_(aCovidValue) {
    const html =  aCovidValue ? 'True': 'False';
    return html
  };

  doDisplayScale() {
    return "Boolean"
  }

  doIsSaturatedValue_(aCovidValue) {
    return false
  }

  doIsSaturatedMaxValue_(aCovidValue) {
    return false
  }

  doIsSaturatedMinValue_(aCovidValue) {
    return false
  }

  doMinimumScaleString() {return ''}

  doMaximumScaleString() {return ''}

}

export default BooleanRenderer;
