/**
 * PaneLink.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * Renders a link that changes focus to another TabPanel
 */
import React from 'react';
import Link from '@material-ui/core/Link';

/**
 * Pass any click to the function bound to onLinkClick
 */
export default function PaneLink({ label, onLinkClick, ...props }) {
  function onClickHandler(anEvent) {
    anEvent.preventDefault();
    onLinkClick(label);
  }
  const localURL = `/${label}`;
  return (
    <Link 
      component="a"
      rel="noopener"
      target="_blank"
      onClick={onClickHandler}
      href={localURL}
      {...props}
    />
  );
}
