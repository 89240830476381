/**
 * TextSearchField.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * This uses examples from the material-ui documentation:
 *    Custom search:
 *      https://material-ui.com/components/autocomplete/
 *
 * I want the semantics to match the demo, but the cosmetics to match the Map
 * API. That means:
 *
 *  - No label
 *  - Disable search button unless a list item is chosen
 *  - etc
 * Documentation for places API:
 *    Google Places:
 *      Documentation:
 *        https://developers.google.com/maps/documentation/places/web-service/details
 *      Format:
 *        https://maps.googleapis.com/maps/api/place/details/output?parameters
 *
 *        Required parameters:
 *          key
 *          place_id
 *      Example URI:
 *        https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJN1t_tDeuEmsRUsoyG83frY4&fields=geometry&key=YOUR_API_KEY
 *
 */
import React from 'react';
import { 
  useEffect,
  useMemo,
  useRef,
  useState,
  } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography
  } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import {
  Search as SearchIcon,
  Close as CloseIcon,
  LocationOn as LocationOnIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((aTheme) => ({
  icon: {
    color: aTheme.palette.text.secondary,
    marginRight: aTheme.spacing(2),
  },
  iconButton: {
    padding: 10,
  },
  input: {
    marginLeft: aTheme.spacing(1),
    flex: 1,
  },
}));

const autocompleteService = { current: null };
const placesService = { current: null };

/**
 * Disabled until text is present.
 */
function OptionalClearButton({ onClick, isDisabled, ...props }) {
  const classes = useStyles();
  return (
    <IconButton
      color="primary"
      onClick={onClick}
      disabled={isDisabled}
      className={classes.iconButton}
      aria-label="clear search string"
    >
      <CloseIcon />
    </IconButton>
  )
}

/**
 * I collect a string from the user and find a corresponding feature.
 *
 *  searchString: The string collected from the user and sent to the Google
*                 places API.
 *  places:       An array of places returned by the Google places API for the
*                 current search string
*   place:         A place selected by the user from the places array
 *
 *  Behavior:
 *    Characters are collected one at a time from the `TextField` of my
 *    `Autocomplete` widget. As each character is collected, my `places` array
 *    is populated by the Google Places API. When the user accepts an item from
 *    my `places` list, my `searchString` is filled to match and my `place` 
 *    field is assigned to the selected `place`.
 *
 *    My `Search` button is enabled while I have a non-null `place`. When my
 *    `search` button is clicked, my handleSearchSubmit function is called with
 *    the current value of my `place` state.
 */
export default function TextSearchField({ handleSearchSubmit, handleClearSearchField, googleMapKey, ...props }) {
  const classes = useStyles();
  const [place, setPlace] = useState(placePreset());
  const [searchString, setSearchString] = useState(searchStringPreset());
  const [places, setPlaces] = useState(placesPreset());
  const [isSearchDisabled, setIsSearchDisabled] = useState(isSearchDisabledPreset());
  const [isClearDisabled, setIsClearDisabled] = useState(isClearDisabledPreset);
  const [placeDetails, setPlaceDetails] = useState(null);

  const arePlaceDetailsLoaded = useRef(false);

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  useEffect(() => {
    // console.log(`TextSearchField.useEffect[place, searchString, fetch]()`);
    let isActive = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      // console.log(`TextSearchField.useEffect: autocompleteService not loaded`);
      return undefined;
    }

    if (searchString === '') {
      setPlacesWrapper(place ? [place] : []);
      // console.log(`TextSearchField.useEffect: No search string`);
      return undefined;
    }

    // console.log(`TextSearchField.useEffect: Fetch places`);
    fetch({ input: searchString, componentRestrictions: { country: ["us", "pr"] }}, (aResultList) => {
      if (isActive) {
        let newPlaces = [];

        if (place) {
          newPlaces = [place];
        }

        if (aResultList) {
          newPlaces = [...newPlaces, ...aResultList];
        }

        setPlacesWrapper(newPlaces);
      }
    });

    // console.log(`TextSearchField.useEffect: Done`);
    return () => {
      isActive = false;
    };
  }, [place, searchString, fetch]);

  /**
   * I'm called when a valid placeDetails is received.
   */
  useEffect(
    () => {
      // console.log(`TextSearchField.useEffect[arePlaceDetailsLoaded, placeDetails]())`);
      // console.log(`arePlaceDetailsLoaded: ${arePlaceDetailsLoaded}`);
      // console.log(`placeDetails: ${placeDetails}`);
      if ((!arePlaceDetailsLoaded) || (null === placeDetails)) {
        // console.log(`TextSearchField.useEffect: no placeDetails`);
        return;
      }
      setIsSearchDisabled(false);
    },
    [arePlaceDetailsLoaded, placeDetails]
  )

  // Begin state management functions

  function placePreset() {return null;}
  function searchStringPreset() {return '';}
  function placesPreset() {return [];}
  function isSearchDisabledPreset() {return true;}
  function isClearDisabledPreset() {return true;}
  function placeDetailsPreset() {return null;}
  function arePlaceDetailsLoadedPreset() {return false;}

  /**
   * Revert to my initial state
   */
  function reset() {
    setPlace(placePreset());
    setPlaces(placesPreset());
    setSearchString(searchStringPreset());
    setIsSearchDisabled(isSearchDisabledPreset());
    setIsClearDisabled(isClearDisabledPreset());
    setPlaceDetails(placeDetailsPreset());
    arePlaceDetailsLoaded.current = arePlaceDetailsLoadedPreset();
  }

  /**
   * Get the geometry of the current place, then get its featureDetails.
   */
  function searchForPlace_(aPlace) {
    // // console.log(`TextSearchField.searchForPlace(${aPlace})`);
    const sacrificialDiv = document.createElement('div');
    if (!placesService.current && window.google) {
      placesService.current = new window.google.maps.places.PlacesService(sacrificialDiv);
    }
    if (!placesService.current) {
      // console.log(`TextSearchField.searchForPlace: placesService not loaded`);
      return undefined;
    }
    setPlaceWrapper(aPlace);
    const getPlaceDetailsRequest = {
      placeId: aPlace.place_id,
      fields: ['geometry'],
    }
    placesService.current.getDetails(getPlaceDetailsRequest, handleGetDetailsResponse);
    // console.log(`TextSearchField.searchForPlace: Done`);
  }

  function handleGetDetailsResponse(aPlace, aStatus) {
    // console.log(`TextSearchField.handleGetDetailsResponse(${aPlace}, ${aStatus})`);
    if (aStatus === window.google.maps.places.PlacesServiceStatus.OK) {
        setPlaceDetailsWrapper(aPlace);
        arePlaceDetailsLoaded.current=true;
      }
  }

  function setSearchStringWrapper(anInputSearchString) {
    // console.log(`TextSearchField.setSearchString(${anInputSearchString})`);
    setSearchString(anInputSearchString);
    setIsClearDisabled(false);
    // console.log(`TextSearchField.setSearchString(): Done`);
  }

  function setPlaceDetailsWrapper(aPlaceDetails) {
    // console.log(`TextSearchField.setPlaceDetailsWrapper(${aPlaceDetails})`);
    setPlaceDetails(aPlaceDetails);
  }

/**
 * I am called when a specific place is chosen by the user.
 */
  function setPlaceWrapper(aSelectedPlace) {
    // console.log(`TextSearchField.setPlace(${aSelectedPlace})`);
    setPlace(aSelectedPlace);
    // console.log(`TextSearchField.setPlace(): Done`);
  }

  function setPlacesWrapper(aPlaceList) {
    // console.log(`TextSearchField.setPlaces(${aPlaces})`);
    setPlaces(aPlaceList);
  }

  function handleClearSearchStringClick(anEvent) {
    // console.log(`TextSearchField.handleClearSearchStringClick(${anEvent}`);
    reset();
  }

  function handleAutocompleteChange(anEvent, aSelectedPlace) {
    // console.log(`TextSearchField.handleAutocompleteChange(${anEvent}, ${aSelectedPlace})`);
    setPlacesWrapper(aSelectedPlace ? [aSelectedPlace, ...places] : places);
    searchForPlace_(aSelectedPlace);
  }

  /**
   * Calls handleSearchSubmit with the current placeDetails
   */
  function handleSearchClick(anEvent) {
    anEvent.preventDefault();
    // console.log(`TextSearchField.handleSearchSubmitClick(${anEvent})`);
    handleSearchSubmit(placeDetails);
    // console.log(`TextSearchField.handleSearchSubmitClick(): Done`);
  }

  return (
    <>
      <Autocomplete
        disableClearable
        size="small"
        id="google-map-demo"
        style={{ width: 260}}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={places}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={place}
        // onChange={(event, aSelectedPlace) => {
        //   setPlacesWrapper(aSelectedPlace ? [aSelectedPlace, ...places] : places);
        //   setPlaceWrapper(aSelectedPlace);
        // }}
        onChange={handleAutocompleteChange}
        onInputChange={(event, anInputSearchString) => {
          setSearchStringWrapper(anInputSearchString);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search for feature"
          />
        )}
        renderOption={(option) => {
          const matches = option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
          );

          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
      <IconButton
        id="navigatorSearchButtonID"
        onClick={handleSearchClick}
        disabled={isSearchDisabled}
        type="submit"
        className={classes.iconButton}
      >
        <SearchIcon />
      </IconButton>
      <Divider className={classes.divider} orientation="vertical" />
      <OptionalClearButton
        onClick={handleClearSearchStringClick}
        isDisabled={isClearDisabled}
      />
    </>
  )

};
