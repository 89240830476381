/**
  * AbstractFeatureStyler.js
  * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
  * All rights reserved.
  * 
  * The contents of this file may not be copied, duplicated, or used without the
  * written consent of Zeetix, LLC.
  * 
  * Each descendant of AbstractFeatureStyler exports a function that, when
  * called with a feature as its argument, renders that feature in the current
  * map. In March of 2021, the following three descendants exist:
  *
  *  BooleanFeatureStyler
  *  DoubleEndedFeatureStyler
  *  SingleEndedFeatureStyler
  *
  * Each AbstractFeatureStyler uses props provided by the MapView that uses it.
  *
  * The colorKey is an array of tuples used to color each feature and the
  * scale. Each descendant expects a colorKey of a fixed size, and uses each
  * tuple to color features of a specific value range. The tuples are in order
  * from lowest to highest value of the corresponding property.
  */

class AbstractFeatureStyler {
  #_renderer = null;
  #_limits = null;
  #_colorKey = null;

  shouldNotCall() {
    throw new Error ("Call of deprecated method")
  }

  /**
   * Descendants override this to apply their specific style to aFeature.
   *
   * It is an error for a descendant to not override this method.
   */
  doStyleFeature_(aFeature) {
    throw new Error("A subclass should have implemented this message");
  }

  doLegendViewName() {
    throw new Error("A subclass should have implemented this message");
  }

  doScaleOffsetForFeature_(aFeature) {
    throw new Error("A subclass should have implemented this message");
  }

  doIsFeatureSaturated_(aFeature) {
    throw new Error("A subclass should have implemented this message");
  }

  /**
   * Each descendant overrides me to provide a suitable colorKey.
   *
   * It is an error for a descendant to not override this method.
   */
  doColorKeyPreset() {
    throw new Error("A subclass should have implemented this message");
  }

  /**
   * Each descendant overrides me to interpolate a color based on aScaleOffset.
   *
   * It is an error for a descendant to not override this method.
   */
  doInterpolatedColorForScaleOffset_(aScaleOffset) {
    throw new Error("A subclass should have implemented this message");
  }

  /**
    * Called from the data layer of a GoogleMap each time a feature changes.
    *
    * This is the callback passed to data.setStyle() and is called for each
    * feature in map.data. Check out the docs for Data.StylingFunction.
    *
   **/
  styleFeature_(aFeature) {
    return this.doStyleFeature_(aFeature);
  }

  /**
  * This is a STUPID hack because I can't find a better way to make this code work.
  *
  * Answer a string that my caller uses to determine which LegendView to build.
  */
  legendViewName() {
    return this.doLegendViewName()
  }

  htmlForValue_(aDataValue) {
    return this._renderer.htmlForValue_(aDataValue);
  }

  // Begin accessor methods

  // These low-level methods get and set the value of limits

  limitsBasic() {return this._limits}
  limitsBasic_(aLimits) {this._limits = aLimits;}

  // These higher-level methods may do other things when changing limits.
  limits() {
    return this.limitsBasic()
  }
  limits_(aLimits) {this.limitsBasic_(aLimits);}

  lowerLimit() {
    return this._limits.lowerLimit()
  };

  upperLimit() {
    return this._limits.upperLimit()
  };
  metaDatapointID() {return this._limits.metaDatapointID()};
  propertyName() {return this._limits.propertyName()}
  maximumValue() {return this._limits.maximumValue()};
  minimumValue() {return this._limits.minimumValue()};
  lowerLimitCentile() {return this._limits.lowerLimitCentile()};
  lowerLimitCentile_(anInteger) {this._limits.lowerLimitCentile_(anInteger)};
  upperLimitCentile() {return this._limits.upperLimitCentile()};
  upperLimitCentile_(anInteger) {this._limits.upperLimitCentile_(anInteger)};

  range() {return this._limits.range()}
  range_(aRange) {return this._limits.range_(aRange)}

  renderer() {return this._renderer}
  renderer_(anAbstractValueRenderer) {
    if (anAbstractValueRenderer !== null) {
      anAbstractValueRenderer.styler_(this)
    }
    this._renderer = anAbstractValueRenderer
  }

  colorKey() {return this._colorKey}
  colorKey_(aColorKey) {this._colorKey = aColorKey}
  colorKeyPreset() {return this.doColorKeyPreset()}

  // End accessor methods

  scaleOffsetForFeature_(aFeature) {
    return this.scaleOffsetForFeature_isDoubleEnded_(aFeature, false);
  }

  doNothing(){}

  scaleOffsetForFeature_isDoubleEnded_(aFeature, anIsDoubleEnded) {
    const covidVariable = aFeature.getProperty('covid_variable');
    if (covidVariable === undefined) {return null}
    // const isBarnstable = aFeature.getProperty('GEO_ID') === "0500000US25001";
    // if (isBarnstable) {
    //   if (this.lowerLimit() >= 14000) {
    //     this.doNothing()
    //   }
    // }
    return this._renderer.scaleOffsetForValue_isDoubleEnded_(covidVariable, anIsDoubleEnded);
  }

  isFeatureSaturated_(aFeature) {
    const covidVariable = aFeature.getProperty('covid_variable');
    if (covidVariable === undefined) {return false}
    return this._renderer.isSaturatedValue_(covidVariable)
  }

  isFeatureMaxSaturated_(aFeature) {
    const covidVariable = aFeature.getProperty('covid_variable');
    if (covidVariable === undefined) {return false}
    return this._renderer.isSaturatedMaxValue_(covidVariable)
  }

  isFeatureMinSaturated_(aFeature) {
    const covidVariable = aFeature.getProperty('covid_variable');
    if (covidVariable === undefined) {return false}
    return this._renderer.isSaturatedMinValue_(covidVariable)
  }

  /**
   * Answers a string to display for the minimum scale value.
   */
  minimumScaleString() {
    return this._renderer.minimumScaleString()
  }

  /**
   * Answers a string to display for the maximum scale value.
   */
  maximumScaleString() {
    return this._renderer.maximumScaleString()
  }

  // Begin methods for styling
  colorKeyStrings() {
    const answer = this._colorKey.map((aColorTuple) => {return `hsl(${aColorTuple[0]}, ${aColorTuple[1]}%, ${aColorTuple[2]}%)`});
    return answer
  }

  colorKeyBackgroundString() {
    // linear-gradient(to right, hsl(76, 99%, 39%) 0%, hsl(51, 100%, 50%) 25%, hsl(18, 88%, 51%) 50%, hsl(7, 88%, 46%) 75%, hsl(311, 100%, 47%) 100%)
    const parameterItems = this.colorKeyStrings();
    const parameterParts = parameterItems.map((aColorKeyString, anIndex, aColorKeyStrings) => {
      // hsl(76, 99%, 39%) 0%
      return `${aColorKeyString} ${Math.trunc(100*anIndex/(aColorKeyStrings.length-1))}%`;
    });
    parameterParts.unshift('to right');
    const parameterString = parameterParts.join(', ');
    const colorKeyBackgroundString = `linear-gradient(${parameterString})`
    return colorKeyBackgroundString;
  };

  saturatedMinColorKeyString() {
    const colorKeyStrings = this.colorKeyStrings();
    const saturatedMinColorKeyString = colorKeyStrings[0];
    return saturatedMinColorKeyString
  }

  saturatedMaxColorKeyString() {
    const colorKeyStrings = this.colorKeyStrings();
    const saturatedMaxColorKeyString = colorKeyStrings[colorKeyStrings.length-1];
    return saturatedMaxColorKeyString
  }

  // End methods for styling

  // Methods for manipulating colors

  rgbColorForScaleOffset_startColor_stopColor(aScaleOffset, aStartColor, aStopColor) {
    const [startRed, startGreen, startBlue] = aStartColor;
    const [stopRed, stopGreen, stopBlue] = aStopColor;
    const red = (stopRed - startRed)*aScaleOffset + startRed;
    const green = (stopGreen - startGreen)*aScaleOffset + startGreen;
    const blue = (stopBlue -startBlue)*aScaleOffset + startBlue;
    const rgbColor = [red, green, blue];
    return rgbColor;
  }
  /**
   * Answers the current colorKey as a list of RGB, rather than HSL, colors.
   *
   * The elements of each hslKey are answered as 
   */
  rgbColorKey() {
    const rgbColorKey = [];
    const hslColorKey = this.colorKey();
    for (const hslKey of hslColorKey) {
      const [hue, saturation, lightness] = hslKey;
      const rgbKey = this.hslToRgb(hue, saturation, lightness);
      rgbColorKey.push(rgbKey);
    }
    return rgbColorKey;
  }

  /**
   * hue is an integer between 0 and 360 -- it is the angle in degrees of the
   * color on the color wheel
   *
   * saturationInteger and lightnessInteger are each integers between 0 and 100
   * that correspond to percentages.
   */
  hslToRgb(hue, saturationInteger, lightnessInteger) {
    const saturation = saturationInteger/100;
    const lightness = lightnessInteger/100;
    const chroma = (1 - Math.abs(2 * lightness - 1)) * saturation;
    const hueSector = hue / 60.0;
    const x = chroma * (1 - Math.abs((hueSector % 2) - 1));
    let rgb1;
    if (isNaN(hue)) rgb1 = [0, 0, 0];
    else if (hueSector <= 1) rgb1 = [chroma, x, 0];
    else if (hueSector <= 2) rgb1 = [x, chroma, 0];
    else if (hueSector <= 3) rgb1 = [0, chroma, x];
    else if (hueSector <= 4) rgb1 = [0, x, chroma];
    else if (hueSector <= 5) rgb1 = [x, 0, chroma];
    else if (hueSector <= 6) rgb1 = [chroma, 0, x];
    let m = lightness - chroma * 0.5;
    return [
      Math.round(255 * (rgb1[0] + m)),
      Math.round(255 * (rgb1[1] + m)),
      Math.round(255 * (rgb1[2] + m))
    ];
  }

  /**
   * Converts saturation and lightness to percentages in the return value.
   */
  rgbToHsl(r, g, b) {
    r /= 255; g /= 255; b /= 255;
    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);
    let d = max - min;
    let h;
    if (d === 0) h = 0;
    else if (max === r) h = ((((g - b) / d) % 6)+6)%6;
    else if (max === g) h = (b - r) / d + 2;
    else if (max === b) h = (r - g) / d + 4;
    let l = (min + max) / 2;
    let s = d === 0 ? 0 : d / (1 - Math.abs(2 * l - 1));
    return [h * 60, s*100, l*100];
  }

  /**
   * Calculates and returns a color that matches the linear shading of the
   * scale.
   *
   * This is done by collecting a color key expressed in RGB instead of hsl.
   * The start and stop values are determined from aScaleOffset, and an RGB
   * value is interpolated from the start and stop using aScaleOffset. That
   * rgb value is then converted back to HSL and returned.
   *
   *
   */
  interpolatedColorForScaleOffset_(aScaleOffset) {
    return this.doInterpolatedColorForScaleOffset_(aScaleOffset)
  }
}

export default AbstractFeatureStyler;
