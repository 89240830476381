/**
 * ServerEnvironment.js
 * @copyright: 2022 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * Collects and answers values from the current environment, using axios.
 *
 * MapPanel invokes me in its useEffect handler.
**/
import axios from 'axios';

/**
 * Uses axios to get the current environment from my services microservice.
 *
 * Caller passes a setter (often created by useState) that I use to answer the
 * response.
 */
const GET_ENVIRONMENT_SELECTOR = 'getEnvironment';
const URL = `${process.env.REACT_APP_COVID_URL}:${process.env.REACT_APP_SERVICES_PORT}/${GET_ENVIRONMENT_SELECTOR}`;

const loadServerEnvironment = ({setter, environmentVariableName}) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const getServerEnvironment = async () => {
    try {
      let res = await axios.get(`${URL}?name=${environmentVariableName}`, { cancelToken: source.token});
      //let newServerEnvironment = res.data;
      //setter(newServerEnvironment);
      const environmentVariableValue = res.data;
      setter(environmentVariableValue);
    } catch (anError) {
      if (axios.isCancel(anError)) {
        console.log("cancelled");
      } else {
        throw anError;
      }
    }
  };

  getServerEnvironment();
  return () => {
    source.cancel();
  };
};

export const loadGooglePlacesKey = ({setter}) => {
  if (setter !== null) {
      // console.log(`ServerEnvironment.loadGooglePlacesKey()`)
      loadServerEnvironment({setter: setter, environmentVariableName: 'googlePlacesKey'});
      // console.log(`ServerEnvironment.loadGooglePlacesKey(): Done`)
  }
}

export const loadGoogleMapKey = ({setter}) => {
  if (setter !== null) {
      // console.log(`ServerEnvironment.loadGoogleMapKey()`)
      loadServerEnvironment({setter: setter, environmentVariableName: 'googleMapKey'});
      // console.log(`ServerEnvironment.loadGoogleMapKey(): Done`)
  }
}

export const loadRecaptchaSiteKey = ({setter}) => {
  if (setter !== null) {
    loadServerEnvironment({setter: setter, environmentVariableName: 'recaptchaSiteKey'});
  }
}

export default loadServerEnvironment;
