/**
 * DataGrainControl.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * I provide the dashboard that controls a map scene.
 *
 * I am usually the single child of the `Collapse` element of a
 * `ScreenController` that is in turn a sibling of a `Navigator` in an
 *  `Omnibox`.
 * I appear below (in the y-direction) the `Navigator` and screen controller
 * preview buttons. Each of those is 345x48 (as of 31-Mar-2021).
 *
 * I therefore position a 345x48 spacer box/paper/div at top of my root
 * container in order to position my controllers so that they are visible while
 * I am expanded.
 *
 * From static site:
 *  .nicebox (container)
 *    font-family: "Roboto", "Arial", "sans-serif";
 *    font-size: 13px
 *
 *  input element:
 *    
 */

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  } from '@material-ui/core';

const FONT_SIZE=13;

const useStyles = makeStyles((aTheme) => ({
  root: {
    width: 19,
    height: 19,
    marginLeft: 16,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: FONT_SIZE,
  },
  formControlLabel: {
    paddingLeft: 8,
    fontSize: FONT_SIZE,
  },
}));

/**
 * Only show Edge Intensity if my dataSource is "Cases" or "Deaths"
 */
export default function DataGrainControl({ dataSource, dataGrain, setDataGrain, analyzerName, setAnalyzerName, ...props}) {
  const classes = useStyles();
  const [isEdgeIntensity, setIsEdgeIntensity] = useState("EdgeIntensity" === analyzerName);

  const handleDataGrainChange = (anEvent) => {
    anEvent.preventDefault();
    setDataGrain(anEvent.target.value);
  }

  const isShowingEdgeIntensity = () => {
    const answer = (("CaseCount" === dataSource) || ("DeathCount" === dataSource));
    return answer;
  }

  /**
   * Clear isEdgeIntensity if my analyzerName is changed
   *
   * This happens during a `handleCancel` invocation.
   */
  useEffect(
    () => {
      setIsEdgeIntensity("EdgeIntensity" === analyzerName);
      },
    [analyzerName]
  );

  const handleIsEdgeIntensityChange = (anEvent) => {
    anEvent.preventDefault();
    setIsEdgeIntensity(!isEdgeIntensity);
    const newAnalyzerName = (!isEdgeIntensity) ? 'EdgeIntensity' : 'None';
    setAnalyzerName(newAnalyzerName);
  }

  const TinyRadio = <Radio size="small" classes={{root: classes.root}} />;
  const TinyCheckbox = <Checkbox checked={isEdgeIntensity} size="small" classes={{root: classes.root}} />;

  return (
    <FormControl component="fieldset">
      <RadioGroup
        value={dataGrain}
        onChange={handleDataGrainChange}
      >
        <FormControlLabel
          classes={{label: classes.formControlLabel}}
          value="cumulative"
          control={TinyRadio} 
          label="Cumulative"
        />
        <FormControlLabel
          classes={{label: classes.formControlLabel}}
          value="daily"
          control={TinyRadio} 
          label="Daily"
        />
      </RadioGroup>
      <Box visibility={ isShowingEdgeIntensity() ? "visible" : "hidden"} >
        <FormControlLabel
          id="isEdgeIntensityID"
          classes={{label: classes.formControlLabel}}
          control={TinyCheckbox} 
          label="Edge Intensity"
          onClick={handleIsEdgeIntensityChange}
        />
      </Box>
    </FormControl>
  );
};
