/**
 * BooleanLegendView.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * I render a boolean value and so my scaleLegendID box should be two
 * rectangles, one off and one on. I don't see how to do this with just a css
 * change. I have no linear/log switch, and I don't have a min or max value in
 * my scale.
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  } from '@material-ui/core';

const useStyles = makeStyles((aTheme) => ({
  root: {
    display: 'inline',
    marginLeft: "10px",
  },
  scaleLegendLabel: {
    width: '214px',
    display: 'inline-block',
    textAlign: 'center',
    marginLeft: '10px',
  },
  scaleLegendLabelLeft: {
    width: '25px',
    display: 'inline-block',
  },
  scaleLegendLabelRight: {
    width: '25px',
    display: 'inline-block',
  },
  scaleLegendDiv: {
    lineHeight: '1.0em',
  },
  noDisplayControl: {
    height: '8px',
  },
  spacerCell: {
    width: '25px',
    display: 'inline-block',
  }
}));

export default function BooleanLegendView({ dataLoaderRef, children, ...props }) {
  const classes = useStyles();
  const getLegendString = () => {
    const mapControls = dataLoaderRef.current._mapControls;
    /*
    'pertainsDate':             defaultPertainsDate,
    'dataSource':               'CaseCount',      // 'CaseCount', "DeathCount", "HotSpots'"
    'dataGrain':                'daily',          // 'daily'|'cumulative'
    'dataAdjustment':           'smoothing',           // 'None'|'smoothing'|'anomalies
    'isByPopulation':           true,
    'isByArea':                 false,
    'analyzerName':             'None',           // 'EdgeIntensity'
     */
    let dataSourceString = '';
    switch (mapControls.dataSource) {
      case 'HotSpots':
        dataSourceString = 'hot spot warnings';
        break;
      default:
        break;
    }

    const legendString = `${dataSourceString}`;
    return legendString
  }

  return (
    <table id="booleanLegendTableID">
      <tbody id="booleanLegendTableBodyID">
        <tr id="booleanLegendLabelID">
          <td
          >
            <Box
              id="spacerCell0ID"
              className={classes.spacerCell}
              fontWeight="fontWeightMedium"
              fontSize={16}
            >
              &nbsp;
            </Box>
          </td>
          <td
          >
            <Box
              id="scaleLegendLabelLeftID"
              className={classes.scaleLegendLabelLeft}
              fontWeight="fontWeightMedium"
              fontSize={12}
            >
              False
            </Box>
          </td>
          <td
          >
            <Box
              id="scaleLegendLabelID"
              className={classes.scaleLegendLabel}
              fontWeight="fontWeightMedium"
              fontSize={12}
            >
              {getLegendString()}
            </Box>
          </td>
          <td
          >
            <Box
              id="scaleLegendLabelRightID"
              className={classes.scaleLegendLabelRight}
              fontWeight="fontWeightMedium"
              fontSize={12}
            >
            True
          </Box>
          </td>
          <td
          >
            <Box
              id="spacerCell4ID"
              className={classes.spacerCell}
              fontWeight="fontWeightMedium"
              fontSize={16}
            >
              &nbsp;
            </Box>
          </td>
        </tr>
        {children}
        <tr id="noDisplayScaleControlRowID">
          <td colSpan="5">
            <Box
              className={classes.noDisplayControl}
            >
              &nbsp;
            </Box>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
