/**
  * LinearRenderer.js
  * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
  * All rights reserved.
  * 
  * The contents of this file may not be copied, duplicated, or used without the
  * written consent of Zeetix, LLC.
  * 
  */

import AbstractValueRenderer from './AbstractValueRenderer';

class LinearRenderer extends AbstractValueRenderer{

  doNothing() {};

  doRenderedValueFor_(aCovidValue) {
    const limitedMinimum = Math.max(aCovidValue, this.lowerLimit())
    const valueToRender = Math.min(limitedMinimum, this.upperLimit());
    return valueToRender;
  };

  /**
   * Answers the relative magnitude of aCovidValue in the current scale.
   *
   * This is number between 0.0 and 1.0. I use linear values.
   *
   */
  doScaleOffsetForValue_isDoubleEnded_(aCovidValue, anIsDoubleEnded) {
    if ((aCovidValue === null)||(aCovidValue === undefined)) {
      return null;
    }
    const valueToRender = this.renderedValueFor_(aCovidValue);
    let scaleOffset = null;

    if (!isNaN(valueToRender)) {
      if (anIsDoubleEnded) {
        scaleOffset = (valueToRender + this.upperLimit())/(2*this.upperLimit());
      } else {
        if (this.minimumValue() !== 0) {
          console.log(`LinearRenderer.doScaleOffsetForValue_isDoubleEnded_: non-zero minimumValue (${this.minimumValue})`)
        }
        // scaleOffset = (valueToRender - this.minimumValue()) / (this.upperLimit() - this.minimumValue())
        scaleOffset = (valueToRender - this.lowerLimit()) / (this.upperLimit() - this.lowerLimit())
      }
    }
    return scaleOffset;
  }

  doIsSaturatedValue_(aCovidValue) {
    const renderedValue = Math.abs(this.renderedValueFor_(aCovidValue));
    const isSaturatedValue = (renderedValue >= this.upperLimit());
    return isSaturatedValue
  }

  doIsSaturatedMaxValue_(aCovidValue) {
    const renderedValue = this.renderedValueFor_(aCovidValue);
    const isSaturatedValue = (renderedValue >= this.upperLimit());
    return isSaturatedValue
  }

  doIsSaturatedMinValue_(aCovidValue) {
    const renderedValue = this.renderedValueFor_(aCovidValue);
    const isSaturatedValue = (renderedValue <= this.lowerLimit());
    return isSaturatedValue
  }

  doHTMLForValue_(aCovidValue) {
    // console.log(`LinearRenderer.doCovidVariableHTML_(${aCovidValue})`);
    // console.log(`LogLinearRendererRenderer.doCovidVariableHTML_: returns "${aCovidValue.toLocaleString()}"`);
    return aCovidValue.toLocaleString();
  };

  doDisplayScale() {
    // console.log(`LinearRenderer.doDisplayScale(): returns "Linear"`);
    return "Linear"
  }

  doMinimumScaleString()  {
      // console.log(`LinearRenderer.doMinimumScaleString()`);
      const minimumScale = this.lowerLimit();
      const minimumScaleString = this.formatAsString_(minimumScale);
      // console.log(`LinearRenderer.doMinimumScaleString: returning ${minimumScaleString}`);
      return minimumScaleString;
  }

  doMaximumScaleString()  {
      // console.log(`LinearRenderer.doMaximumScaleString()`);
      const maximumScale = this.upperLimit();
      const maximumScaleString = this.formatAsString_(maximumScale);
      // console.log(`LinearRenderer.doMaximumScaleString: returning ${maximumScaleString}`);
      return maximumScaleString;
  }

}

export default LinearRenderer;
