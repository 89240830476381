/**
 * AbstractDataLoader.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * Each descendant must create a suitable LegendView and pass its reference in
 * the "current" property of my legendViewRef.
 */
import databaseClient from './DatabaseClient';

class AbstractDataLoader {
  #_mapControls = null;
  #_featureStyler = null;
  #_directorContext = null;

  constructor({mapControls, featureStyler, directorContext}) {
    this._mapControls = mapControls;
    this._featureStyler = featureStyler;
    this._directorContext = directorContext;
  };

  doLoadData() {
    throw new Error("A subclass should have implemented this message");
  }

  doConfigureLoadedFeature_covidVariable_(aFeature, aCovidVariable) {
    throw new Error("A subclass should have implemented this message");
  }

  doHandleLoadedData_(aLoadedData) {
    throw new Error("A subclass should have implemented this message");
  }

  // doHandleLoadedMetadata_(aLoadedMetadata) {
  //   const limits = Limits.createFromMetadata_directorContext_(aLoadedMetadata, this._directorContext);
  //   this.limits_(limits)
  // }

  handleOnCompletion() {
    this._directorContext.onCompletion();
  }

  handleLoadedData_(aLoadedData) {
    this.doHandleLoadedData_(aLoadedData);
  }

  // handleLoadedMetadata_(aLoadedMetadata) {
  //   this.doHandleLoadedMetadata_(aLoadedMetadata);
  // }

  /**
   * My descendant loads the data and calls an appropriate handler on aMapView,
   * based on the specific descendant. I call anErrorCallback if anything goes
   * wrong.
   */
  loadDataOnError_(anErrorCallback) {
    if (!(this._directorContext.areFeaturesLoadedRef.current)) {
      // Do nothing until features are loaded
      return;
    }
    // console.log('AbstractDataLoader.loadDataOnError_(): Calling doLoadData');
    this.doLoadData()
      .then((aDatabaseResponse) => {
          // console.log('AbstractDataLoader.loadDataOnError_: handling returned data');
          this.handleLoadedData_(aDatabaseResponse)
          // console.log('AbstractDataLoader.loadDataOnError_: finished handling returned data');
          })
      .then(() => {
        // console.log('AbstractDataLoader.loadDataOnError_: notifing directorContext of completion');
        this.handleOnCompletion();
        // console.log('AbstractDataLoader.loadDataOnError_: finished');
        })
      .catch (anError => {
        console.log('AbstractDataLoader.loadDataForViewOnError_: caught: ', anError);
        anErrorCallback(anError)
      });
  }

  /**
   * My descendant sets properties on aFeature as appropriate.
   */
  configureLoadedFeature_covidVariable_(aFeature, aCovidVariable) {
    this.doConfigureLoadedFeature_covidVariable_(aFeature, aCovidVariable);
  }

  // Methods delegated to my databaseClient.

  loadBooleanData() {
    const {pertainsDate, dataSource} = this._mapControls;
    return databaseClient.loadBooleanDataForDate_source_(pertainsDate, dataSource);
  }

  loadAnalysisResults() {
    const {pertainsDate, dataSource, dataGrain, analyzerName} = this._mapControls;
    return databaseClient.loadAnalysisResultsForDate_source_grain_analyzerName_(pertainsDate, dataSource, dataGrain, analyzerName)
  }

  loadNumericData() {
    const {pertainsDate, dataSource, dataGrain, isByPopulation, isByArea, dataAdjustment} = this._mapControls;
    return databaseClient.loadDataForDate_source_grain_isByPopulation_isByArea_adjustment_(pertainsDate, dataSource, dataGrain, isByPopulation, isByArea, dataAdjustment)
  }

  loadMetadata() {
    return databaseClient.loadMetadata()
  }

  // Accessors
  isDataLoaded() {return this._directorContext.getIsDataLoaded()}

  metaDatapointID() {return this._featureStyler.metaDatapointID()};
  propertyName() {return this._featureStyler.propertyName()}
  lowerLimit() {return this._featureStyler.lowerLimit()};
  upperLimit() {return this._featureStyler.upperLimit()};
  minimumValue() {return this._featureStyler.minimumValue()};
  maximumValue() {return this._featureStyler.maximumValue()};

  limits() {return this._featureStyler.limits()}
  limits_(aLimits) {this._featureStyler.limits_(aLimits)}
  lowerLimitCentile() {return this._featureStyler.lowerLimitCentile()};
  lowerLimitCentile_(anInteger) {this._featureStyler.lowerLimitCentile_(anInteger)};
  upperLimitCentile() {return this._featureStyler.upperLimitCentile()};
  upperLimitCentile_(anInteger) {this._featureStyler.upperLimitCentile_(anInteger)};

  range() {return this._featureStyler.range()}
  range_(aRange) {return this._featureStyler.range_(aRange)}

  featureStyler() {return this._featureStyler}
  featureStyler_(aStyler) {this._featureStyler = aStyler}

  doNothing() {}

  styleFeature_(aFeature) {
    return this._featureStyler.styleFeature_(aFeature);
  }

  /**
  * This is a STUPID hack because I can't find a better way to make this code work.
  *
  * Answer a string that my caller uses to determine which LegendView to build.
  */
  legendViewName() {
    return this._featureStyler.legendViewName()
  }

  scaleOffsetForFeature_(aFeature) {
    return this._featureStyler.scaleOffsetForFeature_(aFeature);
  }

  isFeatureSaturated_(aFeature) {
    return this._featureStyler.isFeatureSaturated_(aFeature);
  }

  isFeatureMaxSaturated_(aFeature) {
    return this._featureStyler.isFeatureMaxSaturated_(aFeature);
  }

  isFeatureMinSaturated_(aFeature) {
    return this._featureStyler.isFeatureMinSaturated_(aFeature);
  }

  htmlForValue_(aDataValue) {
    return this._featureStyler.htmlForValue_(aDataValue);
  }

  minimumScaleString() {
    return this._featureStyler.minimumScaleString()
  }

  maximumScaleString() {
    return this._featureStyler.maximumScaleString()
  }

  colorKey() {return this._featureStyler.colorKey()}
  colorKey_(aColorKey) {this._featureStyler.colorKey_(aColorKey)}
  colorKeyBackgroundString() {return this._featureStyler.colorKeyBackgroundString()}
  saturatedMinColorKeyString() {return this._featureStyler.saturatedMinColorKeyString()}
  saturatedMaxColorKeyString() {return this._featureStyler.saturatedMaxColorKeyString()}

}

export default AbstractDataLoader;
