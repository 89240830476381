/**
 * BooleanScale.js
 * @copyright: 2022 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * Use the caretPosition to offset my caret to the correct location in my
 * display scale.
 *
 * I render a boolean value and so my scaleLegendID box should be two
 * rectangles, one off and one on. I don't see how to do this with just a css
 * change. I have no linear/log switch, and I don't have a min or max value in
 * my scale.
 *
 * My color key is a bar with two colors. I have just two caret positions, 25%
 * for false (in the green key) and 75% for true.
 *
 */
import React, { useEffect, useState  } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  } from '@material-ui/core';

const useStyles = makeStyles((aTheme) => ({
  scaleLegendDiv: {
  },
  falseKey: {
    background: 'hsl(76, 99%, 39%)',
    height: '20px',
    width: '148px',
    display: 'inline-block',
  },
  trueKey: {
    background: 'hsl(311, 100%, 47%)',
    height: '20px',
    width: '148px',
    display: 'inline-block'
  },
  falseValue: {
    background: 'hsl(76, 99%, 39%)',
    height: '5px',
    width: '74px',
    marginLeft: '37px',
  },
  trueValue: {
    background: 'hsl(311, 100%, 47%)',
    height: '5px',
    width: '74px',
    marginLeft: '185px',
  },
  undefinedValue: {
    background: 'white',
    height: '5px',
  },
  falseScale: {

  },
  trueScale: {

  },
}));

function UndefinedScale() {
  const classes = useStyles();
  return (
    <Box id="undefinedScaleID">
      <Box id="scaleLegendID"> 
        <Box id="falseBoxID" className={classes.falseKey} />
        <Box id="trueBoxID" className={classes.trueKey} />
      </Box>
      <Box id="undefinedValueID" className={classes.undefinedValue} />
    </Box>
  );
}

function FalseScale() {
  const classes = useStyles();
  return (
    <Box id="falseScaleID" className = {classes.falseScale} >
      <Box id="scaleLegendID">
        <Box id="falseBoxID" className={classes.falseKey} />
        <Box id="trueBoxID" className={classes.trueKey} />
      </Box>
      <Box id="falseValueID" className={classes.falseValue} />
    </Box>
  );
}

function TrueScale() {
  const classes = useStyles();
  return (
    <Box id="trueScaleID" className = {classes.trueScale} >
      <Box id="scaleLegendID"> 
        <Box id="falseBoxID" className={classes.falseKey} />
        <Box id="trueBoxID" className={classes.trueKey} />
      </Box>
      <Box id="trueValueID" className={classes.trueValue} />
      </Box>
  );
}

export default function BooleanScale({ highlightedFeature, dataLoaderRef, ...props }) {
  const classes = useStyles();
  const [scaleOffset, setScaleOffset] = useState(null);

  useEffect(
    () => {
      if (highlightedFeature !== null) {
        const newScaleOffset = dataLoaderRef.current.scaleOffsetForFeature_(highlightedFeature);
        setScaleOffset(newScaleOffset)
      } else {
        setScaleOffset(null);
      }
    },
    [highlightedFeature, dataLoaderRef]
  );

  if (scaleOffset === null) {
    return (
      <tr>
        <td></td>
        <td colSpan="3">
          <div id="scaleLegendID"
            className={classes.scaleLegendDiv}
          >
            <UndefinedScale />
          </div>
        </td>
        <td></td>
      </tr>
    )
  }
  if (scaleOffset) {
    return (
      <tr>
        <td></td>
        <td colSpan="3">
          <div id="scaleLegendID"
            className={classes.scaleLegendDiv}
          >
            <TrueScale />
          </div>
        </td>
        <td></td>
      </tr>
    )
  } else {
    return (
      <tr>
        <td></td>
        <td colSpan="3">
          <div id="scaleLegendID"
            className={classes.scaleLegendDiv}
          >
            <FalseScale />
          </div>
        </td>
        <td></td>
      </tr>
    )
  }
}
