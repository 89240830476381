/**
 * DataMarker.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 * I am the window that appears on the map in response to a mouse-in event.
 **/

import {
  Marker,
  } from "@react-google-maps/api";

const DataMarker = ( {onMarkerLoad, markerPosition, setMarkerPosition, markerIcon, onClickInMarker, isDataMarkerVisible, ...props } ) => {
  // console.log('DataWindow.render()');
  return (
    <Marker
      onLoad={onMarkerLoad}
      position={markerPosition}
      setPosition={setMarkerPosition}
      icon={markerIcon}
      onClick={onClickInMarker}
      visible={isDataMarkerVisible}
    />
  );
};

export default DataMarker;
