/**
 * MetadataLoader.js
 * @copyright: 2022 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * I load and answer metadata from the current database
 */
import AbstractDataLoader from './AbstractDataLoader';

class MetadataLoader extends AbstractDataLoader {

  doNothing() {}

  doConfigureLoadedFeature_covidVariable_(aFeature, aCovidVariable) {
    this.doNothing()
  }

  /**
   * I use my directorContext to delegate this to my creator.
   */
  doHandleLoadedData_(aLoadedData) {
    this._directorContext.onDataLoaded(aLoadedData);
  }

  doLoadData() {
    return this.loadMetadata();
  };

  loadDataOnError_(anErrorCallback) {
    // console.log('MetadataLoader.loadDataOnError_(): Calling doLoadData');
    this.doLoadData()
      .then((aDatabaseResponse) => {
          // console.log('MetadataLoader.loadDataOnError_: handling returned data');
          this.handleLoadedData_(aDatabaseResponse)
          // console.log('MetadataLoader.loadDataOnError_: finished handling returned data');
          })
      .then(() => {
        // console.log('MetadataLoader.loadDataOnError_: notifing directorContext of completion');
        this.handleOnCompletion();
        // console.log('MetadataLoader.loadDataOnError_: finished');
        })
      .catch (anError => {
        console.log('MetadataLoader.loadDataForViewOnError_: caught: ', anError);
        anErrorCallback(anError)
      });
  }

}

export default MetadataLoader;
