/**
 * SceneView.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 * SceneView connects my Scene to my MapScene.
 *
 * MapScene contains the Google Map and its controls. I may someday have other children
 * besides MapScene.
 */
import React from 'react';
import { 
  Box,
  } from '@material-ui/core';
import MapScene from './map_scene/MapScene';

const SceneView = ({mapControls, mapSettings, setMapSettings, googleMapKey, environmentRef, environment, metadata, ...props}) => {
  return (
    <Box
      width="100%"
      height="100%"
      position="absolute"
    >
      <MapScene
        mapControls={mapControls}
        mapSettings={mapSettings}
        setMapSettings={setMapSettings}
        googleMapKey={googleMapKey}
        environmentRef={environmentRef}
        environment={environment}
        metadata={metadata}
      />
    </Box>
  );
}

export default SceneView;
