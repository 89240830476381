const vaxUnknownCountyShareByState = {
  '18': 1.4,
  '24': 1.4,
  '12': 1.4,
  '09': 1.6,
  '28': 1.7,
  '47': 2.2,
  '16': 2.3,
  '41': 2.3,
  '17': 2.3,
  '39': 2.4,
  '19': 2.5,
  '04': 2.5,
  '36': 2.6,
  '06': 2.8,
  '55': 2.9,
  '49': 3,
  '02': 3.1,
  '23': 3.7,
  '37': 3.7,
  '53': 3.8,
  '56': 3.9,
  '10': 3.9,
  '22': 4.1,
  '30': 4.5,
  '44': 4.7,
  '38': 4.8,
  '27': 5,
  '32': 5.1,
  '11': 5.2,
  '26': 5.9,
  '33': 6.1,
  '45': 6.4,
  '42': 6.4,
  '40': 6.5,
  '21': 6.6,
  '20': 6.8,
  '34': 7.1,
  '01': 8.2,
  '29': 9.3,
  '25': 10.7,
  '05': 10.9,
  '31': 11.3,
  '46': 16.8,
  '35': 17.7,
  '08': 23.9,
  '50': 26.2,
  '54': 42.4,
  '13': 46.4,
  '51': 48.8,
}

export { vaxUnknownCountyShareByState };
