/**
 * HelpPane.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 */
import React from 'react';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import {
  Body2,
  H5,
  Subtitle2,
  } from './Markup'

export default function HelpPane() {
  return (
    <>
      <Body2>
        The map displayed in a COVID data browser is analogous to a weather
        map, showing the intensity and location of "storms" of COVID
        activity.  We default to daily data, but there are counties that do
        not report each and every day.
      </Body2>
      <Subtitle2>Exceptions to daily reporting</Subtitle2>
      <Body2>
        While most counties report cases and deaths every day of the week, there are 524 counties in 7 states that do not report every day. In summary:
        <Divider style={{marginTop: '15px'}}/>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell><strong>Connecticut</strong></TableCell><TableCell>Connecticut does not report data for Friday or Saturday and thus Saturday and Sunday maps will show zero cases. Connecticut is best viewed using the smoothing option.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Louisiana</strong></TableCell><TableCell>This state does not report data for Friday.  Thus, Saturday maps will show zero cases. It is best viewed using the smoothing option.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Oklahoma</strong></TableCell><TableCell>This state reports only weekly on Wednesday.  It is best viewed using the smoothing option.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Rhode Island</strong></TableCell><TableCell>Rhode Island reports data only on Wednesday.  Rhode Island is best viewed using smoothing option.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Michigan</strong></TableCell><TableCell>Michigan reports on only seven counties on Sundays.  The rest of the week the state reports on all 83 counties.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Texas</strong></TableCell><TableCell>This state reports on most of the 254 counties Monday through Friday. Data is reported for only 162 counties on Saturday and Sunday.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Kansas</strong></TableCell><TableCell>Kansas reports on all 105 counties on Sunday, Tuesday and Thursday.  Fifty four counties are reported on Mondays, 24 on Wednesday, 32 on Friday and 19 on Saturday.</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Body2>
      <Body2>
        A detailed listing is at the bottom of this page.
      </Body2>
      <H5>Cumulative case count</H5>
      <Body2>
        This is the total confirmed case count by county for the currently selected "pertainsDate".
      </Body2>
      <H5>Cumulative death count</H5>
      <Body2>
        This is the total death count of patients with confirmed cases for the currently selected "pertainsDate".  Again the data is by county.
      </Body2>
      <H5>Daily Case Count</H5>
      <Body2>
        This is new confirmed case count. Change the "pertainsDate" to see the case count for other days.
      </Body2>
      <H5>Daily Death Count</H5>
      <Body2>
        This is the count of deaths reported for the particular date.  Change the date to see deaths for prior days.
      </Body2>
      <H5>Hotspot Warning</H5>
      <Body2>
        These are counties that have a high (95%) likelihood of experiencing a
        significant increase in the daily case count in the next four days. It is
        comparable to a storm warning issued by the National Weather Service.
      </Body2>

      <H5>Data normalization</H5>
      <Subtitle2>Per 100K population</Subtitle2>
      <Body2>
        The selected data of a given feature is normalized by the population of that feature, based
        on US Census Bureau data. The result is adjusted to provide the number of cases per 100K population.
      </Body2>
      <Body2>
        This shows the effect of population density on the selected data and allows the selected data to be
        meaningfully compared between features of high and low population.
      </Body2>
      <Subtitle2>Per 1K square miles</Subtitle2>
      <Body2>
        The selected data of a given feature is normalized by the land area, in square miles, of that
        feature, based on US Census Bureau data. The result is adjusted to provide the number of cases
        per 1K square miles.
      </Body2>
      <Body2>
        This shows the effect of area density on the selected data and allows the selected data to be
        meaningfully compared between features of high and low area.
      </Body2>
      <Subtitle2>Both population and area</Subtitle2>
      <Body2>
        The selected data of a given feature is normalized by both the population and also the land area of that
        feature, based on US Census Bureau data. The result is adjusted to provide the number of cases
        per 1M person-miles.
      </Body2>
      <Body2>
        This shows the effect of both population and area density on the selected data and allows the selected
        data to be meaningfully compared between features of both high and low population and also high and
        low area.
      </Body2>
      <H5>Data adjustments</H5>
      <Subtitle2>None</Subtitle2>
      <Body2>
        No adjustments are applied to the selected data.
      </Body2>
      <Subtitle2>Smoothed</Subtitle2>
      <Body2>
        The selected data is transformed into a seven-day rolling average. This smooths day-to-day variations
        in reporting.
      </Body2>
      <Subtitle2>Anomalies</Subtitle2>
      <Body2>
        The displayed data is the difference between the data selected by the "Pertains Date"
        control and the seven-day rolling average up to and including that date. A positive anomaly
        means that the displayed value is increasing. A negative anomaly means that the displayed value
        is decreasing.
      </Body2>
      <Body2>
        This map reveals dramatic daily changes in selected data for the selected date. The selected
        data is rapidly decreasing in dark blue areas, rapidly increasing in bright magenta areas, and
        relatively unchanged in green areas.
      </Body2>
      <H5>Analysis results/edge intensity</H5>
      <Body2>
        The COVID data browser technology stack runs a suite of analysis tools
        that are applied to whatever data is in the database (including the results
        other analysis tools). The first of these computes a value of "Edge Intensity"
        for whatever is being shown on the map.
      </Body2>
      <Body2>
        The results of each analysis tool are displayed by clicking the named
        control.
      </Body2>
      <Subtitle2>Edge Intensity</Subtitle2>
      <Body2>
        The "edge intensity" of a county is highest when that county is at a boundary
        between neighbors whose value is high and those whose value is low. Since, like weather,
        COVID is a geographic phenemenon, these boundaries are analogous to warm and cold fronts
        on a weather map.
      </Body2>
      <Body2>
        Counties with a high edge intensity for a particular variable are more likely to
        experience a change in that variable in the immediate future than counties with a
        low edge intensity. A sequence of edge intensity maps over several days provides
        guidance about the rate at which a local event is spreading or shrinking.
      </Body2>
      <H5>Pertains date</H5>
      <Body2>
        The date for which data is currently displayed.
      </Body2>
      <H5>Display Scale</H5>
      <Body2>
        This controls the color displayed for a specific county. While "Linear" is selected,
        the color chosen is linearly proportional to the value of the selected county compared
        to the difference between the minimum and maximum values. While "log" is selected,
        the color chosen is instead proportional to the decimal logarithm of the value.
      </Body2>
      <Body2>
        This data is heavily concentrated, so that a very small number of counties have very high
        values, and a very large number of counties have small values. Selecting "linear" makes it
        easier to see the counties where the value is highest (colored in red). Selecting "log" makes
        it possible to see the differences among counties with low values.
      </Body2>
      <Body2>
        The caret (small black diamond) shows the relative position in the scale of the color of
        the county under the current mouse location.
      </Body2>
      <H5>Detailed exceptions to daily reporting</H5>
      <body2>
        Detailed listing of non-daily reporting counties (the 99 counties of Iowa are excluded)
      </body2>
      <Divider style={{marginTop: '15px'}}/>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableCell><strong>County</strong></TableCell><TableCell><strong>State</strong></TableCell><TableCell><strong>FIPS</strong></TableCell><TableCell><strong>Note</strong></TableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Fairfield</TableCell><TableCell>Connecticut</TableCell><TableCell>09001</TableCell><TableCell>no data on Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hartford</TableCell><TableCell>Connecticut</TableCell><TableCell>09003</TableCell><TableCell>no data on Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Litchfield</TableCell><TableCell>Connecticut</TableCell><TableCell>09005</TableCell><TableCell>no data on Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Middlesex</TableCell><TableCell>Connecticut</TableCell><TableCell>09007</TableCell><TableCell>no data on Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>New Haven</TableCell><TableCell>Connecticut</TableCell><TableCell>09009</TableCell><TableCell>no data on Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>New London</TableCell><TableCell>Connecticut</TableCell><TableCell>09011</TableCell><TableCell>no data on Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tolland</TableCell><TableCell>Connecticut</TableCell><TableCell>09013</TableCell><TableCell>no data on Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Windham</TableCell><TableCell>Connecticut</TableCell><TableCell>09015</TableCell><TableCell>no data on Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Allen</TableCell><TableCell>Kansas</TableCell><TableCell>20001</TableCell><TableCell>No data on Wednesday, Friday,Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Anderson</TableCell><TableCell>Kansas</TableCell><TableCell>20003</TableCell><TableCell>No data on Wednesday, Friday,Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Atchison</TableCell><TableCell>Kansas</TableCell><TableCell>20005</TableCell><TableCell>No data on Monday, Wednesday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Barber</TableCell><TableCell>Kansas</TableCell><TableCell>20007</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Barton</TableCell><TableCell>Kansas</TableCell><TableCell>20009</TableCell><TableCell>No data  on Friday or Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bourbon</TableCell><TableCell>Kansas</TableCell><TableCell>20011</TableCell><TableCell>No data on Wednesday, Friday,Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Brown</TableCell><TableCell>Kansas</TableCell><TableCell>20013</TableCell><TableCell>No data on Wednesday, Friday,Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Butler</TableCell><TableCell>Kansas</TableCell><TableCell>20015</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Chase</TableCell><TableCell>Kansas</TableCell><TableCell>20017</TableCell><TableCell>No data on Wednesday, Friday,Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Chautauqua</TableCell><TableCell>Kansas</TableCell><TableCell>20019</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cherokee</TableCell><TableCell>Kansas</TableCell><TableCell>20021</TableCell><TableCell>No data on Wednesday or Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cheyenne</TableCell><TableCell>Kansas</TableCell><TableCell>20023</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Clark</TableCell><TableCell>Kansas</TableCell><TableCell>20025</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Clay</TableCell><TableCell>Kansas</TableCell><TableCell>20027</TableCell><TableCell>No data on Wednesday or Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cloud</TableCell><TableCell>Kansas</TableCell><TableCell>20029</TableCell><TableCell>No data on Monday, Wednesday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Coffey</TableCell><TableCell>Kansas</TableCell><TableCell>20031</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Comanche</TableCell><TableCell>Kansas</TableCell><TableCell>20033</TableCell><TableCell>No data on Wednesday or Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cowley</TableCell><TableCell>Kansas</TableCell><TableCell>20035</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Crawford</TableCell><TableCell>Kansas</TableCell><TableCell>20037</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Decatur</TableCell><TableCell>Kansas</TableCell><TableCell>20039</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Dickinson</TableCell><TableCell>Kansas</TableCell><TableCell>20041</TableCell><TableCell>No data  on Friday or Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Doniphan</TableCell><TableCell>Kansas</TableCell><TableCell>20043</TableCell><TableCell>No data on Wednesday, Friday,Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Douglas</TableCell><TableCell>Kansas</TableCell><TableCell>20045</TableCell><TableCell>No data  on Friday or Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Edwards</TableCell><TableCell>Kansas</TableCell><TableCell>20047</TableCell><TableCell>No data on Wednesday or Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Elk</TableCell><TableCell>Kansas</TableCell><TableCell>20049</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ellis</TableCell><TableCell>Kansas</TableCell><TableCell>20051</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ellsworth</TableCell><TableCell>Kansas</TableCell><TableCell>20053</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Finney</TableCell><TableCell>Kansas</TableCell><TableCell>20055</TableCell><TableCell>No data  on Friday or Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ford</TableCell><TableCell>Kansas</TableCell><TableCell>20057</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Franklin</TableCell><TableCell>Kansas</TableCell><TableCell>20059</TableCell><TableCell>No data on Wednesday, Friday,Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Geary</TableCell><TableCell>Kansas</TableCell><TableCell>20061</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Gove</TableCell><TableCell>Kansas</TableCell><TableCell>20063</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Graham</TableCell><TableCell>Kansas</TableCell><TableCell>20065</TableCell><TableCell>No data on Wednesday or Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Gray</TableCell><TableCell>Kansas</TableCell><TableCell>20069</TableCell><TableCell>No data on Monday, Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Greeley</TableCell><TableCell>Kansas</TableCell><TableCell>20071</TableCell><TableCell>No data on Monday, Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Greenwood</TableCell><TableCell>Kansas</TableCell><TableCell>20073</TableCell><TableCell>No data  on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hamilton</TableCell><TableCell>Kansas</TableCell><TableCell>20075</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Harper</TableCell><TableCell>Kansas</TableCell><TableCell>20077</TableCell><TableCell>No data on Wednesday or Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Harvey</TableCell><TableCell>Kansas</TableCell><TableCell>20079</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Haskell</TableCell><TableCell>Kansas</TableCell><TableCell>20081</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hodgeman</TableCell><TableCell>Kansas</TableCell><TableCell>20083</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Jackson</TableCell><TableCell>Kansas</TableCell><TableCell>20085</TableCell><TableCell>No data  on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Jefferson</TableCell><TableCell>Kansas</TableCell><TableCell>20087</TableCell><TableCell>No data  on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Jewell</TableCell><TableCell>Kansas</TableCell><TableCell>20089</TableCell><TableCell>No data on Wednesday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Johnson</TableCell><TableCell>Kansas</TableCell><TableCell>20091</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kearny</TableCell><TableCell>Kansas</TableCell><TableCell>20093</TableCell><TableCell>No data on Wednesday, Friday,Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kingman</TableCell><TableCell>Kansas</TableCell><TableCell>20095</TableCell><TableCell>No data on Monday, Wednesday, Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kiowa</TableCell><TableCell>Kansas</TableCell><TableCell>20097</TableCell><TableCell>No data on Wednesday, Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Labette</TableCell><TableCell>Kansas</TableCell><TableCell>20099</TableCell><TableCell>No data on Wednesday, Friday,Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lane</TableCell><TableCell>Kansas</TableCell><TableCell>20101</TableCell><TableCell>No data on Wednesday or Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Leavenworth</TableCell><TableCell>Kansas</TableCell><TableCell>20103</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lincoln</TableCell><TableCell>Kansas</TableCell><TableCell>20105</TableCell><TableCell>No data on Wednesday, Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Linn</TableCell><TableCell>Kansas</TableCell><TableCell>20107</TableCell><TableCell>No data on Wednesday, Friday,Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Logan</TableCell><TableCell>Kansas</TableCell><TableCell>20109</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lyon</TableCell><TableCell>Kansas</TableCell><TableCell>20111</TableCell><TableCell>No data  on Friday or Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Marshall</TableCell><TableCell>Kansas</TableCell><TableCell>20113</TableCell><TableCell>No data  on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Marion</TableCell><TableCell>Kansas</TableCell><TableCell>20117</TableCell><TableCell>No data on Wednesday, Friday,Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Meade</TableCell><TableCell>Kansas</TableCell><TableCell>20119</TableCell><TableCell>No data on Wednesday, Friday,Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Miami</TableCell><TableCell>Kansas</TableCell><TableCell>20121</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Mitchell</TableCell><TableCell>Kansas</TableCell><TableCell>20123</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Montgomery</TableCell><TableCell>Kansas</TableCell><TableCell>20125</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Morris</TableCell><TableCell>Kansas</TableCell><TableCell>20127</TableCell><TableCell>No data on Monday, Wednesday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Morton</TableCell><TableCell>Kansas</TableCell><TableCell>20129</TableCell><TableCell>No data on Wednesday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Nemaha</TableCell><TableCell>Kansas</TableCell><TableCell>20131</TableCell><TableCell>No data on Wednesday, Friday,Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Neosho</TableCell><TableCell>Kansas</TableCell><TableCell>20133</TableCell><TableCell>No data  on Friday or Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ness</TableCell><TableCell>Kansas</TableCell><TableCell>20135</TableCell><TableCell>No data on Monday, Wednesday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Norton</TableCell><TableCell>Kansas</TableCell><TableCell>20137</TableCell><TableCell>No data on Monday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Osage</TableCell><TableCell>Kansas</TableCell><TableCell>20139</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Osborne</TableCell><TableCell>Kansas</TableCell><TableCell>20141</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ottawa</TableCell><TableCell>Kansas</TableCell><TableCell>20143</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Pawnee</TableCell><TableCell>Kansas</TableCell><TableCell>20145</TableCell><TableCell>No data on Wednesday, Friday,Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Pottawatomie</TableCell><TableCell>Kansas</TableCell><TableCell>20149</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Pratt</TableCell><TableCell>Kansas</TableCell><TableCell>20151</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Rawlins</TableCell><TableCell>Kansas</TableCell><TableCell>20153</TableCell><TableCell>No data on Wednesday or Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Rice</TableCell><TableCell>Kansas</TableCell><TableCell>20159</TableCell><TableCell>No data on Wednesday, Friday,Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Riley</TableCell><TableCell>Kansas</TableCell><TableCell>20161</TableCell><TableCell>No data on Monday, Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Rooks</TableCell><TableCell>Kansas</TableCell><TableCell>20163</TableCell><TableCell>No data on Wednesday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Rush</TableCell><TableCell>Kansas</TableCell><TableCell>20165</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Russell</TableCell><TableCell>Kansas</TableCell><TableCell>20167</TableCell><TableCell>No data on Wednesday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Saline</TableCell><TableCell>Kansas</TableCell><TableCell>20169</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Scott</TableCell><TableCell>Kansas</TableCell><TableCell>20171</TableCell><TableCell>No data  on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Sedgwick</TableCell><TableCell>Kansas</TableCell><TableCell>20173</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Shawnee</TableCell><TableCell>Kansas</TableCell><TableCell>20177</TableCell><TableCell>No data  on Friday or Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Sheridan</TableCell><TableCell>Kansas</TableCell><TableCell>20179</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Sherman</TableCell><TableCell>Kansas</TableCell><TableCell>20181</TableCell><TableCell>No data on Wednesday, Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Smith</TableCell><TableCell>Kansas</TableCell><TableCell>20183</TableCell><TableCell>No data on Monday, Wednesday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Stafford</TableCell><TableCell>Kansas</TableCell><TableCell>20185</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Stanton</TableCell><TableCell>Kansas</TableCell><TableCell>20187</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Stevens</TableCell><TableCell>Kansas</TableCell><TableCell>20189</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Sumner</TableCell><TableCell>Kansas</TableCell><TableCell>20191</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Trego</TableCell><TableCell>Kansas</TableCell><TableCell>20195</TableCell><TableCell>No data on Wednesday or Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Wabaunsee</TableCell><TableCell>Kansas</TableCell><TableCell>20197</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Wallace</TableCell><TableCell>Kansas</TableCell><TableCell>20199</TableCell><TableCell>No data on Wednesday or Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Washington</TableCell><TableCell>Kansas</TableCell><TableCell>20201</TableCell><TableCell>No data on Wednesday, Friday,Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Wichita</TableCell><TableCell>Kansas</TableCell><TableCell>20203</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Wilson</TableCell><TableCell>Kansas</TableCell><TableCell>20205</TableCell><TableCell>Data only on Sunday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Woodson</TableCell><TableCell>Kansas</TableCell><TableCell>20207</TableCell><TableCell>No data on Wednesday, Friday,Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Acadia</TableCell><TableCell>Louisiana</TableCell><TableCell>22001</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Allen</TableCell><TableCell>Louisiana</TableCell><TableCell>22003</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ascension</TableCell><TableCell>Louisiana</TableCell><TableCell>22005</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Assumption</TableCell><TableCell>Louisiana</TableCell><TableCell>22007</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Avoyelles</TableCell><TableCell>Louisiana</TableCell><TableCell>22009</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Beauregard</TableCell><TableCell>Louisiana</TableCell><TableCell>22011</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bienville</TableCell><TableCell>Louisiana</TableCell><TableCell>22013</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bossier</TableCell><TableCell>Louisiana</TableCell><TableCell>22015</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Caddo</TableCell><TableCell>Louisiana</TableCell><TableCell>22017</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Calcasieu</TableCell><TableCell>Louisiana</TableCell><TableCell>22019</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Caldwell</TableCell><TableCell>Louisiana</TableCell><TableCell>22021</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cameron</TableCell><TableCell>Louisiana</TableCell><TableCell>22023</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Catahoula</TableCell><TableCell>Louisiana</TableCell><TableCell>22025</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Claiborne</TableCell><TableCell>Louisiana</TableCell><TableCell>22027</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Concordia</TableCell><TableCell>Louisiana</TableCell><TableCell>22029</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>De Soto</TableCell><TableCell>Louisiana</TableCell><TableCell>22031</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>East Baton Rouge</TableCell><TableCell>Louisiana</TableCell><TableCell>22033</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>East Carroll</TableCell><TableCell>Louisiana</TableCell><TableCell>22035</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>East Feliciana</TableCell><TableCell>Louisiana</TableCell><TableCell>22037</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Evangeline</TableCell><TableCell>Louisiana</TableCell><TableCell>22039</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Franklin</TableCell><TableCell>Louisiana</TableCell><TableCell>22041</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Grant</TableCell><TableCell>Louisiana</TableCell><TableCell>22043</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Iberia</TableCell><TableCell>Louisiana</TableCell><TableCell>22045</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Iberville</TableCell><TableCell>Louisiana</TableCell><TableCell>22047</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Jackson</TableCell><TableCell>Louisiana</TableCell><TableCell>22049</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Jefferson</TableCell><TableCell>Louisiana</TableCell><TableCell>22051</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Jefferson Davis</TableCell><TableCell>Louisiana</TableCell><TableCell>22053</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lafayette</TableCell><TableCell>Louisiana</TableCell><TableCell>22055</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lafourche</TableCell><TableCell>Louisiana</TableCell><TableCell>22057</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>LaSalle</TableCell><TableCell>Louisiana</TableCell><TableCell>22059</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lincoln</TableCell><TableCell>Louisiana</TableCell><TableCell>22061</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Livingston</TableCell><TableCell>Louisiana</TableCell><TableCell>22063</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Madison</TableCell><TableCell>Louisiana</TableCell><TableCell>22065</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Morehouse</TableCell><TableCell>Louisiana</TableCell><TableCell>22067</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Natchitoches</TableCell><TableCell>Louisiana</TableCell><TableCell>22069</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Orleans</TableCell><TableCell>Louisiana</TableCell><TableCell>22071</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ouachita</TableCell><TableCell>Louisiana</TableCell><TableCell>22073</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Plaquemines</TableCell><TableCell>Louisiana</TableCell><TableCell>22075</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Pointe Coupee</TableCell><TableCell>Louisiana</TableCell><TableCell>22077</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Rapides</TableCell><TableCell>Louisiana</TableCell><TableCell>22079</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Red River</TableCell><TableCell>Louisiana</TableCell><TableCell>22081</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Richland</TableCell><TableCell>Louisiana</TableCell><TableCell>22083</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Sabine</TableCell><TableCell>Louisiana</TableCell><TableCell>22085</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>St. Bernard</TableCell><TableCell>Louisiana</TableCell><TableCell>22087</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>St. Charles</TableCell><TableCell>Louisiana</TableCell><TableCell>22089</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>St. Helena</TableCell><TableCell>Louisiana</TableCell><TableCell>22091</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>St. James</TableCell><TableCell>Louisiana</TableCell><TableCell>22093</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>St. John the Baptist</TableCell><TableCell>Louisiana</TableCell><TableCell>22095</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>St. Landry</TableCell><TableCell>Louisiana</TableCell><TableCell>22097</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>St. Martin</TableCell><TableCell>Louisiana</TableCell><TableCell>22099</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>St. Mary</TableCell><TableCell>Louisiana</TableCell><TableCell>22101</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>St. Tammany</TableCell><TableCell>Louisiana</TableCell><TableCell>22103</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tangipahoa</TableCell><TableCell>Louisiana</TableCell><TableCell>22105</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tensas</TableCell><TableCell>Louisiana</TableCell><TableCell>22107</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Terrebonne</TableCell><TableCell>Louisiana</TableCell><TableCell>22109</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Union</TableCell><TableCell>Louisiana</TableCell><TableCell>22111</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Vermilion</TableCell><TableCell>Louisiana</TableCell><TableCell>22113</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Vernon</TableCell><TableCell>Louisiana</TableCell><TableCell>22115</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Washington</TableCell><TableCell>Louisiana</TableCell><TableCell>22117</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Webster</TableCell><TableCell>Louisiana</TableCell><TableCell>22119</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>West Baton Rouge</TableCell><TableCell>Louisiana</TableCell><TableCell>22121</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>West Carroll</TableCell><TableCell>Louisiana</TableCell><TableCell>22123</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>West Feliciana</TableCell><TableCell>Louisiana</TableCell><TableCell>22125</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Winn</TableCell><TableCell>Louisiana</TableCell><TableCell>22127</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Alcona</TableCell><TableCell>Michigan</TableCell><TableCell>26001</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Alger</TableCell><TableCell>Michigan</TableCell><TableCell>26003</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Allegan</TableCell><TableCell>Michigan</TableCell><TableCell>26005</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Alpena</TableCell><TableCell>Michigan</TableCell><TableCell>26007</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Arenac</TableCell><TableCell>Michigan</TableCell><TableCell>26011</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Baraga</TableCell><TableCell>Michigan</TableCell><TableCell>26013</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Barry</TableCell><TableCell>Michigan</TableCell><TableCell>26015</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bay</TableCell><TableCell>Michigan</TableCell><TableCell>26017</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Benzie</TableCell><TableCell>Michigan</TableCell><TableCell>26019</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Branch</TableCell><TableCell>Michigan</TableCell><TableCell>26023</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Calhoun</TableCell><TableCell>Michigan</TableCell><TableCell>26025</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cass</TableCell><TableCell>Michigan</TableCell><TableCell>26027</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cheboygan</TableCell><TableCell>Michigan</TableCell><TableCell>26031</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Chippewa</TableCell><TableCell>Michigan</TableCell><TableCell>26033</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Clare</TableCell><TableCell>Michigan</TableCell><TableCell>26035</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Clinton</TableCell><TableCell>Michigan</TableCell><TableCell>26037</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Crawford</TableCell><TableCell>Michigan</TableCell><TableCell>26039</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Delta</TableCell><TableCell>Michigan</TableCell><TableCell>26041</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Dickinson</TableCell><TableCell>Michigan</TableCell><TableCell>26043</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Eaton</TableCell><TableCell>Michigan</TableCell><TableCell>26045</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Genesee</TableCell><TableCell>Michigan</TableCell><TableCell>26049</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Gladwin</TableCell><TableCell>Michigan</TableCell><TableCell>26051</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Gogebic</TableCell><TableCell>Michigan</TableCell><TableCell>26053</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Grand Traverse</TableCell><TableCell>Michigan</TableCell><TableCell>26055</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Gratiot</TableCell><TableCell>Michigan</TableCell><TableCell>26057</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hillsdale</TableCell><TableCell>Michigan</TableCell><TableCell>26059</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Houghton</TableCell><TableCell>Michigan</TableCell><TableCell>26061</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Huron</TableCell><TableCell>Michigan</TableCell><TableCell>26063</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ingham</TableCell><TableCell>Michigan</TableCell><TableCell>26065</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ionia</TableCell><TableCell>Michigan</TableCell><TableCell>26067</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Iosco</TableCell><TableCell>Michigan</TableCell><TableCell>26069</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Iron</TableCell><TableCell>Michigan</TableCell><TableCell>26071</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Isabella</TableCell><TableCell>Michigan</TableCell><TableCell>26073</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Jackson</TableCell><TableCell>Michigan</TableCell><TableCell>26075</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kalamazoo</TableCell><TableCell>Michigan</TableCell><TableCell>26077</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kalkaska</TableCell><TableCell>Michigan</TableCell><TableCell>26079</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kent</TableCell><TableCell>Michigan</TableCell><TableCell>26081</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Keweenaw</TableCell><TableCell>Michigan</TableCell><TableCell>26083</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lake</TableCell><TableCell>Michigan</TableCell><TableCell>26085</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lapeer</TableCell><TableCell>Michigan</TableCell><TableCell>26087</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Leelanau</TableCell><TableCell>Michigan</TableCell><TableCell>26089</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lenawee</TableCell><TableCell>Michigan</TableCell><TableCell>26091</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Livingston</TableCell><TableCell>Michigan</TableCell><TableCell>26093</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Luce</TableCell><TableCell>Michigan</TableCell><TableCell>26095</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Mackinac</TableCell><TableCell>Michigan</TableCell><TableCell>26097</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Macomb</TableCell><TableCell>Michigan</TableCell><TableCell>26099</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Manistee</TableCell><TableCell>Michigan</TableCell><TableCell>26101</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Marquette</TableCell><TableCell>Michigan</TableCell><TableCell>26103</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Mason</TableCell><TableCell>Michigan</TableCell><TableCell>26105</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Mecosta</TableCell><TableCell>Michigan</TableCell><TableCell>26107</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Menominee</TableCell><TableCell>Michigan</TableCell><TableCell>26109</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Midland</TableCell><TableCell>Michigan</TableCell><TableCell>26111</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Missaukee</TableCell><TableCell>Michigan</TableCell><TableCell>26113</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Monroe</TableCell><TableCell>Michigan</TableCell><TableCell>26115</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Montcalm</TableCell><TableCell>Michigan</TableCell><TableCell>26117</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Montmorency</TableCell><TableCell>Michigan</TableCell><TableCell>26119</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Muskegon</TableCell><TableCell>Michigan</TableCell><TableCell>26121</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Newaygo</TableCell><TableCell>Michigan</TableCell><TableCell>26123</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Oakland</TableCell><TableCell>Michigan</TableCell><TableCell>26125</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Oceana</TableCell><TableCell>Michigan</TableCell><TableCell>26127</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ogemaw</TableCell><TableCell>Michigan</TableCell><TableCell>26129</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ontonagon</TableCell><TableCell>Michigan</TableCell><TableCell>26131</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Osceola</TableCell><TableCell>Michigan</TableCell><TableCell>26133</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Oscoda</TableCell><TableCell>Michigan</TableCell><TableCell>26135</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Presque Isle</TableCell><TableCell>Michigan</TableCell><TableCell>26141</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Roscommon</TableCell><TableCell>Michigan</TableCell><TableCell>26143</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Saginaw</TableCell><TableCell>Michigan</TableCell><TableCell>26145</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>St. Joseph</TableCell><TableCell>Michigan</TableCell><TableCell>26149</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Sanilac</TableCell><TableCell>Michigan</TableCell><TableCell>26151</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Schoolcraft</TableCell><TableCell>Michigan</TableCell><TableCell>26153</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Shiawassee</TableCell><TableCell>Michigan</TableCell><TableCell>26155</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tuscola</TableCell><TableCell>Michigan</TableCell><TableCell>26157</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Van Buren</TableCell><TableCell>Michigan</TableCell><TableCell>26159</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Washtenaw</TableCell><TableCell>Michigan</TableCell><TableCell>26161</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Wayne</TableCell><TableCell>Michigan</TableCell><TableCell>26163</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Wexford</TableCell><TableCell>Michigan</TableCell><TableCell>26165</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Adair</TableCell><TableCell>Oklahoma</TableCell><TableCell>40001</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Alfalfa</TableCell><TableCell>Oklahoma</TableCell><TableCell>40003</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Atoka</TableCell><TableCell>Oklahoma</TableCell><TableCell>40005</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Beaver</TableCell><TableCell>Oklahoma</TableCell><TableCell>40007</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Beckham</TableCell><TableCell>Oklahoma</TableCell><TableCell>40009</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Blaine</TableCell><TableCell>Oklahoma</TableCell><TableCell>40011</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bryan</TableCell><TableCell>Oklahoma</TableCell><TableCell>40013</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Caddo</TableCell><TableCell>Oklahoma</TableCell><TableCell>40015</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Canadian</TableCell><TableCell>Oklahoma</TableCell><TableCell>40017</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Carter</TableCell><TableCell>Oklahoma</TableCell><TableCell>40019</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cherokee</TableCell><TableCell>Oklahoma</TableCell><TableCell>40021</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Choctaw</TableCell><TableCell>Oklahoma</TableCell><TableCell>40023</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cimarron</TableCell><TableCell>Oklahoma</TableCell><TableCell>40025</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cleveland</TableCell><TableCell>Oklahoma</TableCell><TableCell>40027</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Coal</TableCell><TableCell>Oklahoma</TableCell><TableCell>40029</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Comanche</TableCell><TableCell>Oklahoma</TableCell><TableCell>40031</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cotton</TableCell><TableCell>Oklahoma</TableCell><TableCell>40033</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Craig</TableCell><TableCell>Oklahoma</TableCell><TableCell>40035</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Creek</TableCell><TableCell>Oklahoma</TableCell><TableCell>40037</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Custer</TableCell><TableCell>Oklahoma</TableCell><TableCell>40039</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Delaware</TableCell><TableCell>Oklahoma</TableCell><TableCell>40041</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Dewey</TableCell><TableCell>Oklahoma</TableCell><TableCell>40043</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ellis</TableCell><TableCell>Oklahoma</TableCell><TableCell>40045</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Garfield</TableCell><TableCell>Oklahoma</TableCell><TableCell>40047</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Garvin</TableCell><TableCell>Oklahoma</TableCell><TableCell>40049</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Grady</TableCell><TableCell>Oklahoma</TableCell><TableCell>40051</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Grant</TableCell><TableCell>Oklahoma</TableCell><TableCell>40053</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Greer</TableCell><TableCell>Oklahoma</TableCell><TableCell>40055</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Harmon</TableCell><TableCell>Oklahoma</TableCell><TableCell>40057</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Harper</TableCell><TableCell>Oklahoma</TableCell><TableCell>40059</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Haskell</TableCell><TableCell>Oklahoma</TableCell><TableCell>40061</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hughes</TableCell><TableCell>Oklahoma</TableCell><TableCell>40063</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Jackson</TableCell><TableCell>Oklahoma</TableCell><TableCell>40065</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Jefferson</TableCell><TableCell>Oklahoma</TableCell><TableCell>40067</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Johnston</TableCell><TableCell>Oklahoma</TableCell><TableCell>40069</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kay</TableCell><TableCell>Oklahoma</TableCell><TableCell>40071</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kingfisher</TableCell><TableCell>Oklahoma</TableCell><TableCell>40073</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kiowa</TableCell><TableCell>Oklahoma</TableCell><TableCell>40075</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Latimer</TableCell><TableCell>Oklahoma</TableCell><TableCell>40077</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Le Flore</TableCell><TableCell>Oklahoma</TableCell><TableCell>40079</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lincoln</TableCell><TableCell>Oklahoma</TableCell><TableCell>40081</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Logan</TableCell><TableCell>Oklahoma</TableCell><TableCell>40083</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Love</TableCell><TableCell>Oklahoma</TableCell><TableCell>40085</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Major</TableCell><TableCell>Oklahoma</TableCell><TableCell>40093</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Marshall</TableCell><TableCell>Oklahoma</TableCell><TableCell>40095</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Mayes</TableCell><TableCell>Oklahoma</TableCell><TableCell>40097</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>McClain</TableCell><TableCell>Oklahoma</TableCell><TableCell>40087</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>McCurtain</TableCell><TableCell>Oklahoma</TableCell><TableCell>40089</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>McIntosh</TableCell><TableCell>Oklahoma</TableCell><TableCell>40091</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Murray</TableCell><TableCell>Oklahoma</TableCell><TableCell>40099</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Muskogee</TableCell><TableCell>Oklahoma</TableCell><TableCell>40101</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Noble</TableCell><TableCell>Oklahoma</TableCell><TableCell>40103</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Nowata</TableCell><TableCell>Oklahoma</TableCell><TableCell>40105</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Okfuskee</TableCell><TableCell>Oklahoma</TableCell><TableCell>40107</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Oklahoma</TableCell><TableCell>Oklahoma</TableCell><TableCell>40109</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Okmulgee</TableCell><TableCell>Oklahoma</TableCell><TableCell>40111</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Osage</TableCell><TableCell>Oklahoma</TableCell><TableCell>40113</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ottawa</TableCell><TableCell>Oklahoma</TableCell><TableCell>40115</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Pawnee</TableCell><TableCell>Oklahoma</TableCell><TableCell>40117</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Payne</TableCell><TableCell>Oklahoma</TableCell><TableCell>40119</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Pittsburg</TableCell><TableCell>Oklahoma</TableCell><TableCell>40121</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Pontotoc</TableCell><TableCell>Oklahoma</TableCell><TableCell>40123</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Pottawatomie</TableCell><TableCell>Oklahoma</TableCell><TableCell>40125</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Pushmataha</TableCell><TableCell>Oklahoma</TableCell><TableCell>40127</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Roger Mills</TableCell><TableCell>Oklahoma</TableCell><TableCell>40129</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Rogers</TableCell><TableCell>Oklahoma</TableCell><TableCell>40131</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Seminole</TableCell><TableCell>Oklahoma</TableCell><TableCell>40133</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Sequoyah</TableCell><TableCell>Oklahoma</TableCell><TableCell>40135</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Stephens</TableCell><TableCell>Oklahoma</TableCell><TableCell>40137</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Texas</TableCell><TableCell>Oklahoma</TableCell><TableCell>40139</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tillman</TableCell><TableCell>Oklahoma</TableCell><TableCell>40141</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tulsa</TableCell><TableCell>Oklahoma</TableCell><TableCell>40143</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Wagoner</TableCell><TableCell>Oklahoma</TableCell><TableCell>40145</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Washington</TableCell><TableCell>Oklahoma</TableCell><TableCell>40147</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Washita</TableCell><TableCell>Oklahoma</TableCell><TableCell>40149</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Woods</TableCell><TableCell>Oklahoma</TableCell><TableCell>40151</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Woodward</TableCell><TableCell>Oklahoma</TableCell><TableCell>40153</TableCell><TableCell>stopped reporting on March 15 and then began weekly reporting on March 24, 2021</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bristol</TableCell><TableCell>Rhode Island</TableCell><TableCell>44001</TableCell><TableCell>Data only on Wednesday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kent</TableCell><TableCell>Rhode Island</TableCell><TableCell>44003</TableCell><TableCell>Data only on Wednesday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Newport</TableCell><TableCell>Rhode Island</TableCell><TableCell>44005</TableCell><TableCell>Data only on Wednesday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Providence</TableCell><TableCell>Rhode Island</TableCell><TableCell>44007</TableCell><TableCell>Data only on Wednesday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Washington</TableCell><TableCell>Rhode Island</TableCell><TableCell>44009</TableCell><TableCell>Data only on Wednesday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Andrews</TableCell><TableCell>Texas</TableCell><TableCell>48003</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Angelina</TableCell><TableCell>Texas</TableCell><TableCell>48005</TableCell><TableCell>No data on Thursday Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Aransas</TableCell><TableCell>Texas</TableCell><TableCell>48007</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Archer</TableCell><TableCell>Texas</TableCell><TableCell>48009</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Armstrong</TableCell><TableCell>Texas</TableCell><TableCell>48011</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Atascosa</TableCell><TableCell>Texas</TableCell><TableCell>48013</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Baylor</TableCell><TableCell>Texas</TableCell><TableCell>48023</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bee</TableCell><TableCell>Texas</TableCell><TableCell>48025</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Blanco</TableCell><TableCell>Texas</TableCell><TableCell>48031</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Borden</TableCell><TableCell>Texas</TableCell><TableCell>48033</TableCell><TableCell>Data only on Monday,Thursday,Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bosque</TableCell><TableCell>Texas</TableCell><TableCell>48035</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Briscoe</TableCell><TableCell>Texas</TableCell><TableCell>48045</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Brooks</TableCell><TableCell>Texas</TableCell><TableCell>48047</TableCell><TableCell>No data on Tuesday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Brown</TableCell><TableCell>Texas</TableCell><TableCell>48049</TableCell><TableCell>No data on Sunday, Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Caldwell</TableCell><TableCell>Texas</TableCell><TableCell>48055</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Callahan</TableCell><TableCell>Texas</TableCell><TableCell>48059</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cameron</TableCell><TableCell>Texas</TableCell><TableCell>48061</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Carson</TableCell><TableCell>Texas</TableCell><TableCell>48065</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Castro</TableCell><TableCell>Texas</TableCell><TableCell>48069</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Clay</TableCell><TableCell>Texas</TableCell><TableCell>48077</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cochran</TableCell><TableCell>Texas</TableCell><TableCell>48079</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Coke</TableCell><TableCell>Texas</TableCell><TableCell>48081</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Coleman</TableCell><TableCell>Texas</TableCell><TableCell>48083</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Collin</TableCell><TableCell>Texas</TableCell><TableCell>48085</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Collingsworth</TableCell><TableCell>Texas</TableCell><TableCell>48087</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Colorado</TableCell><TableCell>Texas</TableCell><TableCell>48089</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Comal</TableCell><TableCell>Texas</TableCell><TableCell>48091</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Comanche</TableCell><TableCell>Texas</TableCell><TableCell>48093</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Concho</TableCell><TableCell>Texas</TableCell><TableCell>48095</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cooke</TableCell><TableCell>Texas</TableCell><TableCell>48097</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cottle</TableCell><TableCell>Texas</TableCell><TableCell>48101</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Crockett</TableCell><TableCell>Texas</TableCell><TableCell>48105</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Crosby</TableCell><TableCell>Texas</TableCell><TableCell>48107</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Dawson</TableCell><TableCell>Texas</TableCell><TableCell>48115</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Dickens</TableCell><TableCell>Texas</TableCell><TableCell>48125</TableCell><TableCell>Data only on Monday, Wednesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Dimmit</TableCell><TableCell>Texas</TableCell><TableCell>48127</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Donley</TableCell><TableCell>Texas</TableCell><TableCell>48129</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Duval</TableCell><TableCell>Texas</TableCell><TableCell>48131</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Eastland</TableCell><TableCell>Texas</TableCell><TableCell>48133</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ector</TableCell><TableCell>Texas</TableCell><TableCell>48135</TableCell><TableCell>no data on Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Edwards</TableCell><TableCell>Texas</TableCell><TableCell>48137</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ellis</TableCell><TableCell>Texas</TableCell><TableCell>48139</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>El Paso</TableCell><TableCell>Texas</TableCell><TableCell>48141</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Erath</TableCell><TableCell>Texas</TableCell><TableCell>48143</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Fannin</TableCell><TableCell>Texas</TableCell><TableCell>48147</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Fayette</TableCell><TableCell>Texas</TableCell><TableCell>48149</TableCell><TableCell>No data on Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Fisher</TableCell><TableCell>Texas</TableCell><TableCell>48151</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Floyd</TableCell><TableCell>Texas</TableCell><TableCell>48153</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Foard</TableCell><TableCell>Texas</TableCell><TableCell>48155</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Fort Bend</TableCell><TableCell>Texas</TableCell><TableCell>48157</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Gaines</TableCell><TableCell>Texas</TableCell><TableCell>48165</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Galveston</TableCell><TableCell>Texas</TableCell><TableCell>48167</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Garza</TableCell><TableCell>Texas</TableCell><TableCell>48169</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Gillespie</TableCell><TableCell>Texas</TableCell><TableCell>48171</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Goliad</TableCell><TableCell>Texas</TableCell><TableCell>48175</TableCell><TableCell>No data on Sunday, Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Gonzales</TableCell><TableCell>Texas</TableCell><TableCell>48177</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Gray</TableCell><TableCell>Texas</TableCell><TableCell>48179</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Grayson</TableCell><TableCell>Texas</TableCell><TableCell>48181</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Gregg</TableCell><TableCell>Texas</TableCell><TableCell>48183</TableCell><TableCell>No data on Sunday, Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hale</TableCell><TableCell>Texas</TableCell><TableCell>48189</TableCell><TableCell>no data on Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hall</TableCell><TableCell>Texas</TableCell><TableCell>48191</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hamilton</TableCell><TableCell>Texas</TableCell><TableCell>48193</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hansford</TableCell><TableCell>Texas</TableCell><TableCell>48195</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hardeman</TableCell><TableCell>Texas</TableCell><TableCell>48197</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hardin</TableCell><TableCell>Texas</TableCell><TableCell>48199</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hartley</TableCell><TableCell>Texas</TableCell><TableCell>48205</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Haskell</TableCell><TableCell>Texas</TableCell><TableCell>48207</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hays</TableCell><TableCell>Texas</TableCell><TableCell>48209</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Henderson</TableCell><TableCell>Texas</TableCell><TableCell>48213</TableCell><TableCell>no data on Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hidalgo</TableCell><TableCell>Texas</TableCell><TableCell>48215</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hood</TableCell><TableCell>Texas</TableCell><TableCell>48221</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Jack</TableCell><TableCell>Texas</TableCell><TableCell>48237</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Jackson</TableCell><TableCell>Texas</TableCell><TableCell>48239</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Jeff Davis</TableCell><TableCell>Texas</TableCell><TableCell>48243</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Jefferson</TableCell><TableCell>Texas</TableCell><TableCell>48245</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Jim Hogg</TableCell><TableCell>Texas</TableCell><TableCell>48247</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Jim Wells</TableCell><TableCell>Texas</TableCell><TableCell>48249</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Johnson</TableCell><TableCell>Texas</TableCell><TableCell>48251</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Jones</TableCell><TableCell>Texas</TableCell><TableCell>48253</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Karnes</TableCell><TableCell>Texas</TableCell><TableCell>48255</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kaufman</TableCell><TableCell>Texas</TableCell><TableCell>48257</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kendall</TableCell><TableCell>Texas</TableCell><TableCell>48259</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kenedy</TableCell><TableCell>Texas</TableCell><TableCell>48261</TableCell><TableCell>Data only on Monday, Tuesday, Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kent</TableCell><TableCell>Texas</TableCell><TableCell>48263</TableCell><TableCell>Data only on Monday,Thursday,Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kerr</TableCell><TableCell>Texas</TableCell><TableCell>48265</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kinney</TableCell><TableCell>Texas</TableCell><TableCell>48271</TableCell><TableCell>no data on Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kleberg</TableCell><TableCell>Texas</TableCell><TableCell>48273</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Knox</TableCell><TableCell>Texas</TableCell><TableCell>48275</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lamb</TableCell><TableCell>Texas</TableCell><TableCell>48279</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>La Salle</TableCell><TableCell>Texas</TableCell><TableCell>48283</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Live Oak</TableCell><TableCell>Texas</TableCell><TableCell>48297</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Llano</TableCell><TableCell>Texas</TableCell><TableCell>48299</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lubbock</TableCell><TableCell>Texas</TableCell><TableCell>48303</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lynn</TableCell><TableCell>Texas</TableCell><TableCell>48305</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>McMullen</TableCell><TableCell>Texas</TableCell><TableCell>48311</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Marion</TableCell><TableCell>Texas</TableCell><TableCell>48315</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Martin</TableCell><TableCell>Texas</TableCell><TableCell>48317</TableCell><TableCell>No data on Tuesday, Wednesday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Mason</TableCell><TableCell>Texas</TableCell><TableCell>48319</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Maverick</TableCell><TableCell>Texas</TableCell><TableCell>48323</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Medina</TableCell><TableCell>Texas</TableCell><TableCell>48325</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Menard</TableCell><TableCell>Texas</TableCell><TableCell>48327</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Midland</TableCell><TableCell>Texas</TableCell><TableCell>48329</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Milam</TableCell><TableCell>Texas</TableCell><TableCell>48331</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Mills</TableCell><TableCell>Texas</TableCell><TableCell>48333</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Mitchell</TableCell><TableCell>Texas</TableCell><TableCell>48335</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Montague</TableCell><TableCell>Texas</TableCell><TableCell>48337</TableCell><TableCell>Data only on Monday, Tuesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Montgomery</TableCell><TableCell>Texas</TableCell><TableCell>48339</TableCell><TableCell>no data on Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Motley</TableCell><TableCell>Texas</TableCell><TableCell>48345</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Navarro</TableCell><TableCell>Texas</TableCell><TableCell>48349</TableCell><TableCell>no data on Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Newton</TableCell><TableCell>Texas</TableCell><TableCell>48351</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Nolan</TableCell><TableCell>Texas</TableCell><TableCell>48353</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Oldham</TableCell><TableCell>Texas</TableCell><TableCell>48359</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Orange</TableCell><TableCell>Texas</TableCell><TableCell>48361</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Palo Pinto</TableCell><TableCell>Texas</TableCell><TableCell>48363</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Panola</TableCell><TableCell>Texas</TableCell><TableCell>48365</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Parker</TableCell><TableCell>Texas</TableCell><TableCell>48367</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Polk</TableCell><TableCell>Texas</TableCell><TableCell>48373</TableCell><TableCell>no data on Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Potter</TableCell><TableCell>Texas</TableCell><TableCell>48375</TableCell><TableCell>no data on Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Presidio</TableCell><TableCell>Texas</TableCell><TableCell>48377</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Rains</TableCell><TableCell>Texas</TableCell><TableCell>48379</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Randall</TableCell><TableCell>Texas</TableCell><TableCell>48381</TableCell><TableCell>no data on Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Reagan</TableCell><TableCell>Texas</TableCell><TableCell>48383</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Real</TableCell><TableCell>Texas</TableCell><TableCell>48385</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Refugio</TableCell><TableCell>Texas</TableCell><TableCell>48391</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Roberts</TableCell><TableCell>Texas</TableCell><TableCell>48393</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Robertson</TableCell><TableCell>Texas</TableCell><TableCell>48395</TableCell><TableCell>No data on Tuesday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Runnels</TableCell><TableCell>Texas</TableCell><TableCell>48399</TableCell><TableCell>No data on Sunday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>San Augustine</TableCell><TableCell>Texas</TableCell><TableCell>48405</TableCell><TableCell>No data on Thursday Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>San Patricio</TableCell><TableCell>Texas</TableCell><TableCell>48409</TableCell><TableCell>Data only on Monday, Wednesday, Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Scurry</TableCell><TableCell>Texas</TableCell><TableCell>48415</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Shackelford</TableCell><TableCell>Texas</TableCell><TableCell>48417</TableCell><TableCell>No data on Sunday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Sherman</TableCell><TableCell>Texas</TableCell><TableCell>48421</TableCell><TableCell>No data on Tuesday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Smith</TableCell><TableCell>Texas</TableCell><TableCell>48423</TableCell><TableCell>No data on Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Somervell</TableCell><TableCell>Texas</TableCell><TableCell>48425</TableCell><TableCell>No data on Sunday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Starr</TableCell><TableCell>Texas</TableCell><TableCell>48427</TableCell><TableCell>No data on Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Stephens</TableCell><TableCell>Texas</TableCell><TableCell>48429</TableCell><TableCell>No data on Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Sterling</TableCell><TableCell>Texas</TableCell><TableCell>48431</TableCell><TableCell>No data on Sunday, Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Stonewall</TableCell><TableCell>Texas</TableCell><TableCell>48433</TableCell><TableCell>No data on Sunday, Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Sutton</TableCell><TableCell>Texas</TableCell><TableCell>48435</TableCell><TableCell>No data on Monday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Swisher</TableCell><TableCell>Texas</TableCell><TableCell>48437</TableCell><TableCell>No data on Monday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tarrant</TableCell><TableCell>Texas</TableCell><TableCell>48439</TableCell><TableCell>No data on Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Taylor</TableCell><TableCell>Texas</TableCell><TableCell>48441</TableCell><TableCell>No data on Monday, Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Terrell</TableCell><TableCell>Texas</TableCell><TableCell>48443</TableCell><TableCell>No data on Monday, Friday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Terry</TableCell><TableCell>Texas</TableCell><TableCell>48445</TableCell><TableCell>No data on Sunday, Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Throckmorton</TableCell><TableCell>Texas</TableCell><TableCell>48447</TableCell><TableCell>No data on Sunday, Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Titus</TableCell><TableCell>Texas</TableCell><TableCell>48449</TableCell><TableCell>No data on Sunday, Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tom Green</TableCell><TableCell>Texas</TableCell><TableCell>48451</TableCell><TableCell>No data on Monday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Travis</TableCell><TableCell>Texas</TableCell><TableCell>48453</TableCell><TableCell>No data on Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Trinity</TableCell><TableCell>Texas</TableCell><TableCell>48455</TableCell><TableCell>No data on Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tyler</TableCell><TableCell>Texas</TableCell><TableCell>48457</TableCell><TableCell>No data on Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Upshur</TableCell><TableCell>Texas</TableCell><TableCell>48459</TableCell><TableCell>No data on Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Upton</TableCell><TableCell>Texas</TableCell><TableCell>48461</TableCell><TableCell>No data on Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Uvalde</TableCell><TableCell>Texas</TableCell><TableCell>48463</TableCell><TableCell>No data on Monday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Val Verde</TableCell><TableCell>Texas</TableCell><TableCell>48465</TableCell><TableCell>No data on Monday, Wednesday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Van Zandt</TableCell><TableCell>Texas</TableCell><TableCell>48467</TableCell><TableCell>No data on Monday, Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Victoria</TableCell><TableCell>Texas</TableCell><TableCell>48469</TableCell><TableCell>Data only on Tuesday, Wednesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Walker</TableCell><TableCell>Texas</TableCell><TableCell>48471</TableCell><TableCell>No data on Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Waller</TableCell><TableCell>Texas</TableCell><TableCell>48473</TableCell><TableCell>No data on Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ward</TableCell><TableCell>Texas</TableCell><TableCell>48475</TableCell><TableCell>No data on Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Washington</TableCell><TableCell>Texas</TableCell><TableCell>48477</TableCell><TableCell>No data on Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Webb</TableCell><TableCell>Texas</TableCell><TableCell>48479</TableCell><TableCell>No data on Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Wharton</TableCell><TableCell>Texas</TableCell><TableCell>48481</TableCell><TableCell>No data on Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Wheeler</TableCell><TableCell>Texas</TableCell><TableCell>48483</TableCell><TableCell>No data on Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Wichita</TableCell><TableCell>Texas</TableCell><TableCell>48485</TableCell><TableCell>No data on Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Wilbarger</TableCell><TableCell>Texas</TableCell><TableCell>48487</TableCell><TableCell>Data only on Tuesday, Wednesday, Thursday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Willacy</TableCell><TableCell>Texas</TableCell><TableCell>48489</TableCell><TableCell>No data on Sunday, Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Williamson</TableCell><TableCell>Texas</TableCell><TableCell>48491</TableCell><TableCell>No data on Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Wilson</TableCell><TableCell>Texas</TableCell><TableCell>48493</TableCell><TableCell>No data on Monday, Friday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Winkler</TableCell><TableCell>Texas</TableCell><TableCell>48495</TableCell><TableCell>No data on Monday, Saturday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Wise</TableCell><TableCell>Texas</TableCell><TableCell>48497</TableCell><TableCell>No data on Sunday, Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Wood</TableCell><TableCell>Texas</TableCell><TableCell>48499</TableCell><TableCell>No data on Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Yoakum</TableCell><TableCell>Texas</TableCell><TableCell>48501</TableCell><TableCell>No data on Sunday, Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Young</TableCell><TableCell>Texas</TableCell><TableCell>48503</TableCell><TableCell>No data on Sunday, Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Zapata</TableCell><TableCell>Texas</TableCell><TableCell>48505</TableCell><TableCell>No data on Sunday, Monday</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Zavala</TableCell><TableCell>Texas</TableCell><TableCell>48507</TableCell><TableCell>No data on Monday, Friday, Saturday</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
