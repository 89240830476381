/**
 * BooleanDataLoader.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * I need a boolean legend
 */
import DataLoader from './DataLoader';

class BooleanDataLoader extends DataLoader {

  doCovidVariableHTML_(aCovidVariable) {
    const covidVariableHTML =  aCovidVariable ? 'True': 'False';
    return covidVariableHTML
  }

  doHandleLoadedData_(aLoadedBooleanData) {
    const googleMapData = this._directorContext.rawDataRef.current;
    // Each pair contains "true" as its value
    const loadedData = aLoadedBooleanData.map((dataPair) => {return [dataPair[0], dataPair[1]]});

    // I know there's a better way to do this ...
    let featureHash = {}
    let stateCountyFIPSCode;
    // Make the default value be lowerLimit (false)
    googleMapData.forEach((aFeature) => {
      stateCountyFIPSCode = aFeature.getProperty('STATE') + aFeature.getProperty('COUNTY');
      featureHash[stateCountyFIPSCode] = false;
    });

    loadedData.forEach(function(aDataPair) {
      featureHash[aDataPair[0]] = true;
    });
    let booleanData = [];
    for (stateCountyFIPSCode in featureHash) {
      booleanData.push([stateCountyFIPSCode, featureHash[stateCountyFIPSCode]]);
    }

    super.doHandleLoadedData_(booleanData);
  }

  doLoadData() {
    return this.loadBooleanData();
  };

}

export default BooleanDataLoader;
