/**
  * StylerFactory.js
  * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
  * All rights reserved.
  * 
  * The contents of this file may not be copied, duplicated, or used without the
  * written consent of Zeetix, LLC.
  * 
  * I provide a factory method that answers an AbstractFeatureStyler descendant
  * based on the provided parameters.
  *
  * This can probably done as static behavior in the AbstractFeatureStyler
  * tree, but I don't know how.
 **/
import BooleanFeatureStyler from './BooleanFeatureStyler';
import DoubleEndedFeatureStyler from './DoubleEndedFeatureStyler';
import SingleEndedFeatureStyler from './SingleEndedFeatureStyler';
import Limits from './Limits';
import RendererFactory from '../value_renderer/RendererFactory';

class StylerFactory {
  /**
  * Answers a concrete descendant based on the content of aMapControls.
  */
  static createForMapControls_metadata_directorContext_(aMapControls, aMetadata, aDirectorContext) {
    const mapType = ('HotSpots' === aMapControls.dataSource) ? 'boolean' : (('anomalies' === aMapControls.dataAdjustment) ? 'doubleEnded': 'singleEnded');
    let newStyler;
    switch (mapType) {
      case 'boolean':
        newStyler = new BooleanFeatureStyler();
        break;
      case 'singleEnded':
        newStyler = new SingleEndedFeatureStyler();
        break;
      case 'doubleEnded':
        newStyler = new DoubleEndedFeatureStyler()
        break;
      default: {
        const complaint = `Unknown map type: ${aMapControls.mapType}`;
        throw new Error(complaint);
      }
    }
    const renderer = RendererFactory.createRendererForMapControls_(aMapControls);
    const limits = Limits.createFromMetadata_directorContext_(aMetadata, aDirectorContext);
    newStyler.limits_(limits);
    newStyler.renderer_(renderer);
    newStyler.colorKey_(newStyler.colorKeyPreset());
    return newStyler
  };
}
export default StylerFactory;
