/**
 * SourcesPane.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 */
import React from 'react';

import Typography from '@material-ui/core/Typography';

export default function SourcesPane() {
  return (
    <>
      <Typography variant="body2" style={{marginTop: '15px'}}>
        Zeetix LLC is providing this tool free of cost in hopes of
        contributing to the public good. The COVID Data Browser is
        provided under the following free-of-cost, perpetual, non-exclusive
        license. Anyone may use this tool provided (a) any such use
        is for non-commercial purposes only and (b) credit is given to
        Zeetix LLC for any such use, in any work derived in part or in full
        from this tool, and in any other public use of this tool
      </Typography>
      <Typography variant="h5" style={{marginTop: '15px', marginBottom: '5px'}}>New York Times</Typography>
      <Typography variant="body2" style={{'marginTop': '15px'}}>
        We invite you to visit the <a href="https://www.nytimes.com/interactive/2020/us/coronavirus-us-cases.html">New York Times site</a>
        itself for additional ways to interact with the New York Times data.
      </Typography>
      <Typography variant="body2" style={{marginTop: '15px'}}>
        Map data Copyright 2020 by The New York Times Company 
      </Typography>
      <Typography variant="body2" style={{marginTop: '15px'}}>
        In light of the current public health emergency, The New York Times Company is
        providing this database under the following free-of-cost, perpetual,
        non-exclusive license. Anyone may copy, distribute, and display the database, or
        any part thereof, and make derivative works based on it, provided  (a) any such
        use is for non-commercial purposes only and (b) credit is given to The New York
        Times in any public display of the database, in any publication derived in part
        or in full from the database, and in any other public use of the data contained
        in or derived from the database.
      </Typography>
      <Typography variant="body2" style={{marginTop: '15px'}}>
        By accessing or copying any part of the database, the user accepts the terms of
        this license. Anyone seeking to use the database for other purposes is required
        to contact The New York Times Company at covid-data@nytimes.com to obtain
        permission.
      </Typography>
      <Typography variant="body2" style={{marginTop: '15px'}}>
        The New York Times has made every effort to ensure the accuracy of the
        information. However, the database may contain typographic errors or
        inaccuracies and may not be complete or current at any given time. Licensees
        further agree to assume all liability for any claims that may arise from or
        relate in any way to their use of the database and to hold The New York Times
        Company harmless from any such claims.
      </Typography>
      <Typography variant="h5" style={{marginTop: '15px', marginBottom: '5px'}}>United States Census Bureau</Typography>
      <Typography variant="body2" style={{marginTop: '15px'}}>
        Population data by county was extracted from this resource:
        <a href="https://www.census.gov/data/datasets/time-series/demo/popest/2010s-counties-total.html">https://www.census.gov/data/datasets/time-series/demo/popest/2010s-counties-total.html</a>.
        Excel file "c0-est2019-annres.xlsx" was used to extract Annual Estimates of the Resident Population for Counties in the United States: April 1, 2010 to July 1, 2019.
      </Typography>
    </>
  );
}
