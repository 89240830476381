/**
 * AnalyisResultsDataLoader.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * I need a double-ended numeric legend
 */
import DataLoader from './DataLoader';

class AnalyisResultsDataLoader extends DataLoader {

  doLoadData() {
    return this.loadAnalysisResults();
  };

}

export default AnalyisResultsDataLoader;
