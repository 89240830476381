/**
 * PaneSelector.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 * 
 * I provide a component that my consumer uses to display a specific pane among
 * several choices.
 */
import React from 'react';
import {
  Box,
  } from '@material-ui/core';

import PaneContainer from './PaneContainer';

import AboutPane from './AboutPane';
import ContactPane from './ContactPane';
import HelpPane from './HelpPane';
import SourcesPane from './SourcesPane';
import TechnologyPane from './TechnologyPane';

export default function PaneSelector ({ selectedPaneIndex, handlePaneClose, onLinkClick, ...props }) {

  const onCloseHandler = (anEvent) => {
    // console.log(`PaneSelector.onCloseHandler(${anEvent._reactName}Event})`)
    handlePaneClose(selectedPaneIndex);
    // console.log(`PaneSelector.onCloseHandler: Done`)
  };

  // Open coded
  return (
    <Box>
      <PaneContainer
        selectedPaneIndex={selectedPaneIndex}
        paneIndex={0}
        paneTitle="Technology"
        onClose={onCloseHandler}
      >
        <TechnologyPane onLinkClick={onLinkClick} />
      </PaneContainer>
      <PaneContainer
        selectedPaneIndex={selectedPaneIndex}
        paneIndex={1}
        paneTitle="Help"
        onClose={onCloseHandler}
      >
        <HelpPane onLinkClick={onLinkClick} />
      </PaneContainer>
      <PaneContainer
        selectedPaneIndex={selectedPaneIndex}
        paneIndex={2}
        paneTitle="Sources"
        onClose={onCloseHandler}
      >
        <SourcesPane onLinkClick={onLinkClick}  />
      </PaneContainer>
      <PaneContainer
        selectedPaneIndex={selectedPaneIndex}
        paneIndex={3}
        paneTitle="Contact Us"
        onClose={onCloseHandler}
      >
        <ContactPane onLinkClick={onLinkClick}  />
      </PaneContainer>
      <PaneContainer
        selectedPaneIndex={selectedPaneIndex}
        paneIndex={4}
        paneTitle="About Us"
        onClose={onCloseHandler}
      >
        <AboutPane onLinkClick={onLinkClick}  />
      </PaneContainer>
    </Box>
  )
}
