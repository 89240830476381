/**
 * Environment.js
 * @copyright: 2022 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * Provides access to properties populated from the current
 * server environment.
 *
 * 2022-02-25 (tms):
 *  Added access methods for the several sources so consumers of Environment
 *  don't need to worry about which database is current. This is because the
 *  value of several environments variables depends not only on their source
 *  but also on which database they are populated from.
**/
import environmentLoader from './EnvironmentLoader';

class Environment {
  _serverEnvironment = null;
  _loadedEnvironmentHandler = null;

  constructor(aHandler) {
    this._loadedEnvironmentHandler = aHandler;
  };

  /**
    databaseSuffix(self):
        """Answers 'COVID_A' or 'COVID_B' based on database_to_update."""
        databaseSuffix = None
        databaseToUpdate = self.databaseToUpdate()
        if (      ('covid-a' not in databaseToUpdate)
              and ('covid-b' not in databaseToUpdate)
            ):
            self.error_("Unknown database to query")

        if 'covid-a' in databaseToUpdate:
            databaseSuffix = 'COVID_A'
        elif 'covid-b' in databaseToUpdate:
            databaseSuffix = 'COVID_B'
        return databaseSuffix
   */
    
  /**
   Answers a suffix that identifies the database to use for a specific binding.
   */
  databaseSuffix() {
    var databaseSuffix;
    switch (this._serverEnvironment['DATABASE_TO_QUERY'].slice(-7)) {
      case 'covid-a':
        databaseSuffix = 'COVID_A';
        break;
      case 'covid-b':
        databaseSuffix = 'COVID_B'
        break;
      default:
        throw new Error("Missing or invalid DATABASE_TO_QUERY");
    }
    return databaseSuffix
  }

  // Begin access methods for consumers

  nytEarliestPertainsDate() {
    const variableName = `NYT_EARLIEST_PERTAINS_DATE`;
    const answer = this._serverEnvironment[variableName];
    return answer;
  }

  nytLatestPertainsDate() {
    const variableName = `NYT_LATEST_PERTAINS_DATE_${this.databaseSuffix()}`
    const answer = this._serverEnvironment[variableName];
    return answer;
  }

  nytLastUpdateTime() {
    const variableName = `NYT_LAST_UPDATE_TIME_${this.databaseSuffix()}`
    const answer = this._serverEnvironment[variableName];
    return answer;
  }

  cdcEarliestPertainsDate() {
    const variableName = `CDC_EARLIEST_PERTAINS_DATE`;
    const answer = this._serverEnvironment[variableName];
    return answer;
  }

  cdcLatestPertainsDate() {
    const variableName = `CDC_LATEST_PERTAINS_DATE_${this.databaseSuffix()}`
    const answer = this._serverEnvironment[variableName];
    return answer;
  }

  cdcLastUpdateTime() {
    const variableName = `CDC_LAST_UPDATE_TIME_${this.databaseSuffix()}`
    const answer = this._serverEnvironment[variableName];
    return answer;
  }

//   samlerEarliestPertainsDate() {
//     const variableName = `SAMLER_EARLIEST_PERTAINS_DATE`;
//     const answer = this._serverEnvironment[variableName];
//     return answer;
//   }

//   samlerLatestPertainsDate() {
//     const variableName = `SAMLER_LATEST_PERTAINS_DATE_${this.databaseSuffix()}`
//     const answer = this._serverEnvironment[variableName];
//     return answer;
//   }

//   samlerLastUpdateTime() {
//     const variableName = `SAMLER_LAST_UPDATE_TIME_${this.databaseSuffix()}`
//     const answer = this._serverEnvironment[variableName];
//     return answer;
//   }

  zeetixHotspotEarliestPertainsDate() {
    const variableName = `ZEETIX_HOTSPOT_EARLIEST_PERTAINS_DATE`;
    const answer = this._serverEnvironment[variableName];
    return answer;
  }

  zeetixHotspotLatestPertainsDate() {
    const variableName = `ZEETIX_HOTSPOT_LATEST_PERTAINS_DATE_${this.databaseSuffix()}`
    const answer = this._serverEnvironment[variableName];
    return answer;
  }

  zeetixHotspotLastUpdateTime() {
    const variableName = `ZEETIX_HOTSPOT_LAST_UPDATE_TIME_${this.databaseSuffix()}`
    const answer = this._serverEnvironment[variableName];
    return answer;
  }

  // End access methods for consumers

  /**
   * Collects the loaded environment, using a promise to await its loading.
   */
  loadEnvironmentOnError_(anErrorCallback) {
    // console.log(`Environment.loadEnvironmentOnError_(${anErrorCallback})`);
    environmentLoader.loadServerEnvironment()
      .then((aLoadedEnvironment) => {
        // console.log('Environment.loadConfigurationOnError_:successfully returned environment');
        this._serverEnvironment = aLoadedEnvironment
        this._loadedEnvironmentHandler(aLoadedEnvironment);
        })
      .catch (anError => {
        console.log('Environment.loadEnvironmentOnError_: caught: ', anError);
        anErrorCallback(anError)
      });
  }

  serverEnvironment_(aServerEnvironment) {
    this._serverEnvironment = aServerEnvironment;
  }

  getEnvironmentVariableNamed_(anEnvironmentVariableName) {
    return this._serverEnvironment[anEnvironmentVariableName]
  }

  getEnvironment() {
    return this._serverEnvironment
  }

}

export default Environment;
