/**
  * AbstractValueRenderer.js
  * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
  * All rights reserved.
  * 
  * The contents of this file may not be copied, duplicated, or used without the
  * written consent of Zeetix, LLC.
  * 
  * Each descendant of AbstractValueRenderer converts a value to a percentage to
  * value that will be used a styler to style a given feature. There are three
  * descendants of AbstractValueRenderer in March of 2021:
  *   BooleanRenderer
  *   LogRenderer
  *   LinearRenderer
  */

class AbstractValueRenderer {
  #_styler = null;
  #_dataSourceCoverageMap = null;

  doScaleOffsetForValue_isDoubleEnded_(aCovidValue, anIsDoubleEnded) {
    throw new Error("A subclass should have implemented this message");
  }

  doIsSaturatedValue_(aCovidValue) {
    throw new Error("A subclass should have implemented this message");
  }

  doIsSaturatedMaxValue_(aCovidValue) {
    throw new Error("A subclass should have implemented this message");
  }

  doIsSaturatedMinValue_(aCovidValue) {
    throw new Error("A subclass should have implemented this message");
  }

  /**
   * Descendants specialize this to, for example, indicate that linear/vs log
   * values
   */
  doHTMLForValue_(aCovidValue) {
    throw new Error("A subclass should have implemented this message");
  };

  doMinimumScaleString()  {
    throw new Error("A subclass should have implemented this message");
  }

  doMaximumScaleString()  {
    throw new Error("A subclass should have implemented this message");
  }

  doRenderedValueFor_(aCovidValue) {
    throw new Error("A subclass should have implemented this message");
  };

  // Begin accessor methods delegated to my styler

  lowerLimit() {
    return this._styler.lowerLimit();
  };

  upperLimit() {
    return this._styler.upperLimit();
  };

  maximumValue() {
    return this._styler.maximumValue();
  };

  minimumValue() {
    return this._styler.minimumValue();
  };

  // End accessor methods delegated to my styler

  /**
   * Returns a string that represents aNumber in the scale.
   *
   * Looking for N.NN with a suitable suffix
   */
  formatAsString_(aNumber) {
    let suffix = ""
    const mFactor = 1000000
    const kFactor = 1000
    const noFactor = 1
    let scaleFactor = noFactor;
    let roundingFactor = 100;
    if (aNumber >= mFactor) {
      // Millions
      scaleFactor = mFactor
      suffix = "M"
    } else if (aNumber > 1000) {
      // housands
      scaleFactor = kFactor
      suffix = "K"
    }
    const scaledValue = Math.trunc(((aNumber/scaleFactor) * roundingFactor) + 0.5)/roundingFactor
    const answer = `${scaledValue.toLocaleString()} ${suffix}`
    return answer
  }

  styler() {
    return this._styler
  }

  styler_(aStyler) {
    return this._styler = aStyler
  }

  scaleOffsetForValue_isDoubleEnded_(aCovidValue, anIsDoubleEnded) {
    if (!this._styler._limits) {
      // Nothing to do
      return undefined
    }
    return this.doScaleOffsetForValue_isDoubleEnded_(aCovidValue, anIsDoubleEnded);
  };

  isSaturatedValue_(aCovidValue) {
    if (!this._styler._limits) {
      // Nothing to do
      return undefined
    }
    return this.doIsSaturatedValue_(aCovidValue)
  }

  isSaturatedMaxValue_(aCovidValue) {
    if (!this._styler._limits) {
      // Nothing to do
      return undefined
    }
    return this.doIsSaturatedMaxValue_(aCovidValue)
  }

  isSaturatedMinValue_(aCovidValue) {
    if (!this._styler._limits) {
      // Nothing to do
      return undefined
    }
    return this.doIsSaturatedMinValue_(aCovidValue)
  }

  htmlForValue_(aCovidValue) {
    if (!this._styler._limits) {
      // Nothing to do
      return undefined
    }
    return this.doHTMLForValue_(aCovidValue);
  }

  /**
   * Answers a string to display for the minimum scale value.
   */
  minimumScaleString() {
    if (!this._styler._limits) {
      // Nothing to do
      return undefined
    }
    return this.doMinimumScaleString()
  }

  /**
   * Answers a string to display for the maximum scale value.
   */
  maximumScaleString() {
    if (!this._styler._limits) {
      // Nothing to do
      return undefined
    }
    return this.doMaximumScaleString()
  }

  /**
   * Answers a string to display for the minimum scale value.
   */
  renderedValueFor_(aCovidValue) {
    if (!this._styler._limits) {
      // Nothing to do
      return undefined
    }
    return this.doRenderedValueFor_(aCovidValue);
  }

}

export default AbstractValueRenderer;
