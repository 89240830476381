/**
 * Navigator.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * 18-May-2021 (tms):
 *    Wrapping the Hamburger button in a Tooltip causes a findDomNode
 *    complaint. The Tooltip is removed until that issue is fixed in
 *    material-ui.
 *-------------
 * This uses examples from the material-ui documentation:
 *    Custom input:
 *      https://material-ui.com/components/text-fields/
 *
 * I've elided the "directions" button and drawer.
 *
 */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import PaneSelector from './panes/PaneSelector';
import TextSearchField from './TextSearchField';

/**
 * This is an array of bindings from a name to an element name. The index of
 * each binding is used to identify the binding in the List element of my
 * Drawer. The name of each binding is used as the label of the element in
 * the list and as the title of the resulting pane.
 *
 * The element name is used by PaneSelector to construct a ReactElement of
 * that name. Each name must therefore match a pane imported by the
 * PaneSelector.
 */
const panes = [
  {'paneName': 'Technology', 'paneTitle': 'Technology', 'elementName': 'TechnologyPane'},
  {'paneName': 'Help', 'paneTitle': 'Help', 'elementName': 'HelpPane'},
  {'paneName': 'Sources', 'paneTitle': 'Sources', 'elementName': 'SourcesPane'},
  {'paneName': 'Contact', 'paneTitle': 'Contact Us', 'elementName': 'ContactPane'},
  {'paneName': 'About', 'paneTitle': 'About Us', 'elementName': 'AboutPane'},
  ];

const drawerWidth = 240;

const useStyles = makeStyles((aTheme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    width: 360,
  },
  input: {
    marginLeft: aTheme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },

}));

function NavigatorCloseButton({ onClickHandler, ...props }) {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="row-reverse"
    >
      <IconButton
        id="navigatorCloseButtonID"
        onClick={onClickHandler}
        className={classes.iconButton}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  )
};

/**
 * When I'm clicked, I set the selectedItemIndex to my index.
 */
const NavigatorItem = ({ itemIndex, itemLabel, selectedPaneIndex, onItemClick, ...props }) => {
  const itemID = `navigatorItem${itemIndex}`;
  const onClickHandler = (anEvent) => {
    // console.log(`NavigatorItem.onClickHandler(${anEvent._reactName}Event)`)
    anEvent.preventDefault();
    onItemClick(itemLabel, itemIndex)
    // console.log(`NavigatorItem.onClickHandler: Done`)
  }

  return (
    <ListItem
      id={itemID}
      button
      onClick={onClickHandler}
    >
      <ListItemText inset primary={itemLabel} />
    </ListItem>
  )
};

export default function Navigator({ mapSettings, setMapSettings, containerZIndex, initialIsDrawerOpen, initialSelectedPaneIndex, googleMapKey, ...props }) {
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(initialIsDrawerOpen);
  const [selectedPaneIndex, setSelectedPaneIndex] = useState(initialSelectedPaneIndex);

  //const [isPaneOpen, setIsPaneOpen] = useState(false);
  const zOffset = 5;

  const handleDrawerOpen = (anEvent) => {
    anEvent.preventDefault();
    // console.log(`Navigator.handleDrawerOpen(${anEvent._reactName}Event)`);
    setIsDrawerOpen(true);
    setSelectedPaneIndexWrapper(null);
    // console.log(`Navigator.handleDrawerOpen: Done`);
  };

  const handleDrawerClose = (anEvent) => {
    anEvent.preventDefault();
    // console.log('Navigator.handleDrawerClose(): ', anEvent);
    setIsDrawerOpen(false);
  }

  const setSelectedPaneIndexWrapper = (anIndex) => {
    // console.log(`Navigator.setSelectedPaneIndexWrapper(${anIndex})`);
    setSelectedPaneIndex(anIndex);
    // console.log('Navigator.setSelectedPaneIndexWrapper: Done');
  }

  const handleItemClick = (aLabel, anIndex) => {
    // const message = `Navigator.handleItemClick(${aLabel}, ${anIndex})`;
    // console.log(message);
    setSelectedPaneIndexWrapper(anIndex);
    // console.log('Navigator.handleItemClick: Done');
  }

  const handlePaneClose = (aPaneIndex) => {
    // const message = `Navigator.handlePaneClose(${aPaneIndex})`;
    // console.log(message);
    setSelectedPaneIndexWrapper(null);
    // console.log('Navigator.handlePaneClose: Done');
  }

  /**
   * The last identifier in aLocalURL is a paneName. Open that pane.
   *
   * I should probably keep a history and return there after a close.
   */
  const handleLinkClick = (aLabel) => {
    // const message = `Navigator.handleLinkClick(${aLabel})`;
    // console.log(message);
    const paneIndex = panes.findIndex(eachPane => aLabel === eachPane['paneName']);
    if (paneIndex !== null) {
      handleItemClick(aLabel, paneIndex);
    }
    // console.log('Navigator.handleLinkClick: Done');
  }

  /**
   * Focus the databrowser on the specified feature
   *
   * Updates the `selectedLocation` of `mapSettings` to contain the `location`
   * of `aPlaceDetails` (an instance of `LatLng`). This will cause the map to
   * to pan to this location and then select the feature that contains it.
   */
  function searchForPlaceDetails_(aPlaceDetails) {
    function doNothing() {};
    // console.log(`Navigator.searchForPlaceDetails_(${aPlaceDetails})`);
    const newMapSettings = {...mapSettings, selectedLocation: aPlaceDetails.geometry.location };
    setMapSettings(newMapSettings);
    doNothing();
  };

  return (
    <div id="navigatorTopLevelDivID">
      <Paper
        elevation={5}
        id="navigatorPaperID"
        component="form"
        className={classes.root}
        style={{zIndex: (containerZIndex+zOffset)}}
      >
        <IconButton id="navigatorMenuButtonID" onClick={handleDrawerOpen} className={classes.iconButton} >
          <MenuIcon />
        </IconButton>
        <TextSearchField
          id="textSearchFieldID"
          handleSearchSubmit={searchForPlaceDetails_}
          googleMapKey={googleMapKey}
        />
      </Paper>
      <Drawer
        id="navigatorDrawerID"
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={isDrawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <NavigatorCloseButton
          id="navigatorDrawerCloseButtonID"
          onClickHandler={handleDrawerClose}
          className={classes.iconButton}
        />
        <Divider />
        <List>
          {panes.map(
            (paneItem, paneIndex) => {
              return (
                <NavigatorItem
                  key={paneItem['paneName']}
                  itemIndex={paneIndex}
                  itemLabel={paneItem['paneTitle']}
                  selectedPaneIndex={selectedPaneIndex}
                  onItemClick={handleItemClick}
                />
              )
            })
          }
        </List>
      </Drawer>
      <PaneSelector
        selectedPaneIndex={selectedPaneIndex}
        handlePaneClose={handlePaneClose}
        onLinkClick={handleLinkClick}
      />
    </div>
  );
};
