/**
 * MapSceneView.js
 * @copyright: 2021 by Thomas M. Stambaugh & Zeetix, LLC (http://www.zeetix.com)
 * All rights reserved.
 * 
 * The contents of this file may not be copied, duplicated, or used without the
 * written consent of Zeetix, LLC.
 *
 * MapSceneView is the presentation component used by MapScene. Together, they
 * render the map element within the Scene. It gets its behavior from its
 * props, provided by MapScene (or any other container).
 *
 * Documentation for @react-google-maps/api:
 *   https://react-google-maps-api-docs.netlify.app/
 *
 * See /opt/react/react-google-maps_api/ for working examples of various
 * react-google-maps/API components.
 *
 * Major refactoring in December of 2021, so that limit and range behavior are
 * associated with the currently loaded data layer.
 */
import React, { useState, useEffect, useRef, useCallback }  from 'react';
import {
  GoogleMap,
  Data,
  } from "@react-google-maps/api";
import DataLoaderFactory from '../../data_loader/DataLoaderFactory';
import DataMarkerContainer from './DataMarkerContainer';
import DataWindowContainer from './DataWindowContainer';
import Legend from '../../legend/Legend';
import { vaxUnknownCountyShareByState } from './VaxUnknownCountyShareByState';

const COUNTY_ZOOM_LEVEL = 8;
const mapStyle = [
  {
    'stylers': [{'visibility': 'off'}]
  }, {
    'featureType': 'landscape',
    'elementType': 'geometry',
    'stylers': [{'visibility': 'on'}, {'color': '#fcfcfc'}]
  }, {
    'featureType': 'transit.station',
    'elementType': 'geometry',
    'stylers': [{'visibility': 'on'}, {'color': '#cfcfcf'}]
  }, {
    'featureType': 'road',
    'elementType': 'geometry',
    'stylers': [{'visibility': 'on'}, {'color': '#cfcfcf'}]
  }, {
    'featureType': 'water',
    'elementType': 'geometry',
    'stylers': [{'visibility': 'on'}, {'color': '#bfd4ff'}]
  }
];

const mapOptions = {
  styles: mapStyle,
  mapTypeControl: false,
  streetViewControl: false,
  gestureHandling: "greedy",
};

const mapContainerStyle = {
  width: '100vw',
  height: '100vh',
};

function MapSceneView( { mapControls, mapSettings, setMapSettings, environmentRef, environment, metadata, ...props }) {
  const [googleMapCenter, setGoogleMapCenter] = useState(mapSettings.center);
  const [markerPosition, setMarkerPosition] = useState(mapSettings.markerPosition);
  const [zoom, setZoomBasic] = useState(mapSettings.zoom);
  const [highlightedFeature, setHighlightedFeature] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [isDataMarkerVisible, setIsDataMarkerVisible] = useState(false);
  const [dataWindowPosition, setDataWindowPosition] = useState(mapSettings.dataWindowPosition);
  const [isDataLoaded, setIsDataLoaded] = useState(false);  // Set when handleDataLoaded_ finishes

  const rawGoogleMapRef = useRef(null);   // Needed to open dataWindow.
  const rawDataMarkerRef = useRef(null);  // A ref to the underlying Google Map API instance
  const rawDataWindowRef = useRef(null);
  const rawDataRef = useRef(null);        // A ref to the underlying Google Map API instance
  const dataLoaderRef = useRef(null);
  const areFeaturesLoadedRef = useRef(false);

  // Begin methods for dataLoader

  const setIsDataLoadedWrapper = useCallback(
    (aBoolean) => {
      // console.log(`MapSceneView.setIsDataLoadedWrapper(${aBoolean})`);
      setIsDataLoaded(aBoolean);
      // console.log(`MapSceneView.setIsDataLoadedWrapper()`);
    },
    []
  );

  // End methods for dataLoader

  // Begin methods for feature behavior

  function refreshFeatures() {
    rawDataRef.current.forEach(function(aFeature) {
      const covidVariable = aFeature.getProperty('covid_variable');
      dataLoaderRef.current.configureLoadedFeature_covidVariable_(aFeature, covidVariable);
    });
  }

  /**
   * Removes each property added to each feature.
   *
   * These are:
   *    covid_variable
   *    isHighlighted
   *    isSelected
   *
   * Also removes the 'state' property to 'normal'
   */
  function loadedFeaturesReset() {
    if (rawDataRef.current === null) {
      return;
    }
    rawDataRef.current.forEach(function(aFeature) {
      aFeature.setProperty('covid_variable', undefined);
      aFeature.setProperty('isHighlighted', false);
      aFeature.setProperty('isSelected', false);
      aFeature.setProperty('state', 'normal');

      // aFeature.setProperty('covidVariableHTML', undefined)
      aFeature.setProperty('refresh', false)
    });
  }

  /**
   * Makes a feature be highlighted
   */
  function highlightFeature_(aFeature) {
    // console.log(`MapSceneView.highlightFeature_(${aFeature.getId()})`);
    aFeature.setProperty('isHighlighted', true);
    aFeature.setProperty('state', 'hover');
    // console.log(`MapSceneView.highlightFeature_: Done`);
  }

  /**
   * Makes a feature be unhighlighted. Does nothing if aFeature is selected.
   */
  function unHighlightFeature_(aFeature) {
    // console.log(`MapSceneView.unHighlightFeature_(${aFeature.getId()})`);
    if (aFeature.getProperty('isSelected')) {
      // console.log(`MapSceneView.unHighlightFeature_: still selected`);
      return;
    }
    aFeature.setProperty('isHighlighted', false);
    aFeature.setProperty('state', 'normal');
    // console.log(`MapSceneView.unHighlightFeature_: Done`);
  }

  /**
   * Makes a feature be selected. Does nothing if aFeature is not highlighted.
   */
  function selectFeature_(aFeature) {
    // console.log(`MapSceneView.selectFeature_(${aFeature.getId()})`);
    if (!(aFeature.getProperty('isHighlighted'))) {
      // console.log(`MapSceneView.selectFeature_: not highlighted`);
      return;
    }
    aFeature.setProperty('isSelected', true);
    // console.log(`MapSceneView.selectFeature_: Done`);
  }

  /**
   * Makes a feature be unselected.
   */
  function unSelectFeature_(aFeature) {
    // console.log(`MapSceneView.unSelectFeature_(${aFeature.getId()})`);
    aFeature.setProperty('isSelected', false);
    // console.log(`MapSceneView.unSelectFeature_: Done`);
  }

  // End methods for feature behavior

  const setHighlightedFeatureWrapper = useCallback(
    (aFeatureOrNull) => {
      // console.log(`MapSceneView.setHighlightedFeatureWrapper(${ aFeatureOrNull === null?'null' : aFeatureOrNull.getProperty('GEO_ID') })`);
      setHighlightedFeature(aFeatureOrNull)
      // console.log(`MapSceneView.setHighlightedFeatureWrapper: Done`);
    },
    []
  );

  /**
   * If a map is loaded, then set its zoom
   */
  function setZoom(aZoom) {
    // console.log(`MapSceneView.setZoom(${aZoom})`);
    if (rawGoogleMapRef.current !== null) {
      // console.log(`MapSceneView.setZoom: set zoom of rawGoogleMapRef`);
      rawGoogleMapRef.current.setZoom(aZoom);
    }
    // console.log(`MapSceneView.setZoom: setZoomBasic(${aZoom})`);
    setZoomBasic(aZoom)
    // console.log(`MapSceneView.setZoom: Done`);
  }

  /**
   * If a map is loaded, then pan to its center
   */
  function panTo(aLatLng) {
    // console.log(`MapSceneView.panTo(${aLatLng})`);
    if (rawGoogleMapRef.current !== null) {
      // console.log(`MapSceneView.setGoogleMapCenter: pan raw map to ${aLatLng}`);
      rawGoogleMapRef.current.panTo(aLatLng);
    }
    // console.log(`MapSceneView.setGoogleMapCenter: setGoogleMapCenterBasic(${aLatLng})`);
    setGoogleMapCenter(aLatLng)
    // console.log(`MapSceneView.setGoogleMapCenter: Done`);
  }

  const hideDataMarker = useCallback(
    () => {
      const dataMarker = rawDataMarkerRef.current;
      if (dataMarker === null) {
        return;
      }
      dataMarker.setPosition(null);
      dataMarker.setVisible(false);
      setIsDataMarkerVisible(false);
    },
    []
  );

  /**
  * Clears the highlighted feature if not null. Also resets the DataMarker if
  * needed.
  **/
  const resetHighlightedFeature = useCallback(
    () => {
      // console.log(`MapSceneView.resetHighlightedFeature()`);
      if (highlightedFeature == null) {
        // console.log(`MapSceneView.resetHighlightedFeature: highlightedFeature is null`);
        return;
      }
      
      unHighlightFeature_(highlightedFeature);
      setHighlightedFeatureWrapper(null);
      hideDataMarker();
      // console.log(`MapSceneView.resetHighlightedFeature: Done`);
    },
    [highlightedFeature, setHighlightedFeatureWrapper, hideDataMarker]
  );

  const nameForStateFIPSCode_ = (aStateFIPSCode) => {
    const stateHash = {"01": "Alabama", "02": "Alaska", "04": "Arizona", "05": "Arkansas", "06": "California", "08": "Colorado", "09": "Connecticut", "10": "Delaware", "11": "District of Columbia", "12": "Florida", "13": "Georgia", "15": "Hawaii", "16": "Idaho", "17": "Illinois", "18": "Indiana", "19": "Iowa", "20": "Kansas", "21": "Kentucky", "22": "Louisiana", "23": "Maine", "24": "Maryland", "25": "Massachusetts", "26": "Michigan", "27": "Minnesota", "28": "Mississippi", "29": "Missouri", "30": "Montana", "31": "Nebraska", "32": "Nevada", "33": "New Hampshire", "34": "New Jersey", "35": "New Mexico", "36": "New York", "37": "North Carolina", "38": "North Dakota", "39": "Ohio", "40": "Oklahoma", "41": "Oregon", "42": "Pennsylvania", "44": "Rhode Island", "45": "South Carolina", "46": "South Dakota", "47": "Tennessee", "48": "Texas", "49": "Utah", "50": "Vermont", "51": "Virginia", "53": "Washington", "54": "West Virginia", "55": "Wisconsin", "56": "Wyoming", "72": "Puerto Rico"};
    return stateHash[aStateFIPSCode];
  };

  /**
  * Loads the state boundry polygons from a GeoJSON source.
  *
  * Upon completion, calls loadData once.
  */
  const loadMapShapes_ = (aRawData) => {
    // console.log('MapSceneView.loadMapShapes_: ', aRawData);
    aRawData.loadGeoJson('/data/geojson/us-counties-5m-kcmo-nyc.js', { idPropertyName: 'GEO_ID' });
    // wait for the request to complete by listening for the first feature to be
    // added
    window.google.maps.event.addListenerOnce(aRawData, 'addfeature', function() {
      areFeaturesLoadedRef.current = true;
      loadData();
    });
    // console.log('MapSceneView.loadMapShapes_: done');
  };

  const handleLoadError = (anError) => {
    console.log('MapSceneView.handleLoadError: ', anError);
  };

  /**
   * I am called BEFORE a change (in my dashboard) has
   * caused mapControls and mapLimits to change. This should happen ONLY on the
   * initial load of mapShapes, and perhaps not even then.
   *
   * I need to trigger a refresh of dataLoaderRef.current each time mapControls
   * and mapLimits change.
   */
  const loadData = useCallback(
    () => {
      function prepareForDataLoad() {
        // console.log('MapSceneView.loadData.prepareForDataLoad: Reset loadedFeatures');
        loadedFeaturesReset();
        setIsDataLoadedWrapper(false);
        // console.log('MapSceneView.loadData.prepareForDataLoad: Done');
      }
      if (!mapControls.pertainsDate) {
        // Wait for pertainsDate to be set
        return
      }
      // console.log('MapSceneView.loadData():');
      prepareForDataLoad()
      // console.log('MapSceneView.loadData(): Invoking loadDataOnError_');
      dataLoaderRef.current.loadDataOnError_((anError) => {handleLoadError(anError)});
      // console.log('MapSceneView.loadData: Done');
    },
    [setIsDataLoadedWrapper, mapControls.pertainsDate]
  );

  /**
   * Answers the loaded feature that contains aLocation
   * 
   * For each feature, collects the polygon that defines its outline.
   * Invoke a callback if the result of `containsLocation` is true.
   * Loop until exited or finished.
   */
  function featureAtLocation_(aLocation) {
    const doNothing = () => {};
    const features = [];
    rawDataRef.current.forEach(function(aFeature) {features.push(aFeature)});
    const featureAtLocation = features.find(function(aFeature) {
      // Answers the first element that return true
      const geometry = aFeature.getGeometry()
      const geometryType = geometry.getType();
      let coordinates = [];
      let isContained = false;
      let polygon = null;
      try {
        switch (geometryType) {
          case 'MultiPolygon':
            const elements = geometry.getArray();
            const isLocationInFeature = elements.find(function(anElement, anIndex) {
              coordinates = anElement.getAt(0).getArray();
              polygon = new window.google.maps.Polygon({paths: coordinates});
              isContained = window.google.maps.geometry.poly.containsLocation(aLocation, polygon);
              if (isContained) {
                doNothing()
              }
              return isContained;
            });
            return (isLocationInFeature !== undefined);
          case 'Polygon':
            // coordinates = geometry.getArray();
            geometry.forEachLatLng(function(aLatLng){coordinates.push(aLatLng)});
            if (coordinates.length < 3) {
              // No area
              isContained = false;
              return isContained;
            }
            polygon = new window.google.maps.Polygon({paths: coordinates});
            isContained = window.google.maps.geometry.poly.containsLocation(aLocation, polygon);
            if (isContained) {
              doNothing()
            }
            return isContained;
          default: 
            console.log(`featureAtLocation_: Unknown geometryType (${geometryType})`);
            return undefined;
        }
      } catch (anError) {
        console.log(`featureAtLocation_: Caught error (${anError})`);
        return undefined;
      }
    });
    return featureAtLocation;
  }

  /**
   * Populates the contents of a DataMarker from aFeature and shows the result.
   *
   * The geometry of the label was determined empirically. The height is
   * fixed at 40px.
   * 
   * The font has an average size of 6.63 pixels/char and there is about
   * 40px of padding.
   *
   * The actual width is therefore (40 + (6.63*length)) where length is
   * the number of characters in the labelString.
  **/
  const showDataMarkerForFeature_atLocation_ = useCallback(
    (aFeature, aLocation) => {

      const isByPopulation = () => {
        return ((mapControls.isByPopulation) && !(mapControls.isByArea))
      }

      const featureName = aFeature.getProperty('NAME');
      const featureLSAD = aFeature.getProperty('LSAD');
      const featureState = nameForStateFIPSCode_(aFeature.getProperty('STATE'));
      let featureValue = aFeature.getProperty('covid_variable');
      let markerValue;
      //let markerString;
      let isLowCoverage = false;
      let isPercentage = false;
      if (mapControls.dataSource === 'Vax') {
        // Handle vax features
        if (isByPopulation()) {
          markerValue = dataLoaderRef.current.htmlForValue_(featureValue/1000);
          isPercentage = true;
          // markerString = `${featureName} ${featureLSAD}, ${featureState}: ${markerValue}%`;
        } else {
          markerValue = dataLoaderRef.current.htmlForValue_(featureValue);
        }
        const unknownShare = vaxUnknownCountyShareByState[aFeature.getProperty('STATE')]
        if (parseFloat(unknownShare) >= 20) {
          // // Mark it with an asterisk
          // markerString = `${markerString} (!)`
          isLowCoverage = true;
        }
      } else {
        // Handle non-vax features
        markerValue = dataLoaderRef.current.htmlForValue_(featureValue);
        // markerString = `${featureName} ${featureLSAD}, ${featureState}: ${markerValue}`;
      }
      let markerString = `${featureName} ${featureLSAD}, ${featureState}: ${markerValue}`;
      if (isPercentage) {
        markerString += '%';
      }
      if (isLowCoverage) {
        markerString += ' (!)';
      }
      const dataMarker = rawDataMarkerRef.current;
      dataMarker.setLabel({'text': markerString});
      dataMarker.setPosition(aLocation)
      dataMarker.setVisible(true);
      setIsDataMarkerVisible(true);
    },
    [mapControls.dataSource, mapControls.isByArea, mapControls.isByPopulation]
  );

  // Begin methods for manipulating features
  const highlightFeature_location_ = useCallback(
    (aFeature, aLocation) => {
      const covidVariable = aFeature.getProperty('covid_variable');
      if (covidVariable == null) {
        return;
      }
      highlightFeature_(aFeature);
      setHighlightedFeatureWrapper(aFeature);
      showDataMarkerForFeature_atLocation_(aFeature, aLocation);
    },
    [setHighlightedFeatureWrapper, showDataMarkerForFeature_atLocation_]
  );

  const hideDataWindow = useCallback(
    () => {
      // console.log('MapSceneView.hideDataWindow()');
      const rawDataWindow = rawDataWindowRef.current;
      if (selectedFeature === null) {
        // console.log('MapSceneView.hideDataWindow: selectedFeature is null')
        return;
      }
      if (rawDataWindow.get("isOpen")) {
        // console.log('MapSceneView.hideDataWindow: Closing data window with existing content')
        rawDataWindow.close();
        rawDataWindow.set('isOpen', false);
      }
      setDataWindowPosition(null);
      unSelectFeature_(selectedFeature);
      unHighlightFeature_(selectedFeature)
      setSelectedFeature(null);
      // console.log('MapSceneView.hideDataWindow: Done');
    },
    [selectedFeature]
  );

  const showDataWindowForFeature_location_ = useCallback(
    (aFeature, aLocation) => {
      // console.log(`MapSceneView.showDataWindowForFeature_location_(${aFeature}, ${aLocation})`);
      const rawDataWindow = rawDataWindowRef.current;
      hideDataWindow();
      // console.log(`MapSceneView.showDataWindowForFeature_location_: setting new location`);
      setDataWindowPosition(aLocation);
      // console.log(`MapSceneView.showDataWindowForFeature_location_: selected new feature`);
      selectFeature_(aFeature);
      // console.log(`MapSceneView.showDataWindowForFeature_location_: setting state`);
      setSelectedFeature(aFeature);
      // console.log('MapSceneView.showDataWindowForFeature_location_: Opening data window with new content')
      rawDataWindow.set('isOpen', true);
      rawDataWindow.open(rawDataRef.current.map);
      // console.log('MapSceneView.showDataWindowForFeature_location_: Done')
    },
    [hideDataWindow]
  );

  const selectFeature_atLocation_ = useCallback(
    (aFeature, aLocation) => {
      // console.log(`MapSceneView.selectFeature_atLocation_(${aFeature}, ${aLocation})`);
      showDataWindowForFeature_location_(aFeature, aLocation)
      // console.log(`MapSceneView.selectFeature_atLocation_: Done`);
    },
    [showDataWindowForFeature_location_]
  );

  // End methods for manipulating features

  /**
  * Responds to the click event on a feature.
  *
  * If an InfoWindow is open, then selectedFeature will contain its feature.
  * The highlightedFeature is the feature that received the click.
  * If highlightedFeature and selectedFeature are the same, then do nothing --
  * this is another click in the same feature, and should have no effect.
  *
  * If highlightedFeature and selectedFeature are different, then close the
  * the window, turn off isPinnedToMap, and clear selectedFeature.
  *
  * After 
  * Close the info window if open
  * Set the info window content
  * Set the info window position
  * Open the info window.
  * @param {?google.maps.MouseEvent} e
  **/
  const clickInRegion = (anEvent) => {
    // console.log('MapSceneView.clickInRegion(): ', anEvent)
    if (selectedFeature === highlightedFeature) {
      // Nothing to do.
      // console.log('MapSceneView.clickInRegion(): Nothing to do')
      return;
    }

    if (highlightedFeature === null) {
      // Nothing to do.
      // console.log('MapSceneView.clickInRegion(): highlightedFeature is null');
      return;
    }
    selectFeature_atLocation_(highlightedFeature, anEvent.latLng);
  };

  /**
    * Responds to the mouse-in event on a map feature.
    *
    * @param {?google.maps.MouseEvent} e
    */
  const handleMouseIntoRegion = (aMouseEvent) => {
    // const eventString = `MouseEvent({ featureID: ${aMouseEvent.feature.getId()}, latLng: (${aMouseEvent.latLng.lat()}, ${aMouseEvent.latLng.lng()}) })`;
    // console.log(`MapSceneView.handleMouseIntoRegion(${eventString})`);
    highlightFeature_location_(aMouseEvent.feature, aMouseEvent.latLng);
    // console.log(`MapSceneView.handleMouseIntoRegion: Done`);
  };

  /**
    * Responds to the mouse-out event on a map shape.
    *
    * @param {?google.maps.MouseEvent} e
    */
  const handleMouseOutOfRegion = (aMouseEvent) => {
    // const eventString = `MouseEvent({ featureID: ${aMouseEvent.feature.getId()}, latLng: (${aMouseEvent.latLng.lat()}, ${aMouseEvent.latLng.lng()}) })`;
    // console.log(`MapSceneView.handleMouseOutOfRegion(${eventString})`);
    resetHighlightedFeature();
    // console.log(`MapSceneView.handleMouseOutOfRegion: Done`);
  };

  /**
   * I pan the map to aLocation, set a suitable zoom, and select the resulting
   * feature. This causes the DataWindow to open, displaying the current data
   * chosen for that feature.
   */
  const panAndSelectLocation_ = useCallback(
    (aLocation) => {
      // console.log(`MapSceneView.panAndSelectLocation_(${aLocation})`);
      const locationAsObject = {lat: aLocation.lat(), lng: aLocation.lng()};
      const newMapSettings = {
          'center': locationAsObject,
          'markerPosition': locationAsObject,
          'dataWindowPosition': locationAsObject,
          'zoom': COUNTY_ZOOM_LEVEL,
          'selectedLocation': null,
      }
      // console.log(`MapSceneView.panAndSelectLocation_: Changing mapSettings`);
      setMapSettings(newMapSettings);
      // console.log(`MapSceneView.panAndSelectLocation_: Changing zoom`);
      // console.log(`MapSceneView.panAndSelectLocation_: Changing googleMapCenter`);
      panTo(locationAsObject);
      // console.log(`MapSceneView.panAndSelectLocation_: finding feature`);
      setZoom(COUNTY_ZOOM_LEVEL);
      const newSelectedFeature = featureAtLocation_(aLocation);
      if (newSelectedFeature === undefined) {
        // console.log(`MapSceneView.panAndSelectLocation_: Unable to find feature`);
        return
      }
      if (newSelectedFeature === selectedFeature) {
        // No change, nothing to do
        // console.log(`MapSceneView.panAndSelectLocation_: Unable to find feature`);
        return
      }
      resetHighlightedFeature();
      // console.log(`MapSceneView.panAndSelectLocation_: highlighting ${newSelectedFeature}`);
      highlightFeature_location_(newSelectedFeature, aLocation);
      // console.log(`MapSceneView.panAndSelectLocation_: selecting feature at ${aLocation}`);
      selectFeature_atLocation_(newSelectedFeature, aLocation);
      // console.log(`MapSceneView.panAndSelectLocation_: Done`);
    },
    [selectedFeature, highlightFeature_location_, selectFeature_atLocation_, resetHighlightedFeature, setMapSettings]
  );

  const onGoogleMapLoad_ = (aRawGoogleMap) => {
    rawGoogleMapRef.current = aRawGoogleMap;
  };
  /**

   * I pass a one-arg lambda to setStyle_ so that the styleFeature_ method
   * is called with the appropriate context (value of $this);
   */
  const onDataLoad = (aRawData) => {
    // console.log('MapSceneView.onDataLoad: ', aRawData)
    rawDataRef.current = aRawData;  // This is used by handleTriggerRefreshDivChange
    aRawData.setStyle((aFeature) => dataLoaderRef.current.styleFeature_(aFeature));
    loadMapShapes_(aRawData);
    // console.log('MapSceneView.onDataLoad: Done')
  }

  const onDataClick = (anEvent) => {
    // console.log('MapSceneView.onDataClick: ', anEvent);
    clickInRegion(anEvent);
  }

  /**
   * I use the useCallback hook because I am invoked from useEffect, and this
   * seems to be the most reliable way to maintain the appropriate state.
   */
  const refreshDataLoader = useCallback(
    () => {
      const capitalize_ = (aString) => {
        const capitalized = `${aString.charAt(0).toUpperCase()}${aString.slice(1)}`;
        return capitalized;
      }

      /**
       * Answers a metdataKey derived from mapControls.
       *
       * 'dataSource':     'CaseCount' | 'DeathCount' | 'HotSpots' | 'Vax'
       * 'dataGrain':      'daily'|'cumulative'
       * 'dataAdjustment': 'None'|'smoothing'|'anomalies'
       * 'isByPopulation': true | false,
       * 'isByArea':       true | false,
       * 'analyzerName':   'EdgeIntensity' | 'None'
       *
       * The metadata for any "smoothed" property is the same as the metadata
       * for the property, so there are no metadataKey instances beginning with
       * "smoothed".
       */
      function getMetadataKey() {
        let metadataKey = '';
        const {
          dataSource,
          dataGrain,
          dataAdjustment,
          isByPopulation,
          isByArea,
          analyzerName} = mapControls;
        if ('HotSpots' === dataSource) {
          return 'isHotSpot'
        }
        let normalization = '';
        if (isByArea & isByPopulation) {
          normalization = 'Per100MPersonMile';
        } else if (isByArea) {
          normalization = 'PerKSquareMile';
        } else if (isByPopulation) {
          normalization = 'Per100KPerson';
        };
        let suffix = '';
        if ('anomalies' === dataAdjustment) {
          suffix = 'Anomalies';
        } else if ('EdgeIntensity' === analyzerName) {
          // EdgeIntensity applies only to unNormalized properties
          suffix = "EdgeIntensity";
          normalization = '';
        };
        metadataKey = `${dataGrain}${capitalize_(dataSource)}${normalization}${suffix}`;
        return metadataKey;
      }

      /**
       * Looks up and answers the limits corresponding to mapControls in my
       * metadata.
       *
       * 'dataSource':     'CaseCount' | 'DeathCount' | 'HotSpots' | 'Vax'
       * 'dataGrain':      'daily'|'cumulative'
       * 'dataAdjustment': 'None'|'smoothed'|'anomalies'
       * 'isByPopulation': true | false,
       * 'isByArea':       true | false,
       * 'analyzerName':   'EdgeIntensity' | 'None'
       */
      function getMetadata() {
        const metadataKey = getMetadataKey();
        const selectedMetadata = metadata[metadataKey];
        if (!selectedMetadata) {
          throw new Error("Missing or invalid metadataKey");
        }
        return selectedMetadata;
      }

      function handleCompletedDataLoad() {
        // console.log(`MapSceneView.refreshDataLoader: New data is loaded`);
        /*
        The current dataLoader (dataLoaderRef.current) should have a
        _featureStyler with valid _limits. Does it?
        */
        setIsDataLoadedWrapper(true);
      }

      function defaultLowerLimitCentile() {
        const centile = Number(environment['DEFAULT_LOWER_LIMIT_CENTILE']);
        return centile
      }

      function defaultUpperLimitCentile() {
        const centile = Number(environment['DEFAULT_UPPER_LIMIT_CENTILE']);
        return centile
      }

      function getIsDataLoaded() {
        return isDataLoaded;
      }

      // console.log(`MapSceneView.refreshDataLoader: Creating new DataLoader`);
      const newMetadata = getMetadata();
      const newDataLoader = DataLoaderFactory.createForMapControls_metadata_directorContext_(
        mapControls,
        newMetadata,
        {
          'areFeaturesLoadedRef': areFeaturesLoadedRef,
          'rawDataRef': rawDataRef,
          'onCompletion': handleCompletedDataLoad,
          'defaultLowerLimitCentile': defaultLowerLimitCentile,
          'defaultUpperLimitCentile': defaultUpperLimitCentile,
          'getIsDataLoaded': getIsDataLoaded
        }
        );
      if ('Vax' === mapControls.dataSource) {
        // Reverse the colorKey
        // console.log(`MapSceneView.refreshDataLoader: Reversing the color key for Vax`);
        const reversedColorKey = newDataLoader.colorKey()
        reversedColorKey.reverse()
        newDataLoader.colorKey_(reversedColorKey)
        // console.log(`MapSceneView.refreshDataLoader: Finished reversing the color key`);
      }
      // console.log(`MapSceneView.refreshDataLoader: Updating dataLoaderRef.current`);
      dataLoaderRef.current = newDataLoader;
      // console.log(`MapSceneView.refreshDataLoader: Done`);
    },
    [mapControls, setIsDataLoadedWrapper, environment, isDataLoaded, metadata]
  );

  useEffect(
    () => {
      refreshDataLoader();
    },
    [refreshDataLoader]
  );

  /**
   * Reload the mapData on each change to mapControls
   */
  useEffect(
    () => {
      loadData();
      },
    [loadData, mapControls]
  );

  /**
   * I handle a change in `selectedLocation` field, usually caused by searching
   * from the navigator.
   */
  useEffect(
    () => {
      // console.log(`useEffect[mapSettings]()`);
      const selectedLocation = mapSettings.selectedLocation;
      if ((selectedLocation !== null) && (selectedLocation !== mapSettings.center)) {
        panAndSelectLocation_(mapSettings.selectedLocation);
      }
    }, [mapSettings, panAndSelectLocation_]
  );

  return (
    <React.Fragment>
      <GoogleMap
        id="reactGoogleMapID"
        mapContainerStyle={mapContainerStyle}
        zoom={zoom}
        center={googleMapCenter}
        onLoad={onGoogleMapLoad_}
        options={mapOptions}
      >
        <Data
          id="reactData"
          onLoad={onDataLoad}
          onClick={onDataClick}
          onMouseOver={handleMouseIntoRegion}
          onMouseOut={handleMouseOutOfRegion}
        />
        <DataMarkerContainer
          rawDataMarkerRef = {rawDataMarkerRef}
          handleClickInMarker = {clickInRegion}

          markerPosition={markerPosition}
          setMarkerPosition={setMarkerPosition}
          isDataMarkerVisible={isDataMarkerVisible}
        />
        <DataWindowContainer
          mapControls={mapControls}
          mapSettings={mapSettings}
          position={dataWindowPosition}
          closeDataWindow={hideDataWindow}
          rawDataWindowRef={rawDataWindowRef}
          highlightedFeature={highlightedFeature}
          selectedFeature={selectedFeature}
          nameForStateFIPSCode_={nameForStateFIPSCode_}
          isDataLoaded={isDataLoaded}
          dataLoaderRef={dataLoaderRef}
        />
      </GoogleMap>
      <Legend
        id="legendID"
        dataLoaderRef={dataLoaderRef}
        highlightedFeature = {highlightedFeature}
        isDataLoaded={isDataLoaded}
        refreshFeatures={refreshFeatures}
      />
    </React.Fragment>
  );
}

export default MapSceneView;
